@import 'variables';

body.ups-style {
    font: 16px/1.5 $font-stack-1;
    color: $primary-body-color;

    overflow-y: auto;
}

.ups-style .input-align-below{
    margin-top: -13px!important;
}

.ups-style .section-align{
    margin-top: -20px!important;
    margin-bottom: 10px!important;
}

.ups-style .section-separator {
    border-top: 1px solid #c8c8c8;
    // margin-bottom: 6px!important;
    // margin-top: 45px!important;
    margin-bottom: 1.5rem!important;
}

.ups-style .notify-align{
    margin-left: -45px!important;
}

.ups-style .invoices-align{
    margin-top: -1rem;
}

.ups-style .card.dashboard-widget-card.outline-card {
    background: #fff;
    border: 1px solid #c8c8c8;
}

.ups-style .card.preferences-widget-card {
    border-radius: 0;
    background: #F7F6F5;
    min-height: 220px;
    .view {
        height: 215px;

        img {
            border-radius: 0!important;
        }

        .mask, .Ripple {
            display: none;
        }

        .Ripple-parent {
            cursor: default;
        }

    }

    li {
        font-size: 14px;
    }

    .card-body {
        padding: 0 30px;
    }

    .card-title {
        border: none;
        padding: 30px 30px 0 30px;
        margin-bottom: 10px;
        //margin-top: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .card-footer {
        background: #F7F6F5;
        padding: 15px 30px 30px 30px;
    }
}

.timeoutalign {
    font-size: .875rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.h1-align{
    font-size: 18px;
    line-height: 21.37px;
    padding-bottom: 5px;    
    color: #300;
    position: relative;
}

.session-align{
    margin-top: 40px;
    margin-left: 240px;
}

.ups-style .img-fluid{
    max-width: 100%;
    max-height: 20%;
}

.ups-style .border {
    border: 1px solid #dee2e6!important;

}

.ups-style .btn-preferences{
    margin-left:10px
}

.ups-style .pbtauthcheck{
    margin-left: -15px !important;
}

.enroll-align{
    margin-left: -15px;
}

.ups-style h1.help-support-header{
        padding-top: 5px ;
        margin-top: -35px;
        margin-left: -15px;
        font-size: 28px;
}

.ups-style .ups-main:not(.logged-in) {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
}

.ups-style .form-check-input[type=checkbox]+label.font-weight-bold {
    font-weight: 700!important;
}

.bank-direct-debit-only-warning, .center-alert{
    max-width: 728px;
    margin: 0 auto;
}
.bacs-creation-delay-warning{
    max-width: 530px;
    margin: 0 auto;
}
.alert-red, .ups-style .alert-red{
    color:rgb(187,26,54);
    font-weight:500;
    .modal-title{
        color:rgb(187,26,54);
        font-weight:500;
    }
}
.placeholder-class-name {
    margin-top: 5px;
    margin-right: 12px;

    svg {
        fill: #f78b23;
    }
}

.align-body .material-icons, .align-body-enroll .material-icons {
    font-size: 18px;
    vertical-align: middle;
    margin-left: .25rem;
    
}

.ups-style {
    a:link, a:visited {
        color: #426DA9;
        &:hover {
            color: #4A5B7F;
        }
    }
}

.ups-style .align-body-enroll{
    margin: 0px 0px 30px;
}

.ups-style .align-body{
    margin: 0px 0px 145px;
}

.ups-style .ups-main.logged-in {
    padding-top: 80px;
}

.ups-style .ups-main .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.ups-style .ups-main.logged-in .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

.ups-header-wrapper {
    padding-top: 0;
    padding-bottom: 0;
    height: 132px;
    z-index: 100;
    position: relative;
    width: 100%;
    transition: none!important;
    padding: 0!important;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.ups-header {
    position: relative;
    height: 132px;
    //max-width: 1400px;
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
}

.d-contents{
    display:contents;
}

.ups-style .navbar.scrolling-navbar.top-nav-collapse {
    //top: -32px;
    //position: fixed;
}

.ups-style .navbar.scrolling-navbar.top-nav-collapse  + #ups-main-container {
    //border-top: 132px solid #404040;
    //padding-top: 32px;
    //margin-top: -32px;
}

.ups-header_logo {
    position: absolute;
    display: block;
    z-index: 2;
    width: 67px;
    height: 78px;
    overflow: hidden;
    transition: none;
    bottom: 22px;
    -moz-transition-property: all;
    -o-transition-property: all;
    -webkit-transition-property: all;
    transition-property: all;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    margin-left: 15px;
}

//.navbar.scrolling-navbar.top-nav-collapse .ups-header_logo {
//    bottom: 11px;
//}

.ups-header_logo img {
    width: 100%;
    max-width: 48px;
}

// need to update this later so that logo and title aren't position absolute anymore as they don't need to be
.ups-header_title {
    position: absolute;
    font-size: 32px;
    color: $primary-body-color;
    margin-left: 24px;
    top: 50px;
    left: 76px;
    white-space: normal;
}

.fixed-sn .ups-footer-wrapper, .ups-footer-wrapper {
    margin-top: 15px;
    padding: 6px 0px 60px 0;
    background: #f2f2f2;
}

.ups-headFixed #ups-header_logo, .ups-header_light #ups-header_logo {
    bottom: 11px;
}


.ups-footer {
    padding: 0;
    margin: 0 auto;
    max-width: 1400px;
    position: relative;
    color: $primary-body-color;
    padding: 24px 0 48px;
}

.ups-main.logged-in ~ .ups-footer-wrapper .ups-footer {
    max-width: none;
    padding-left: 298px;

}

.ups-footer .ups-footer_legal {
    clear: both;
    width: 100%;
    float: right;
    padding: 0;
    padding-right: 18px;
    font-size: 0.85em;
}

.ups-footer .ups-footer_legal .ups-footer_links ul::before {
    width: 100%;
    content: "";
    position: absolute;
    border-top: 1px solid $ups-stroke;
    top: -15px;
    display: none;
}

.ups-footer .ups-footer_legal .ups-footer_links ul {
    position: relative;
    padding: 0;
}

.ups-footer .ups-footer_legal .ups-footer_links li {
    padding: 0;
    padding-left: 30px;
    margin: -2px 0 4px;
    white-space: nowrap;
    line-height: 17px;
    position: relative;
    display: inline-block;
}

.ups-footer .ups-footer_legal .ups-footer_links li:first-child {
    padding-left: 0;
}

.ups-footer .ups-footer_legal .ups-footer_links li:first-child:before {
    display: none;
}

.ups-footer .ups-footer_legal .ups-footer_links li:before {
    content: '|';
    font-size: 1.2em;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 12px;
}

.ups-footer .ups-footer_legal .ups-footer_links li a {
    display: inline;
    padding: 0;
    line-height: 1.5;
    color: $primary-body-color;
}

.ups-enroll-section {
    max-width: 960px;
    margin: 0 auto;
}

.ups-style .btn-primary {
    @include button($btn-primary-color, $btn-primary-background, $btn-primary-border);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .preloader-wrapper {
        display: none;
        position: absolute;
        top: 10px;
        right: 8px;
        height: 16px;
        width: 16px;
        vertical-align: top;

        .spinner-layer {
            border-color: #fff;
        }
    }

    &.loading {
        .preloader-wrapper {
            display: block;
        }
    }
}

.ups-style .btn-primary:not(.custom-vertical-dropdown):focus,
.ups-style .btn-primary:not([disabled]):not(.disabled):not(.custom-vertical-dropdown):active,
.ups-style .btn-primary:hover {
    color: $btn-primary-color;
    background: $btn-primary-background-hover;
    text-decoration: none;
}

.ups-style .btn-primary::after, .ups-style .btn-secondary::after{
    font-family: 'Material Icons'!important;
    content: 'keyboard_arrow_right'!important;
    font-size: 20px;
    vertical-align: middle;
    font-feature-settings: 'liga';
}

.btn.btn-primary.custom-vertical-dropdown::after, .btn.btn-secondary.btn-calendar::after, .btn.btn-secondary.btn-download-invoices::after, .btn.btn-secondary.btn-icon::after{
    content: ""!important;
}

.ups-style .notif-disabled{     
    color: #aaa!important;
    cursor: not-allowed;
    text-decoration: none;
}

.ups-style .btn-primary.disabled {
    background-color: #ebebeb!important;
    color: #aaa!important;
    cursor: not-allowed;
    text-decoration: none;
}

.ups-style .btn-start-over,
.ups-style .btn-secondary {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border); 
    display: inline-flex!important;
    align-items: center;
    justify-content: center;
    text-align: left;   
}

.ups-style .btn-flat-link {
    color: $ups-blue;
    background: none!important;
    border: none!important;
    text-decoration: none;
    text-transform: initial!important;
    font-size: 1rem;
}

.ups-style .btn-flat-link-without-fw {
    color: $ups-blue;
    background: none!important;
    border: none!important;
    text-decoration: none;
    text-transform: initial!important;
    font-size: 1rem;
}

.ups-style .btn-primary[disabled],
.ups-style .btn-secondary[disabled] {
    background: #ebebeb !important;
    color: #aaa !important;
    cursor: not-allowed;
    border: 0;
}


.ups-style .btn-start-over:hover,
.ups-style .btn-secondary:hover,
.ups-style .btn-secondary:not([disabled]):not(.disabled):active,
.ups-style .btn-start-over:focus,
.ups-style .btn-secondary:focus{
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border);
    background-color: $ups-teal-light;
    text-align: left;
}



.ups-style .btn-icon {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-icon-border);
    padding: 3px 3px!important;
    width: 38px!important;
    height: 38px!important;

    text-align: center!important;
    //background-color: $ups-teal;
    color: $ups-teal;
    line-height: 0;
    box-shadow: none!important;
    border-radius: 65px!important;
    border: 1px solid #dee2e6;
    color: #424242;
}

.ups-style .btn-icon[disabled] {
    background: #ebebeb !important;
    color: #aaa !important;
    cursor: not-allowed;
    border: 0;
    line-height: 0;

}


.ups-style .btn-input-group {
    @include button($btn-primary-color, $btn-primary-background, $btn-primary-border);
    margin: 0!important;
    border-radius: 0 0.25rem 0.25rem 0!important;
}

.ups-style .btn-input-group:focus,
.ups-style .btn-input-group:not([disabled]):not(.disabled):active,
.ups-style .btn-input-group:hover {
    color: $btn-primary-color;
    background: $btn-primary-background-hover;
    text-decoration: none;
}


.ups-style .btn-icon:hover,
.ups-style .btn-icon:not([disabled]):not(.disabled):active,
.ups-style .btn-icon:focus{
    @include button($ups-teal, $btn-secondary-background, $btn-icon-border);
    background-color: $ups-teal-light;
    line-height: 0;
}

.ups-style .btn-icon.active {
    background-color: $ups-teal;
    color: #fff!important
}

.ups-style .btn-calendar {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-icon-border);
    padding: 10px 10px!important;
    //width: 42px!important;
    width: auto!important;
    text-align: center!important;
    //background-color: $ups-teal;
    color: $ups-teal;
    box-shadow: none!important;
    border-radius: 65px!important;
    border: 1px solid #dee2e6;
}

.ups-style .btn-calendar[disabled] {
    background: #ebebeb !important;
    color: #aaa !important;
    cursor: not-allowed;
    border: 0;
    line-height: 0;
}



.ups-style .btn-calendar:hover,
.ups-style .btn-calendar:not([disabled]):not(.disabled):active,
.ups-style .btn-calendar:focus{
    @include button($ups-teal, $btn-secondary-background, $btn-icon-border);
    background-color: $ups-teal-light;
}




.ups-style .btn-cancel {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border);
    background: none!important;
    //color: #426DA9!important;
    color: $ups-blue;
    text-decoration: none;
    font-family: Tahoma,helvetica,arial,sans-serif;
    border-radius: 0;
    border: none!important;

}

.ups-style .btn-cancel:hover,
.ups-style .btn-cancel:focus {
    background: none!important;
    //color: #4A5B7F!important;
    text-decoration: underline;
    color: $ups-blue-dark;
}


.ups-style .btn-text-link {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border);
    background: none!important;
    //color: #426DA9!important;
    color: $ups-blue;
    text-decoration: none;
    font-family: Tahoma,helvetica,arial,sans-serif;
    border-radius: 0!important;
    border: none!important;

    &.btn-view-plan-invoice {
        padding: 10px 0px!important;
        margin: 0px 16px!important;
        color: $ups-blue;
    }
    &.btn-view-account-invoice {
        padding: 10px 0px!important;
        margin:0px 16px!important;
        color: $ups-blue;
    }
    &.btn-pref{
        padding: 18px 3px!important;
        font-size: 0.81rem;
    }

    &.btn-minimal {
        padding: 0!important;
        margin: 0!important;
        line-height: normal;
        vertical-align: initial;
        font-size: inherit!important;
        transition: none!important;
        &:hover {
            text-decoration: underline;
        }
    }
   
    &.dashboard-btn-minimal {
        padding: 0!important;
        margin: 0!important;
        line-height: normal;
        vertical-align: initial;
        font-size: inherit!important;
        transition: none!important;
        text-decoration: underline;
        color: $ups-blue-dark !important;
    }

    &.dashboard-btn-minimal {
        padding: 0!important;
        margin: 0!important;
        line-height: normal;
        vertical-align: initial;
        font-size: inherit!important;
        transition: none!important;
        text-decoration: underline;
        color: $ups-blue-dark !important;
    }

    &.paybytextbtn {
        padding: 0!important;
        margin: 0!important;
        line-height: normal;
        vertical-align: initial;
        font-size: inherit!important;
        transition: none!important;
    }
}

.link-spacing {
    margin-top: 10px !important;
}

.ups-style .btn-text-link:hover,
.ups-style .btn-text-link:focus {
    background: none!important;
    //color: #4A5B7F!important;
    text-decoration: underline;
    color: $ups-blue-dark;
    &.btn-view-plan-invoice {
        color: $ups-blue-dark;
    }
    &.btn-view-account-invoice {
        color: $ups-blue-dark;
    }
}

.ups-style .dashboard-btn-text-link {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border);
    background: none!important;
    color: $ups-blue;
    text-decoration: none;
    font-family: Tahoma,helvetica,arial,sans-serif;
    border-radius: 0!important;
    border: none!important;
   
    &.dashboard-btn-minimal {
        padding: 0!important;
        margin: 0!important;
        line-height: normal;
        vertical-align: initial;
        font-size: inherit!important;
        transition: none!important;
        text-decoration: underline;
    }
}

.ups-style .btn-datatable-text-link {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border);
    background: none!important;
    //color: #426DA9!important;
    color: $ups-blue;
    text-decoration: underline;
    font-family: Tahoma,helvetica,arial,sans-serif;
    border-radius: 0;
    border: none!important;
    text-align: left;
    padding: 0!important;
    margin: 0!important;
    font-size: 0.813rem!important;
    line-height: normal!important;
    overflow: visible!important;
    -webkit-user-select: unset;
    -khtml-user-select: unset;
    -moz-user-select: unset;
    -ms-user-select: unset;
    user-select: unset;
}

.ups-style .btn-datatable-text-link:hover,
.ups-style .btn-datatable-text-link:focus {
    background: none!important;
    //color: #4A5B7F!important;
    text-decoration: underline;
    color: $ups-blue-dark;
}


.ups-style .btn-change-country {
    @include button($btn-secondary-color, $btn-secondary-background, $btn-secondary-border);
    background: none!important;
    color: #426DA9!important;
    text-decoration: none;
    font-family: Tahoma,helvetica,arial,sans-serif;
    border-radius: 0!important;
    padding: 0!important;
    border: none!important;
    margin: 0!important;
    line-height: normal!important;
    font-size: 14px!important;

}

.ups-style .btn-change-country:hover,
.ups-style .btn-change-country:focus {
    background: none!important;
    color: #4A5B7F!important;
    text-decoration: underline;
}

.ups-cta.ups-cta_secondary {
    background: none;
    color: #426DA9;
    text-decoration: underline;
    font-family: $font-stack-1;
    /* border-radius: 0px; */
}

.ups-cta:focus {
    outline-offset: 2px;
    box-shadow: 0 0 6px $ups-sienna;
}

.ups-readerTxt {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.ups-style h1 {
    color: #300;
    padding-top: 51px;
    padding-bottom: 15px;
    position: relative;
    margin: 0;
    font-size: 2.286em;
    margin-right: 30px;
    padding-bottom: 5px;
}

.ups-style .app-title {

}

.ups-style .app-title {
    padding-top: 0;
    padding-left: 32px;
    font-weight: 400;
    font-size: 2rem;
}

.ups-style h1.has-breadcrumb {
    padding-top: 15px;
    margin-bottom: 15px;
    font-size: 28px;
}

.ups-style h3 {
    font-family: $font-stack-1;
    margin: 0 0 15px;
    font-size: 1.313rem;
    font-weight: normal;
}
.ups-style .dispute-page-title2{
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 9px;
}

.ups-style .dispute-page-title3{
    font-weight: 600;
    font-size: 14px;
}
.ups-style .md-form label,
.ups-style .md-form label.browser-default-label,
.ups-style legend {
    //position: relative;
    //font-family: $font-stack-1;
    //font-weight: 700!important;
    color: $ups-sienna;
    font-weight: 700;
    //margin: 0 0 5px;
    //display: inline-block;
    //width: 100%;
    //border: none;
    //font-size: 0.875rem;
    //transition: none;
    //top: auto;
    //left: auto;
    //transform: none;
    //margin: 0;
    &:focus {
        color: $ups-sienna;
    }
}

.col-legend{
    font-size: 1rem;
}


.ups-style label {
    font-family: $font-stack-1;
    font-weight: 700!important;
    // color: $primary-body-color;
    color: $ups-sienna;
}

.switch label {
    line-height: 25px;
    font-family: Tahoma, helvetica, arial, sans-serif !important;
    font-weight: normal !important;
    font-size: 14px;
    color: #242424 !important;
}

.switch span{
    padding-right: 14px;
}

.switch label .lever:after {
    top: 2.5px;
    width: 1rem;
    height: 1rem;
    background-color: #5F5753;
    left: 3px;
}

.switch label .lever {
    background-color: white;
    border: 1px solid #5F5753 ;
    width: 3rem;
    height: 24px;
}

.switch label input[type="checkbox"]:checked + .lever {
    background-color: $ups-teal;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    background-color: white;
    box-shadow: none;
    left: 1.8rem;
}

.ups-style .font-weight{
    font-weight: bold;
}

label.multi-select-label {
    //position: relative;
    //font-family: $font-stack-1;
    ////font-weight: 700!important;
    //color: $primary-body-color;
    //margin: 0 0 5px;
    //display: inline-block;
    //width: 100%;
    //border: none;
    //font-size: 1em;
    //transition: none;
    //top: auto;
    //left: auto;
    //transform: none;
}

label.multi-select-label {
    position: absolute;
    top: 12px;
    left: 0px;
    transition: .2s ease-out;
    cursor: text;
    color: #333;
    font-weight: 700;
    font-size: .8rem;
    -webkit-transform: translateY(-140%);
    transform: translateY(-140%);
    height: auto;
    bottom: 0px;
}

.ups-style .browser-default.custom-select,
.dataTables_length select {
    @include select();
}

.ups-style .browser-default.custom-select {
    white-space: normal!important;
    text-overflow: ellipsis;
}

.ups-style input.select-dropdown {
    @include select();
    //border: 1px solid #757575 !important;
    //border-radius: 7px!important;
    padding-right: 35px!important;
    height: 41px;
    padding-bottom: 2px;
}

.ups-style .select-wrapper .caret {
    display: none;
}

.ups-style .md-form {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.ups-style select.browser-default,
.ups-style select.browser-default:focus,
.ups-style select.browser-default:active {
    padding: 0.45rem 1.4rem 0.55rem 0.5rem;
}

.ups-style .md-form textarea.form-control {
    padding: 0.5rem 0;
    resize: none;
    overflow-y: auto;
}

.ups-style .popover-wrapper{
    display: inline-block !important;
}

.ups-style .preferences{

    .popover-div{
        color: #c67d30!important;
        font-weight: 700!important;
        white-space: nowrap;
        font-size: 12px;
        margin-bottom: -5px;
    }

    .pbt-dropdown-div{
        font-size:16px;
    }

    .pbt-div{
        color: #242424!important;
        font-weight: 800!important;
        white-space: nowrap;
        font-size: 12.8px;
        margin-bottom: -5px;
        top: 5px;
        margin-bottom: 5px;
    }

    select.browser-default{
        font-size: 1rem !important;
        font-weight: normal !important;
        margin-bottom: 10px !important;
    }

    h3.has-breadcrumb {
        font-family: $font-stack-1;
        margin: 0 0 15px;
        font-size: 1.313rem;
        font-weight: normal;
        color: $ups-sienna;
    }

    .pref-header-row{
        align-items: flex-end;
    }

    .header-note{
        margin-bottom: 10px;
    }

    .form-check{
        padding-left: 0px;
    }

    .form-check-input[type="checkbox"] + label {
        font-size: 14px;
    }
    
    .md-form .number-aligned {
        margin-top: 5px;
        margin-bottom: 19px;
        .pref-input.form-control {            
            padding: 0px 15px !important;
            margin: 0 0 7px 0;
            width: 50px;
            font-size: 14px !important;
        }

        label{
            width: auto;
        }
    }

    .md-form {
        margin-top: 5px;
        margin-bottom: 5px;
        min-width: 50px;
        .pref-input.form-control {            
            padding: 0px 15px !important;
            margin: 0 0 13px 0;
            width: 50px;
            font-size: 14px !important;
            text-align: center;
        }

        label{
            width: auto;
        }
    }


    .pref-input{
        top : -6px;
        position: relative;
        font-weight: normal!important;
        font-size: 11.2px!important;

    }
    .pref-input-label{
        top : -7px;
        position: relative;
        font-weight: normal!important;
        font-size: 14px!important;
        margin-left: 8px;
    }

    .pref-input-label-l1{
        margin-right: 10px!important;
        top : -7px;
        position: relative;
        font-weight: normal!important;
        font-size: 14px!important;
        white-space: nowrap;
    }

    .pbt-days-prior .invalid-status div {
        margin-top: 25px !important;
        margin-left:0px !important;
    }

    .numeric-input-view-p1{
        display: inline-flex;
        align-items: baseline;

        .invalid-status{
            width: auto;
            display: contents;

            div{
                position: absolute;
                margin-top: 40px;
                margin-left: 32px;
                font-size: 11.2px!important;
                font-weight: normal;
                margin-left: 100px!important;
                margin-top: 42px!important;
            }
        }
    }


    .numeric-input-view{
        display: inline-flex;
        align-items: baseline;

        .invalid-status{
            width: auto;
            display: contents;

            div{
                position: absolute;
                margin-top: 40px;
                margin-left: 32px;
            }
        }
    }

    .md-form input[type="number"]:not(.browser-default):focus:not([readonly]){
        padding: 0px;
    }

    .pref-section{
        border: 1px solid #e0e4e8;
        width: 100%;

        span {
            font-size: 14px;
        }

        padding-bottom: 10px;
    }

    .update-btn{
        margin-top: 10px;
        float:right;
    }

    .pref-header{
        padding: 5px 10px;
        background-color: lightgray;
    }

    .required-note{
        font-style: italic;
        font-size: 12px;
        margin-bottom: 20px;
    }

    .display-plaintext{
        font-size: 16px;
    }

    .card.dashboard-widget-card {
        .card-title {
            padding: 30px 0px 0 30px;
        }

        .card-body {
            padding: 0px 0px 0px 30px;
        }
    }

    .notify-align {
        margin-left: -60px!important;

        .btn {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    
    .card.dashboard-widget-card {
        overflow-y: auto;
    }

    .card.dashboard-widget-card .card-body {
        overflow: unset;
    }

    .card.dashboard-widget-card .card-body:hover{
        overflow-y: unset;
    }

}

.error-page {
    padding: 20px;
    text-align: center;
}

.ups-style .md-form input:not([type]):focus:not([readonly]),
.ups-style .md-form input[type="text"]:not(.browser-default):not(.DateInput_input):focus:not([readonly]),
.ups-style .md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="search-md"]:focus:not([readonly]){
    padding: 0.45rem 0.5rem 0.55rem 0;
}

.ups-style select.browser-default:active,
.ups-style .md-form input:not([type]):focus:not([readonly]),
.ups-style .md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.ups-style .md-form input[type="search-md"]:focus:not([readonly]) {
    box-shadow: none;
    border-bottom: 1px solid $ups-sienna;

}

.ups-style .md-form textarea.md-textarea:focus:not([readonly]) {
    padding: 0.5rem 0;
    box-shadow: none;
    border-bottom: 1px solid $ups-sienna;
}


.ups-style .md-form input:not([type]):focus:not([readonly])+label,
.ups-style .md-form input[type="text"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="password"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="email"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="url"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="time"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="date"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="tel"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="number"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="search"]:not(.browser-default):focus:not([readonly])+label,
.ups-style .md-form input[type="search-md"]:focus:not([readonly])+label,
.ups-style .md-form textarea.md-textarea:focus:not([readonly])+label {
    color: $ups-sienna;
}

.ups-style .md-form.md-browser-default-form:after {
    top: 32px;
    right: 8px;
    content: ' ';
    color: $ups-teal;
    display: none;

}

.ups-style .md-form.md-browser-default-form label {
    top: 12px;
}
.ups-style .md-form.md-browser-default-form:before {
    //background: #fff url(../images/icon/ups/039-chevrondown.svg) no-repeat 0 0;
    //fill: red;
    background-color: $ups-teal-dark;
    -webkit-mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-size: 15px 15px;
    content: ' ';
    height: 15px;
    width: 15px;
    display: block;
    position: absolute;
    right: 15px;
    top: 14px;
    pointer-events: none;
}

svg.ups-input-icon {
    position: absolute;

    fill: $ups-teal;
    height: 14px;
    width: 14px;
    pointer-events: none;
    right: 16px;
    top: 10px;
}

.ups-btn-disable{
    opacity: 0.3;
    pointer-events: none;
}

label:not(.sr-only) ~ svg.ups-input-icon {
    top: 32px;
    right: 16px;
}

.datepicker-form + svg.ups-input-icon.ups-icon-calendar {
    width: 21px;
    height: 21px;
    top: 29px;
    right: 24px
}

.datepicker-form label {
    line-height: 1.25!important;
}

.datepicker-form input {
    padding-right: 70px!important;
}

.ups-style .browser-default.custom-select:before {
    content: "\e626";
    position: absolute;
    text-align: center;
    color: $ups-teal;
    top: 0px;
    width: 46px;
    height: 35px;
    font-family: "upsicons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 35px;
    right: 0px;
}

.ups-style input[type="checkbox"]:checked {
    box-shadow: none;
    content: " ";
    position: absolute;
    left: 10px;
    top: 5px;
    width: 5px;
    height: 12px;
    border-right: 3px solid $ups-teal;
    border-bottom: 3px solid $ups-teal;
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 0;
}

//.ups-style .md-form.md-browser-default-form {
//    margin-top: 0;
//    margin-bottom: 15px;
//}

.ups-style .md-form input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]),
.ups-style .md-form input[type=tel]:not(.browser-default) {
    //display: block!important;
    //height: 35px!important;
    //border: 1px solid $ups-stroke;
    //padding: 0 10px!important;
    padding-left: 0;
    //font-size: 14px!important;
    font-family: $font-stack-1;
    font-weight: normal;
    //font-weight: 700!important;
    box-sizing: border-box!important;
    //text-align: left!important;
    //margin-bottom: 0;
}

.ups-style .md-form input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]).select-dropdown {
    padding-right: 35px!important;
}

// Fix IE11 background: initial overlaying the selection dropdown caret and spacing issue
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ups-style .md-form input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]).select-dropdown {
        background: transparent!important;
    }
}


// mui date picker customization
.ups-style .md-form input[type=text][class^="MuiInputBase-input"] {
    display: block!important;
    height: 35px!important;
    border: 1px solid $ups-stroke;
    padding: 0 10px!important;
    font-size: 14px!important;
    font-family: $font-stack-1;
    //font-weight: 700!important;
    box-sizing: border-box!important;
    text-align: left!important;
    margin-bottom: 0;
}

.ups-style div[class^="MuiPickersToolbar-toolbar"] {
    background-color: $ups-brown;
}

.ups-navItems_primary {
    margin-left: 25%;
    padding-top: 85px;
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    padding-left: 0;
}

.ups-navMenu {
    float: left;
    clear: right;
    padding: 12px 15px 0;
    list-style-type: none;
}

.ups-navItems {
    height: 135px;
    width: 100%;
}

.ups-primary-nav {
    position: absolute;
    bottom: 0px;
    left: 128px;
}

.ups-secondary-nav {
    position: absolute;
    top: 15px;
    right: 0px;
}

.ups-primary-nav li {
    float: left;
    clear: right;
    padding: 12px 15px 0;
    list-style-type: none;
}

.ups-secondary-nav li {
    float: left;
    clear: right;
        //margin-left: 15px;
    list-style-type: none;
    float: left;
    padding: 0 20px;
    border-right: 1px solid #757575;
    color: #fff;
}

.ups-secondary-nav li:last-child {
    border: none;
}

.ups-style .dropdown-header {
    font-weight: 700;
    color: $primary-body-color;
    padding: 15px 0 15px 0;
    border-top: 1px solid $ups-neutral-gray-1;
    border-bottom: 1px solid $ups-neutral-gray-1;

}

.ups-style .dropdown-header:first-child {
    border-top: none!important;
}

.ups-primary-nav-item,
.ups-style .btn-dropdown-ups {
    -webkit-appearance: none;
    border-radius: 0;
    background: none!important;
    border: none;
    line-height: 1;
    display: block;
    margin: 0;
    color: #fff;
    font: 1.1em/1 $font-stack-1;
    font-weight: 700!important;
    letter-spacing: 0.07em;
    padding: 0 0 18px!important;
    height: auto;
    text-align: center;
    outline: none;
    -moz-transition-property: all;
    -o-transition-property: all;
    -webkit-transition-property: all;
    transition-property: all;
    -moz-transition-duration: .2s;
    -o-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    text-transform: initial;
    box-shadow: none!important;
}


.ups-primary-nav-item:hover,
.ups-style .btn-dropdown-ups:hover {
    outline: none;
    text-decoration: underline;
    color: #fff;
}

.ups-primary-nav-item:focus,
.ups-style .btn-dropdown-ups:focus {
    //outline: 1px auto #FFD100!important;
    //outline-offset: -1px!important;
    outline: none!important;
    box-shadow: none!important;
}

.ups-primary-nav-item:focus:before,
.ups-style .btn-dropdown-ups:focus:before {
    content: '';
    display: block;
    position: absolute;
    height: 8px;
    bottom: 0;
    width: 100%;
    background: #D49D64;
    border-radius: 0!important;
}

.ups-style .btn:not(.btn-floating):not(.sr-only).btn-dropdown-ups {
    border-radius: 0!important;
}

.ups-style .btn:not(.btn-floating):not(.sr-only).btn-dropdown-ups-secondary {
    border-radius: 0!important;
}

.ups-style .navbar.logged-in.fixed-top {
    position: fixed;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)!important;
    height: 68px;
}

.navbar .ups-navItems .dropdown-menu {
    margin-top: 0px;
    padding: 30px;
    background: #fff;
    border: 1px solid #d9d9d6;
    border-top: 5px solid #D49D64;
    border-radius: 0;
}

.navbar .ups-navItems .dropdown-menu .dropdown-item {
        display: block;
    color: $primary-body-color;
    padding: 10px 13px 10px 0;
}

.navbar .ups-navItems .dropdown-menu .dropdown-item:hover {
    background-color: #fff!important;
        color: #6e6e6e!important;
        text-decoration: underline!important;
}



.ups-secondary-nav-item,
.ups-style .btn-dropdown-ups-secondary {
    -webkit-appearance: none;
    border-radius: 0;
    background: none!important;
    border: none;
    line-height: 1;
    display: block;
    margin: 0;
    color: #fff;
    font: 0.9em/1 $font-stack-1;
    padding: 0px 0px 9px 0!important;
    height: auto;
    text-align: center;
    font-weight: 400!important;
    outline: none;
    -moz-transition-property: all;
    -o-transition-property: all;
    -webkit-transition-property: all;
    transition-property: all;
    -moz-transition-duration: .2s;
    -o-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    text-transform: initial;
    box-shadow: none!important;
}


.ups-secondary-nav-item:hover,
.ups-style .btn-dropdown-ups-secondary:hover {
    outline: none;
    text-decoration: underline;
    color: #fff;
}

.ups-secondary-nav-item:focus,
.ups-style .btn-dropdown-ups-secondary:focus {
    //outline: 1px auto #FFD100!important;
    //outline-offset: -1px!important;
    outline: none!important;
    box-shadow: none!important;
}

.ups-secondary-nav-item:focus:before,
.ups-style .btn-dropdown-ups-secondary:focus:before {
    content: '';
    display: block;
    position: absolute;
    height: 8px;
    bottom: 0;
    width: 100%;
    background: #D49D64;
    border-radius: 0!important;
}

.navbar .ups-navItems .ups-secondary-nav .dropdown-menu {
    padding: 12px 22px;
}


.ups-navMenu .ups-menu_list {
    position: absolute;
    display: none;
    z-index: 1;
    right: 0;
    top: 100%;
    margin-top: 0px;
    padding: 30px 0 0;
    background: #fff;
    border: 1px solid #d9d9d6;
    border-top: 5px solid #D49D64;
}

.ups-navMenu .ups-menu_toggle {
    -webkit-appearance: none;
    border-radius: 0;
    background: none;
    border: none;
    padding: 0;
    line-height: 1;
    display: block;
    margin: 0;
    color: #fff;
    font: 1.1em/1 $font-stack-1;
    letter-spacing: 0.07em;
    padding: 0 0 8px;
    height: 35px;
    text-align: center;
    font-weight: bold;
    outline: none;
    -moz-transition-property: all;
    -o-transition-property: all;
    -webkit-transition-property: all;
    transition-property: all;
    -moz-transition-duration: .2s;
    -o-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
}

.ups-style .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
}

.ups-style .form-check-input[type="checkbox"]+label {
     position: relative;
     line-height: 25px;
     padding-left: 32px;
     font-family: $font-stack-1;
     font-weight: normal!important;
     color: $primary-body-color;
    &.font-weight-bold {
        font-weight: 700!important;
    }
 }

.ups-style .form-check-input[type="checkbox"]+label:before{
    content: '';
    background: #fff;
    border: 1px solid $ups-stroke;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 2px;
    text-align: center;
    position: absolute;
    border-radius: 5px;
    margin-right: 10px;
    left: 0;
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    top: auto;
    transition: none;
}

.ups-style .form-check-input[type="checkbox"]:checked+label:after {
    box-shadow: none;
    content: " ";
    position: absolute;
    left: 10px;
    top: 5px;
    width: 5px;
    height: 12px;
    border-right: 3px solid $ups-teal;
    border-bottom: 3px solid $ups-teal;
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 0;
    transition: none;
    border-radius: 0;
}

.ups-style .form-check-input[type="checkbox"]:focus+label:before {
    background: #FFDC40!important;
    box-shadow: 0 0 6px $ups-sienna;
}

.ups-style .form-check-input[type="radio"]+label {
    position: relative;
    line-height: normal;
    padding-left: 36px;
    //padding-bottom: 15px;
    font-family: $font-stack-1;
    font-weight: normal!important;
    color: $primary-body-color;
    height: auto;
}

.ups-style .form-check-input[type="radio"]+label:before{
    //content: '';
    //background: #fff;
    //border: 1px solid $ups-stroke;
    //display: inline-block;
    //vertical-align: middle;
    //width: 24px;
    //height: 24px;
    //padding: 2px;
    //text-align: center;
    //position: absolute;
    //border-radius: 5px;
    //margin-right: 10px;
    //left: 0;
    //-o-transform: rotate(0deg);
    //-webkit-transform: rotate(0deg);
    //-ms-transform: rotate(0deg);
    //-moz-transform: rotate(0deg);
    //transform: rotate(0deg);
    //top: auto;
    //transition: none;
    content: ' ';
    background: #fff;
    border: 1px solid #757575;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 0px;
    text-align: center;
    position: absolute;
    margin-right: 10px;
    top: -7px;
    left: -5px;
    border-radius: 50%;
    transition: none!important;
}

.ups-style .form-check-input[type="radio"]:checked+label:before {
    //background: #00857D;
    box-shadow: inset 0px 0px 0px 4px #fff;
}

.ups-style .form-check-input[type="radio"]:checked+label:after {
    //box-shadow: none;
    //content: " ";
    //position: absolute;
    //left: 10px;
    //top: 5px;
    //width: 5px;
    //height: 12px;
    //border-right: 3px solid $ups-teal;
    //border-bottom: 3px solid $ups-teal;
    //-o-transform: rotate(45deg);
    //-webkit-transform: rotate(45deg);
    //-ms-transform: rotate(45deg);
    //-moz-transform: rotate(45deg);
    //transform: rotate(45deg);
    //padding: 0;
    //transition: none;
    //border-radius: 0;
    box-shadow: 0 0 6px #C67D30;
    box-shadow: none!important;
    content: ' ';
    width: 14px;
    height: 14px;
    position: absolute;
    border-radius: 100%;
    margin: 0;
    left: 4px;
    top: 2px;
    transform: none;
    background-color: $ups-teal;
    border: none!important;
    transition: none!important;
}


.ups-style .form-check-input[type="radio"]:not(:focus) +label:after {

}

.ups-style .form-check-input[type="radio"]:focus +label:before {
    //background: #FFDC40!important;
    box-shadow: 0 0 6px $ups-sienna;
}
//
//.dropdown .dropdown-menu{
//    -webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) alternate both;
//    animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) alternate both;
//}
//@-webkit-keyframes swing-in-top-fwd {
//    0% {
//        -webkit-transform: rotateX(-100deg);
//        transform: rotateX(-100deg);
//        -webkit-transform-origin: bottom;
//        transform-origin: top;
//        opacity: 0;
//    }
//    100% {
//        -webkit-transform: rotateX(0deg);
//        transform: rotateX(0deg);
//        -webkit-transform-origin: top;
//        transform-origin: top;
//        opacity: 1;
//    }
//}
//@keyframes swing-in-top-fwd {
//    0% {
//        -webkit-transform: rotateX(-100deg);
//        transform: rotateX(-100deg);
//        -webkit-transform-origin: top;
//        transform-origin: top;
//        opacity: 0;
//    }
//    100% {
//        -webkit-transform: rotateX(0deg);
//        transform: rotateX(0deg);
//        -webkit-transform-origin: top;
//        transform-origin: top;
//        opacity: 1;
//    }
//}

.ups-wrap {
    clear: both;
    padding: 0;
    *zoom: 1;
}

.ups-wrap_inner {
    padding: 0;
    margin: 0 auto;
    max-width: 1400px;
    position: relative;
}

.ups-style .ups-breadcrumb {
    font-size: 14px;
    color: #404040;
    padding-top: 5px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.ups-style .ups-breadcrumb-item a.ups-link:link,
.ups-style .ups-breadcrumb-item a.ups-link:visited {
    text-decoration: underline;
    padding: 0!important;
    display: inline-block;
    vertical-align: top;
}


.ups-style span.ups-link{
    cursor: pointer;
    color: $ups-blue;
    text-decoration: underline;
    padding: 0!important;
    display: inline-block;
    vertical-align: top;
}

.ups-style span.in-dispute{
    display: block;
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}
.ups-style .ups-breadcrumb .ups-breadcrumb-item {
    float: left;
    margin-right: 5px;
    display: block;
}

/* invoice summary start */
.card-invoice-summary {

}

.ups-card-section h2,
h2.ups-section-heading{
    background-color: $ups-neutral-gray-1;
    color: $primary-body-color;
    padding: 7px 15px;
    font-size: 26px;
    font-size: 1.625rem;
    font-size: 21px;
    margin: 0;
}

.ups-card-section .dropdown{
    top: 5px;
    right: 16px;
}

.ups-card-invoice-section {

}

/* invoice summary end */


/* widths start */
.ups-style .ups-weekly-end-date-col {
    width: 178px;
}

.ups-style .ups-billed-charge-col {
    width: 256px!important;
    padding-right: 36px!important;
}

/* widths end */

/* demo purposes only start */
.toggle-ups-variations {
    position: fixed;
    bottom: 30px;
    left: 0;
    z-index: 108;
    width: 267px;
    text-align: center;
    color: $primary-body-color;
    z-index: 1041;
    font-size: 12px;
}

.toggle-ups-variations p {
    margin-bottom: 0!important;
    font-weight: bold;
}

.toggle-ups-variations .btn:not(.btn-floating):not(.sr-only) {
    padding: 0!important;
    margin: 0!important;
    background-color: transparent!important;
    text-transform: capitalize!important;
    box-shadow: none!important;
    color: $ups-blue;
    text-decoration: none;
    //display: block!important;
}

.toggle-ups-variations .btn:not(.btn-floating):not(.sr-only).current-variation {
    font-weight: bold;
    color: $ups-teal;
}

.toggle-ups-variations .btn:not(.btn-floating):not(.sr-only):hover {
    text-decoration: underline;
}

/* demo purposes only end */

.ups-style .md-accordion {

}

.md-accordion .card {
    border-top: none!important;
    border-bottom: 1px solid #757575!important;
    border-radius: 0!important;
}

#advancedSearch{

    & .card {
        //border-top: none!important;
        //border-bottom: 1px solid #757575!important;
        border-radius: 0!important;
        border: none!important;
        display: block;
    }
    
    & .card .card-header {
        //border-top: 1px solid #757575 !important;
        padding: 12px 0px!important;
        -moz-transition-property: all;
        -o-transition-property: all;
        -webkit-transition-property: all;
        transition-property: all;
        -moz-transition-duration: 0.15s;
        -o-transition-duration: 0.15s;
        -webkit-transition-duration: 0.15s;
        transition-duration: 0.15s;
        border-radius: 0 !important;
        display: inline-block!important;
        width: auto!important;
        border-top: none!important;
    }
    
    & .card-header h5 {
        color: $ups-teal;
        font-weight: normal!important;
        &.card-title {
            font-family: Tahoma,helvetica,arial,sans-serif;
            font-weight: normal!important;
            font-size: 24px;
            color: #242424;
            position: relative;
            padding-left: 0;
            margin-top: 0;
            border: none;;
            margin: 0;
            padding: 0;
        }
        .fa {
            font-size: 16px;
            margin-left: 0.5rem;
        }
    }
    
    & .card .card-header.collapsed-active {
        //background: #DDD7D0;
        font-family: Tahoma,helvetica,arial,sans-serif;
    
        //border-top: 1px solid #F7F6F5!important;
    }
  
}

.ups-style .md-accordion .card .card-header {
    border-top: 1px solid #757575 !important;
    padding: 12px 30px 12px 12px !important;
    -moz-transition-property: all;
    -o-transition-property: all;
    -webkit-transition-property: all;
    transition-property: all;
    -moz-transition-duration: 0.15s;
    -o-transition-duration: 0.15s;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    border-radius: 0 !important;
}

.ups-style .md-accordion .card .card-header.collapsed-active {
    background: #DDD7D0;
    font-family: Tahoma,helvetica,arial,sans-serif;
    border-top: 1px solid #F7F6F5!important;
}

.ups-style .card-header h5 {
    font-size: 16px;
    font-weight: 700!important;
    color: $primary-body-color;
    line-height: 1.5;
}

.ups-style .card-header h5 i.fa {
    font-size: 20px;
}

a.datatable-nav-link:link,
a.datatable-nav-link:visited {
    padding: 0;
    color: $ups-blue;
    text-decoration: underline;
}

p.invoice-summary-billing-address {
    font-size: 16px;
}

.ups-style .card-invoice-sub-section td {
    font-size: 16px!important;
    color: $primary-body-color;
}

.ups-style .card-invoice-sub-section td[scope="row"] {
    font-weight: 700;
}

.ups-style hr.hr-thick {
    border-top-width: 8px;
    border-color: $ups-neutral-gray-1;
    margin-top: 30px;
    margin-bottom: 30px;
}

.ups-style .form-check-input[type="radio"]:disabled+label {
    color: $ups-gray-inactive-text;
}

.ups-style .form-check-input[type="radio"]:disabled+label:before {
    background-color: $ups-gray-inactive-background;
}

/* modals start */
.ups-style .modal-dialog .modal-content {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    padding: 49px 35px 42px 35px;
    .md-form.md-browser-default-form:before {
        right: 5px;
    }
}

.ups-style .modal-header {
    border: none;
    padding: 0!important;
}

.ups-style .modal-title {
    font-family: $font-stack-1;
    font-weight: normal;
    font-size: 1.625rem;
    color: #242424;
    position: relative;
    padding-left: 0;
    margin-top: 0;
    padding-right: 3.5rem;
}

.ups-style .modal-title-center h2 {
    width: 100%;
    text-align: center;
}

.notification-modal .modal-title{
    margin-top: -10px;
}

.ups-style .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.ups-style .modal-footer {
    display: block;
    text-align: center;
    border: none;
    padding: 0;
}

.ups-style .modal-backdrop.show {
    background: #fff;
    opacity: 0.5;
}

.ups-style .modal-header .close {
    font-size: 36px;
    position: absolute;
    top: 7px;
    right: 31px;
    padding: 0!important;
    color: #000;
    text-shadow: none;
    opacity: 1;
    margin: 0;
    line-height: normal;
}

//a:focus, button:focus, input:focus, select:focus, textarea:focus {
//    outline: 5px auto #C67D30;
//}
/* modals end */

.ups-style .side-nav .logo-wrapper {
    height: auto!important;
}

.ups-style .side-nav .logo-wrapper img {
    max-width: 48px;
    width: 100%;
    padding: 0;
    max-height: none;
}

.ups-style .side-nav {
    //background-color: $ups-brown;
}

.mi {
    &:before {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }
    &[class*="side-nav-icon"]:before {
        position: absolute;
        left: 12px;
        top: 6px;
    }
}

.fa-custom {
    &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
    }

    &[class*="side-nav-icon"] {
        &:before {
            position: absolute;
            left: 12px;
            top: 8px;
            font-size: 24px;
        }

        &.fa-file-alt:before {
            left: 16px;
            font-size: 22px;
        }

        &.fa-user-shield:before {
            font-size: 18px;
            left: 15px;
        }

        &.fa-user-headset:before {
            font-size: 21px;
            left: 14px;
            /* asfdaff */
        }
    }
}

.side-nav-icon-admin:before,
.mi-admin-panel-settings:before {
    content: '\ef3d';
}

.side-nav-icon-user-shield:before {
    content: '\f505';
}

.side-nav-icon-profile:before {
    content: '\e8b8';
}

.side-nav-icon-help-support.active:before {
    color: $ups-teal;
}

.ups-style .poweredby p.last-login {
    font-size: 14px;
}

.ups-style .side-nav .collapsible a.active {
    color: $ups-teal;
}

.ups-style .side-nav .collapsible a.active:hover {
    color: $ups-teal;
}

.ups-style .side-nav .collapsible a:hover {
    color: $primary-body-color;
}

p.placeholder-text {
    color: $primary-body-color;
    margin: 15px 0px;
}

//.ups-style .navbar {
//    display: none;
//}

//.btn:not(.btn-floating):not(.sr-only).buttons-collection.btn-ups-datatable-dropdown {
//    border-radius: 4px!important;
//}
.buttons-collection.btn-ups-datatable-dropdown ~ .dt-button-collection {
    position: absolute;
    width: 256px;
    height: 256px;
    background-color: #fff;
    border: 1px solid #333;
    z-index: 2002;
}


div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0,0,0,0.3)), color-stop(1, rgba(0,0,0,0.7)));
    background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    background: radial-gradient(ellipse farthest-corner at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
    z-index: 2001;
}

.buttons-collection[aria-expanded="true"] {
    pointer-events: none!important;
}

.browser-default-select:before {
    background-color: $ups-teal-dark;
    -webkit-mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-size: 15px 15px;
    content: ' ';
    height: 15px;
    width: 15px;
    display: block;
    position: absolute;
    right: 15px;
    top: 27px;
    pointer-events: none;
}

.browser-default-select.has-sr-only:before {
    top: 10px;
}

.ups-style div.datepicker-form:before {
    background-color: $ups-teal-dark;
    -webkit-mask-image: url(../images/icon/ups/031-calendar.svg);
    mask-image: url(../images/icon/ups/031-calendar.svg);
    mask-size: 21px 21px;
    content: ' ';
    height: 21px;
    width: 21px;
    display: block;
    position: absolute;
    right: 12px;
    top: 7px;
    left: auto;
    pointer-events: none;
}

.ups-style .select-wrapper {
    .select-dropdown {
        .md-form {
            margin-top: 0;
            margin-bottom: 0;
            // MDBSelect searchId prop doesn't seem to work
            // so all MDBSelect with search enabled use the same ID
            #selectSearchInput,
            .form-control {
                padding: 1.25rem .5rem 0.5rem 0.5rem;
            }
            label {
                left: 8px;
                pointer-events: none; // this prevents closing the opened menu when the search label is clicked, although we shouldn't have to do this but we do
                &.active {
                    -webkit-transform: translateY(-6px) scale(0.8);
                    transform: translateY(-6px) scale(0.8);
                }
            }
        }
    }
}

.ups-style .select-wrapper:before {
    background-color: $ups-teal-dark;
    -webkit-mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-size: 15px 15px;
    content: ' ';
    height: 15px;
    width: 15px;
    display: block;
    position: absolute;
    right: 15px;
    top: 11px;
    top: 14px;
    pointer-events: none;
}

.ups-style .mdb-autocomplete-clear {
    color: $ups-teal-dark;
    font-size: 18px;
    right: 10px;
}

.ups-style .mdb-autocomplete-clear i.fa {
    color: $ups-teal-dark;
}

.ups-style .autocomplete-form {
    margin-bottom: 1.5rem;
    overflow: visible;
    position: relative;
}

.ups-style .autocomplete-form label.autocomplete-default-label {
    color: $ups-sienna;
    font-weight: 700;
    position: absolute;
    top: 12px;
    left: 0px;
    transition: .2s ease-out;
    cursor: text;
    color: #333;
    font-size: .8rem;
    -webkit-transform: translateY(-140%);
    transform: translateY(-140%);
    height: auto;
    z-index: 1;
}

.ups-style .select-wrapper .form-check-input[type="checkbox"] + label {
    display: inline-block!important;
    width: auto!important;
    top: 0px;
}

//.ups-style .select-wrapper .select-dropdown {
//    padding-bottom: 15px;
//}

.ups-style .dropdown-content li {
    background-color: #fff;
}

.ups-style .dropdown-content li:hover {
    background-color: $ups-neutral-gray-2;
}

.dropdown-content li>a, .dropdown-content li>span {
    color: $primary-body-color;
}

.global-ups-country {
    color: $primary-body-color;

    .md-form{
        margin-top: 1.5rem;
    }

    select.browser-default{        
        font-size: 14px !important;
        font-weight: normal !important;
        margin-bottom: 10px !important;
    }

    .btn{
        font-size: 0.875rem !important;
    }

    .form-check-input{
        font-size: 0.875rem !important;
    }

    .btn-cancel{
        padding: 10px 10px 10px !important;
    }

    .btn-primary{
        padding: 10px 22px 10px !important;
    }

    .md-form.md-browser-default-form:before {
        right: 5px;
    }
}

.ups-style.v2-theme .side-nav .logo-wrapper img {
    max-width: 67px;
    width: 100%;
    padding: 0;
    max-height: none;
}

.ups-style .side-nav .collapsible {
    margin-top: 0!important;
}

.ups-style.v2-theme .navbar.logged-in.fixed-top {
    display: none!important;
}

.ups-style.v2-theme .ups-main.logged-in {
    padding-top: 3px!important;
}

.billing-center-title {
    color: $primary-body-color;
    text-align: center;
    font-size: 18px;
    padding-top: 15px;
    margin: 0;
    display: none;
}

.v2-theme .billing-center-title {
    display: block;
    color: #fff;
}

.ups-style.v3-theme .navbar.logged-in.fixed-top {
    background-color: $ups-brown;
    color: #fff!important;
    height: 88px!important;
}

.ups-style.v3-theme .app-title h1 {
    color: #fff;

}

.ups-style.v3-theme .ups-main.logged-in {
    padding-top: 100px;
}

.ups-style.v3-theme .side-nav .logo-wrapper {
    background-color: $ups-brown;
    height: 88px!important;
    margin: 0!important;
}

.ups-style.v3-theme #main-nav ul.list-unstyled {
    padding: 0!important;
}

.ups-style.v3-theme ul.main-nav-ul {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;

}

.ups-style.v3-theme #main-nav {
    border: none!important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.12) !important;
}

.ups-style.v3-theme .side-nav .logo-wrapper a {
    width: 100%!important;
}

.ups-style.v3-theme .global-ups-country  {
    display: none;
}

.ups-style .ups-wrap_inner {
    max-width: none;
}

.ups-style td .form-check-input[type="checkbox"] + label {
    top: 4px;
    left: 8px;
    padding-left: 8px;
}

.ups-style th .form-check-input[type="checkbox"] + label {
    top: 4px;
    left: 8px;
}

.ups-style table.dtr-column.collapsed th .form-check-input[type="checkbox"] + label {
    left: 32px;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; right: 50px }

.ups-style .btn.btn-ups-mobile-only {
    display: none!important;
}

.ups-style .table-body .form-check-input {
    width: auto;
    margin: 0;
}


h5.sortable-list-heading {
    font-size: 1rem;
}

.sortable-list-left-container {
    position: relative;
    display: block;
    padding: 0.5rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    min-height: 196px;
}

.sortable-list-right-container {
    position: relative;
    display: block;
    padding: 0.5rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    min-height: 198px;
    counter-reset: section;
}

.sortable-list-item {
    position: relative;
    display: block;
    padding: .600rem 0.75rem;
    margin-bottom: -1px;
    background-color: $ups-brown-light-10;
    border: 1px solid $ups-brown-light-50;
    cursor: move;
}

.sortable-list-item.sortable-chosen {
    background-color: $ups-sienna;
}

.sortable-list-item:focus {
    background-color: $ups-sienna;
}

.sortable-list-right-container .sortable-list-item:before {
    counter-increment: section;
    content: counter(section);
    display: inline-block;
    //background-color: $ups-brown-dark;
    color: $ups-brown-dark;
    margin-right: 0.75rem;
    font-weight: 700;
}


.sortable-list-item:last-child {
    margin-bottom: 0!important;
}

.SingleDatePickerInput {
    .DateInput {
        outline: 0 !important;
        box-shadow: none;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        padding-left: 0;
    }

    .DateInput_input {
        padding-left: 0;
        margin-bottom: 0!important;
    }

    .DateInput_input::placeholder {
        color: $ups-gray-inactive-text;
    }

    .SingleDatePickerInput_calendarIcon {
        position: absolute;
        right: -2px;
        top: 2px;
    }

    .DayPicker {
        position: relative!important;
        z-index: 1!important;
        right: 0;
        margin-top: 48px;
        padding-left: 0;

        .DayPicker_calendarInfo__horizontal .btn {
            display: block!important;
            width: 100%;
        }
    }
}

.DateRangePicker {
    position: absolute!important;
    display: inline-bock;
    right: 0!important;
    z-index: 2!important;


    .DateInput {
        display: none;
    }

    .DateRangePickerInput_arrow {
        display: none;
    }

    .DateRangePicker_picker {
        top: 0!important;
    }
}

svg.react-dates-custom-calendar-icon {
    width: 21px;
    height: 21px;
    fill: $ups-teal-dark;
}



.DayPicker {
    position: absolute!important;
    z-index: 1!important;
    right: 0;
    margin-top: 48px;
    padding-left: 0;

    .DayPicker_calendarInfo__horizontal .btn {
        display: block!important;
        width: 100%;
    }
}

.SingleDatePicker {
    z-index: 101;
}



    /* Document Icon CSS */
.ups-style .btn-document-link
{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f1f1f1+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #f1f1f1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#f1f1f1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ffffff 0%,#f1f1f1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f1f1f1',GradientType=0 ); /* IE6-9 */
    border:1px solid #ccc;
    -moz-border-radius:3px 15px 3px 3px!important;
    -webkit-border-radius:3px 15px 3px 3px!important;
    border-radius:3px 15px 3px 3px!important;
    -moz-box-shadow:inset rgba(255,255,255,0.8) 0 1px 1px;
    -webkit-box-shadow:inset rgba(255,255,255,0.8) 0 1px 1px;
    box-shadow:inset rgba(255,255,255,0.8) 0 1px 1px;
    display:block;
    width:164px;
    min-height:196px;
    position:relative;
    margin:0px auto 15px auto;
    line-height: normal;
    padding: 8px;
    text-transform: none;
}

.ups-style .btn-document-link:hover,
.ups-style .btn-document-link:focus {

}

.btn:not(.btn-floating):not(.sr-only).btn-document-link {
    -moz-border-radius:3px 30px 3px 3px!important;
    -webkit-border-radius:3px 30px 3px 3px!important;
    border-radius:3px 30px 3px 3px!important;
}

.ups-style .btn-document-link:before
{
    content:"";
    display:block;
    position:absolute;
    top:0;
    right:0;
    width:25px;
    height:25px;
    background:#ccc;
    background:-moz-linear-gradient(45deg, #fff 0,  #eee 50%, #ccc 100%);
    background:-webkit-linear-gradient(45deg, #fff 0,  #eee 50%, #ccc 100%);
    box-shadow:rgba(0,0,0,0.05) -1px 1px 1px, inset white 0 0 1px;
    border-bottom:1px solid #ccc;
    border-left:1px solid #ccc;
    -moz-border-radius:0 14px 0 0!important;
    -webkit-border-radius:0 14px 0 0!important;
    border-radius:0 30px 0 0!important;
}

.ups-style .btn-document-link:after
{
    content:"";
    display:block;
    position:absolute;
    left:0;
    top:0;
    width:60%;
    margin:22px 20% 0;
    background:#ccc;
    background:-moz-linear-gradient(top, #ccc 0, #ccc 20%, #fff 20%, #fff 40%, #ccc 40%, #ccc 60%, #fff 60%, #fff 80%, #ccc 80%, #ccc 100%);
    background:-webkit-linear-gradient(top, #ccc 0, #ccc 20%, #fff 20%, #fff 40%, #ccc 40%, #ccc 60%, #fff 60%, #fff 80%, #ccc 80%, #ccc 100%);
    height:18px;
}

.ups-style .btn-document-link span.file-type {
    top: 35px;
    left: -2px;
    width: 74px;
    height: 35px;
    top: auto;
    bottom: 35px;
    display: block;
    position: absolute;
    border-radius: 1px;
    box-shadow: 0 0 1px rgba(0,0,0,0.2);
    color: rgb(255,255,255);
    font-family: calibri;
    font-size: 1em;
    line-height: 35px;
    text-align: center;
    text-shadow: 0 0 1px rgba(0,0,0,0.4);
    transition: top 0.1s ease;
    -webkit-transition: top 0.1s ease;
    background-color: #fff;
}

.ups-style .fake-text-link {
    color: $ups-teal;
    text-decoration: none;
    font-family: Tahoma,helvetica,arial,sans-serif;
    position: absolute;
    bottom: 15px;
    left: 0;
    text-align: center;
    width: 100%;
}

.ups-style button:hover .fake-text-link {
    text-decoration: underline;
    color: $ups-teal-dark;
}

.alert {
    display: flex;
    align-items: center;
    &.alert-inline {
        padding: 0;
        margin-top: 16px;
    }

    .alert-desc {
        padding-top: 6px !important;
    }
    &.alert-warning {
        border: 2px solid #f78b23;
        border-radius: 8px;
        background-color: #fff;
        color: $primary-body-color;
        padding: .5rem .75rem;
        font-size: .875rem;
    }
}

.invoice-list-alert-past-due, .past-due-border-left {
    border-left: 3px solid $ups-plum;
}



.alert-past-due {
    color: $ups-plum;
    font-weight: 700;
    font-size: 14px;
}

.svg-icon {
    margin-right: 15px;
}

.alert-past-due .svg-icon {
    fill: $ups-plum;
    min-width: 32px;
}

.alert-past-due.v2 .svg-icon {
    fill: #fff!important;
}

.small-svg {
    width: 21px;
    height: 21px;
    margin-right: 8px;
}

.alert-icon {
    fill: $ups-plum;
    display: inline-block;
    vertical-align: middle;
    display: none!important;
}

.status-desc {
    //color: $ups-plum;
    //font-weight: 700;
}

.status-desc.alert-desc {
    color: $ups-plum;
    font-weight: 700;
}

.calendar-settings-panel {
    width: 268px;
    padding: 15px 15px;
}

.ups-style .form-radio-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    padding-bottom: 15px;
}

.ups-style .form-radio-group-item {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
}

.ups-style input.form-check-input.form-radio-group-check {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.ups-style .form-check-input.form-radio-group-check:checked + label {
    background-color: #00857D;
    color: #fff!important;
}

.ups-style .form-check-input.form-radio-group-check + label {
    color: #000;
    border-radius: 0;
    border-left: 1px solid #757575;
    border-top: 1px solid #757575;
    border-bottom: 1px solid #757575;
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    font-size: 1em;
    line-height: normal;
    margin: 0;
    padding: 6px;
    text-align: center;
    display: flex!important;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
        vertical-align: middle;
    }
}

.ups-style .form-radio-group-item:first-child .form-check-input.form-radio-group-check +  label {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}


.ups-style .form-radio-group-item:last-child .form-check-input.form-radio-group-check +  label {
    border-right: 1px solid #757575;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.ups-style .form-check-input.form-radio-group-check + label:before {
    display: none!important
}

.ups-style .form-check-input.form-radio-group-check + label:after {
    display: none!important;
}

.DayPicker_calendarInfo__horizontal .calendar-settings-panel-actions .btn {
    display: inline-block!important;
    width: auto!important;
    vertical-align: top!important;

}

.ups-style .btn-label-link {
    box-shadow: none!important;
    padding: 0!important;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    overflow: visible!important
}

.btn-label-link svg  {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: top;
    width: 21px;
    height: 21px;
}

.card-display-popup {
    position: relative;
    z-index: 1010;

    .popover[x-placement^="right"] {
        margin-left: 30px !important;
    }

    .popover-body {
        font-size: 14px !important;
    }
}

.btn-popover-link {
    box-shadow: none!important;
    padding: 0!important;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    overflow: visible!important;
    margin-left: 5px;
    top: 8px;
    z-index: 1;

    svg  {
        position: relative;
        top: -1px;
        display: inline-block;
        vertical-align: top;
        width: 21px;
        height: 21px;
    }
}


.ups-style label.autocomplete-default-label {
    margin: 0!important;
}

.ups-style .popover {
    display: block;
    width: 280px;
}

.ups-style .popover-body {
    color: $primary-body-color;
    font-size: 12px;

    p:first-child:last-child {
        margin: 0;
    }
}


th.file-format-col, td.file-format-col {
    width: 128px;
}

.KeyboardShortcutRow {
    //background-color: $ups-neutral-gray-1;
    padding: 10px 0;
}

.KeyboardShortcutRow_keyContainer {
    display: inline-block;
    min-width: 96px;
    margin-right: 8px;
}

.KeyboardShortcutRow_action {
    display: inline-block
}

.dt-buttons {
    display: inline-block;
    text-align: right;

    .btn-icon {
        margin: 0;
    }

    .btn-calendar {
        margin: 0;
    }
}

.alert-icon-container{
    padding-right: 10px;

}

.alert-icon-container svg, .placeholder-class-name svg {
    fill: #f78b23;
}

.trackingNumber {
    display: inline-block;
}

.invoice-summary-container {
    border: 1px solid #e8e8e8;
    overflow:hidden;
    margin-bottom: 1.25rem;
    padding: 1.25rem 1.25rem;
    background-color: #F7F6F5;
}

.invoice-summary-item {
    flex: 0 0 20%;
    max-width: 20%;
    flex-basis: 0;
    flex-grow: 1;

}


.invoice-summary-item-cta {
    flex: 0 0 20%;
    max-width: 20%;
    flex-basis: 0;
    flex-grow: 1;

    #btn-download-docs {
        min-width: 0;
    }
}

.max-w-100{
    max-width: 100%!important;
}

.cards {
    .card-6:not(:only-child) {
        display: inline-flex;
        width: calc(50% - .75rem);
    }
    .card-6:not(:last-child) {
        margin-right: 1.5rem;
    }
    .card {
        white-space: normal;
        box-shadow: 3px 3px 3px 3px #d3d3d3 !important;
    }
    .card-12 {
        display: flex;
        width: 100%;
    }

    .card-title {
        color: #00857d !important;
    }

    #card-table tfoot {
        padding: 8px 10px !important;
        border-top: 5px solid #e0e0e0 !important;
        border-bottom: 1px solid #e0e0e0 !important;
    }

    .card-body-08rem{
        font-size: 0.8rem;
    }
}

.stretch-card{
    display: flex;
    align-items: stretch;
}

.cards.stretch-card {
    .col-6.text {
        padding-bottom: 1em;
    }
}

.table-responsive  .table-responsive-footer-total {
    display: none;
}

.table-responsive table .pmtus-vertical-table {
    border: none;
    width: 100%;
}

.table-responsive table .pmtus-vertical-table-header {
    display: none;
}

.table-responsive table td .pmtus-vertical-table-content {
    border: none;
}

@media screen and (max-width: 1440px) {

    .ups-style .app-title {
        padding-left: 48px;
    }

    .ups-main ~ .ups-footer-wrapper .ups-footer {
        padding-left: 30px!important;
    }

    .cards.stretch-card {
        .col-md-12.col-lg-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }

    }
}

@media screen and (max-width: 1024px) {
    .ups-style .modal-dialog .modal-content {
        padding: 20px;
    }
    .modal .progress-display {
        margin-left: -20px;
        margin-right: -20px;
    }
    .ups-style .btn.btn-ups-mobile-only {
        display: inline-block!important;
    }

    .notification-modal .modal-title{
        margin-top: 0px;
    }
}

@media screen and (max-width: 991px) {
    .ups-footer .ups-footer_legal .ups-footer_links li {
        padding: 0;
        margin: 0;
        white-space: normal;
        line-height: 1;
        display: block;
    }

    .ups-footer .ups-footer_legal .ups-footer_links li:before {
        display: none;
    }

    .ups-footer .ups-footer_legal {
        float: none;
    }

    .dt-length {
        text-align: center;
    }

    .dt-pagination {
        text-align: center;
    }

    .invoice-summary-item {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .invoice-summary-item-cta {
        margin-top: 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .ups-footer .ups-footer_legal .ups-footer_links li a {
        line-height: 1;
        padding: 10px 0;
        display: block;
        font-size: 14px;
    }

    .ups-footer_disclaimer {
        font-size: 14px;
    }
}

@media screen and (max-width: 850px) {
    .cards.stretch-card {
        display: block;
    }

    .cards {
        .card-6:not(:only-child) {
            display: block;
            width: calc(100% - .5rem);
        }
    }

    .table-responsive .table-bordered-simple {
        border-bottom: none;
    }

    .table-responsive table thead {
        display: none;
    }

    .table-responsive table tr {
        border: 1px solid #e0e0e0;
        width: 100%;
        position: relative;
        display: inline-block;
        letter-spacing: normal;
        margin-top: 1.25rem;
        margin-bottom: 0;
        border-radius: 0;
    }

    .table-responsive table td {
        display: block;
        font-size: 0.750rem;
        padding: 0 !important;
        height: auto !important;
        text-align: left !important;
        white-space: normal !important;
        width: auto!important;
        border-left: none!important;
        border-right: none!important;
    }

    .table-responsive #card-table td:before {
        display: none;
    }

    .table-responsive  .table-responsive-footer-total {
        display: block;
        padding: 1em 0.5em;
        font-size: 1.2em;
    }

    .table-responsive table .pmtus-vertical-table-tr {
        display: table-row;
        border: none;
    }

    .table-responsive table td .pmtus-vertical-table-header {
        font-size: 0.750rem;
        padding: 1em 0.5em !important;
        width: 36% !important;
        letter-spacing: normal;
        word-spacing: normal;
        font-weight: 600;
        display: table-cell;
        border-right: 1px solid #e0e0e0!important;
        vertical-align: middle;
        background-color: #efefef;
    }

    .table-responsive table td .pmtus-vertical-table-content {
        display: table-cell;
        padding-left: 8px!important;
        padding-right: 8px!important;
    }

    .table-responsive table td.td-row-hidden {
        display: none;
    }
}


@media screen and (min-width: 600px) {
    .invoice-summary-item.py-2 {
        padding-right: 1.2rem;

    }
}

@media screen and (max-width: 599px) {
    .invoice-summary-item {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .invoice-summary-item-cta {
        flex: 0 0 100%;
        max-width: 100%;

        .btn {
            width: 100%;
        }
    }
}


.until-date-container {
    padding-left: 56px;
    width: 268px;
}

.frequency-before-liability-container {
    position: relative;
    top: -11px;
    padding-left: 0px;
    left: 56px;
    margin-top: 0!important;
    margin: 0!important;
    display: inline-block;
    input {
        width: 32px;
        text-align: right;
        padding: 3px!important;
        line-height: normal!important;
        margin-bottom: 0!important;
        line-height: normal!important
    }
    input.is-invalid {
        width: 42px;
        text-align: left;
    }
}

.frequency-before-liability-error {
    position: relative;
    margin: 0;
    top: 11px;
    left: 56px;
}

.days-before-text {
    position: relative;
    top: -11px;
    left: 62px;
    display:inline-block;
}

.weekly-before-liability-container {
    position:relative;
    top: -18px;
    padding-left: 0px;
    left: 56px;
    width: 168px;
    margin: 0!important;
    display: inline-block;

    select {
        //padding-top: 0!important;
        //padding-bottom: 0!important;
        margin: 0!important;

    }
}

.weekday-before-text {
    position: relative;
    top: -18px;
    left: 62px;
    display:inline-block;
}

.amount-due-limit-container {
    padding-left: 56px;
    width: 268px;
    margin-top: 0!important;
}

.amount-limit-note {
    font-size: 14px;
    padding-left: 56px;
    padding-right: 32px;
}

.btn-sm-inline-block {
    display: block;
}

@media screen and (max-width: 599px) {
    .weekday-before-text {
        top: -16px;
        left: 58px;
        display: block;
    }

    .modal .progress-display {
        margin-left: -15px;
        margin-right: -15px;
    }

    .alert {
        &.alert-past-due {
            padding: 0;
        }

    }

    .ups-style .ups-main.logged-in .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .ups-style .modal-dialog .modal-content {
        padding: 15px 15px!important;
    }

    .modal-footer .modal-button-col {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        padding: 0;
    }

    .ups-style .modal .modal-footer .btn {
        display: block!important;
        width: 100%!important;
        margin-left: 0!important;
        margin-right: 0!important;

    }

    .fixed-sn .ups-footer-wrapper, .ups-footer-wrapper {
        padding-bottom: 20px;
    }

    .ups-main ~ .ups-footer-wrapper .ups-footer {
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-bottom: 0;
    }

    .ups-footer .ups-footer_legal {
        padding-right: 0!important;
    }



    .sortable-list-item {
        text-align: center;
    }

    .sortable-list-right-container .sortable-list-item:before {
        display: block;
        text-align: center;
        margin: 0;
    }



    .ups-style .modal-title {
        font-size: 1.313rem;
    }

    h5.document-sub-heading {
        text-align: center;
        margin-bottom: 15px;
    }

    .dataTables_length:before {
        right: 8px;
    }

    table.dtr-column.collapsed .selectable-row td:first-child:before, table.dtr-column.collapsed td:first-child:before {
        top: 5px;
    }

    .calendar-settings-panel {
        padding: 15px 15px 15px 22px;
        width: 100%;
    }
}

@media (min-width: 576px) {
    .w-sm-auto {
        width: auto!important;
    }
}

@media (max-width: 576px) {
    .user-account-container {
        margin-top: 0px !important;
    }
}


/* ups landing start */
.ups-landing-container {
    position: relative;
    z-index: 8;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.ups-landing-logo {
    margin-right: 1.5rem;
}

.landing-quick-links {
    max-width: 598px;
    width: 100%;
    margin: 0 auto;
}
#ups-map {
    position: fixed;
    height: 100%;
    width: 80%;
    margin: 0 auto;
    paddng: 0;
    overflow: hidden;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.28;
    pointer-events: none;
}
#ups-map #ups-svg-countries g * {
    fill: #90867f;
}

#ups-map #ups-svg-countries g.ups-svg_disabled *, #ups-langSelect #ups-svg-countries g.ups-svg_disabled:hover * {
    //fill: #ebebeb;
    cursor: default;
    fill: #c7c2be;
}

.ups-style .ups-enroll-section {
    .ups-enroll-subsection {
        max-width: 100%;
    }

    .md-form.md-browser-default-form.browser-default-select {
        margin-top: 2.5rem;
    }

    .ups-subsection-left {
        position: relative;
        overflow: hidden;
        float: left;
        width: 55%;
    }

    .ups-subsection-right {
        position: relative;
        overflow: hidden;
        float: right;
        width: 42%;
        margin-right: 0px;
    }

    .md-form label, .md-form label.browser-default-label, legend,
    label.radioGroupLabel {
        font-weight: 600!important;
        font-size: 12px!important;
    }

    .md-form.md-browser-default-form label {
        top: 2px;
    }

    .browser-default.custom-select {
        background-color: transparent;
        border: 0!important;
        border-bottom: 1px solid $ups-neutral-gray-1;
        border-radius: 0!important;
        outline: 0!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
     }

    .md-form {
         label.active {
            -webkit-transform: translateY(-14px) scale(1);
            transform: translateY(-14px) scale(1);
        }

    }

    .md-form.datepicker-form {
        margin-bottom: 25px;
        input[type="text"] {
            margin-bottom: 0!important;
        }
    }

    .card {
        border: 1px solid #fff;
        padding: 1px;
    }

    .card.card-inactive {
        border: 1px solid $ups-neutral-gray-1;
        color: $ups-brown-mid;
        background-color: $btn-primary-color;
    }

    .card .card-header {
        color: $primary-body-color;
        font-size: 26px;
        padding: 20px 20px!important;
    }
    .ups-btn-section {
        padding-left: 20px!important;
    }

    .card.card-inactive .card-header {
        color: $ups-brown-mid;
        font-size: 24px;
        background-color: $btn-primary-color;
    }

    .card .card-body {
        padding: 10px 20px 25px 20px!important;
    }

    .disable-block {
        .browser-default-select:before,
        div[class^="MuiInputBase-root-"]:before {
            background-color: $ups-gray-inactive-background;
        }
    }

    .form-check {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .section-preview {
        padding: 10px 0px;
        margin-top: 0px;
        margin-bottom: 30px;
        .ups-field-label {
            font-weight: bold!important;
        }
        .card-header {
            background-color: $btn-primary-color;
            padding: 0px!important;
            h5 {
                font-size: 14px!important;
                color: $ups-teal;
                .ups-field-value {
                    font-weight: normal!important;
                }
            }
        }
        .ups-box-title {
            color: $ups-teal;
            font-size: 16px!important;
            font-weight: bold!important;
            padding-top: 10px;
            padding-bottom: 15px;
        }
        .ups-box-title1 {
            color: $ups-teal;
            font-size: 16px!important;
            font-weight: bold!important;
            padding-top: 0px!important;
            padding-bottom: 0px;
            margin-top: 0px!important;
        }
    }

    .section-preview.ups-desktop-mode {
        margin-top: 2px;
    }

    fieldset {
        margin-bottom: 20px;
    }

    .ups-plus-sign {
        -webkit-mask-image: url(../images/icon/ups/092-plus.svg);
        mask-image: url(../images/icon/ups/092-plus.svg);
    }

    .ups-minus-sign {
        -webkit-mask-image: url(../images/icon/ups/084-minus.svg);
        mask-image: url(../images/icon/ups/084-minus.svg);
    }

    .ups-plus-sign, .ups-minus-sign {
        background-color: $ups-teal-dark;
        mask-size: 15px 15px;
        height: 15px;
        width: 15px;
        display: block;
        position: absolute;
        right: 18px;
        top: 7px;
        pointer-events: none;
    }

    .component-status-body {
        margin-top: 15px;
    }

    .component-status-footer {
        margin-left: 20px;
    }

    .view-more {
        font-size: 14px;
        font-weight: 600 !important;
        color: $ups-teal;
    }

    .btn-label-link.tooltipQuestionMark svg {
        top: -5px;
    }
    .popover-body {
        padding: .5rem;
    }
}


    input:not([type]), input[type="text"]:not(.browser-default), input[type="password"]:not(.browser-default),
    input[type="email"]:not(.browser-default), input[type="url"]:not(.browser-default),
    input[type="time"]:not(.browser-default), input[type="date"]:not(.browser-default),
    input[type="datetime"]:not(.browser-default), input[type="datetime-local"]:not(.browser-default),
    input[type="tel"]:not(.browser-default), input[type="number"]:not(.browser-default),
    input[type="search"]:not(.browser-default), input[type="phone"]:not(.browser-default),
    input[type="search-md"], textarea.md-textarea {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        background-color: transparent;
        border: 0!important;
        border-bottom: 1px solid $ups-neutral-gray-1;
        border-radius: 0!important;
        outline: 0!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        margin-bottom: 25px;
    }

    input:not([type]):focus:not([readonly]) + label, 
    input[type="text"]:not(.browser-default):focus:not([readonly]) + label, input[type="password"]:not(.browser-default):focus:not([readonly]) + label, input[type="email"]:not(.browser-default):focus:not([readonly]) + label, input[type="url"]:not(.browser-default):focus:not([readonly]) + label, input[type="time"]:not(.browser-default):focus:not([readonly]) + label, input[type="date"]:not(.browser-default):focus:not([readonly]) + label, input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label, input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label, input[type="tel"]:not(.browser-default):focus:not([readonly]) + label, input[type="number"]:not(.browser-default):focus:not([readonly]) + label, input[type="search"]:not(.browser-default):focus:not([readonly]) + label, input[type="search-md"]:focus:not([readonly]) + label, textarea.md-textarea:focus:not([readonly]) + label {
        color:  $ups-sienna;
    }

    
    .browser-default.custom-select,
    input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]),
    input[type=tel]:not(.browser-default){
         font-weight: 500!important;
         font-size: 1rem!important;
        //  margin-bottom: 25px;
     }
     input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"])::placeholder,
     input[type=tel]:not(.browser-default)::placeholder {
         color: $ups-gray-inactive-text;
     }

     .md-form input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]):disabled,
     .md-form input[type=tel]:not(.browser-default):disabled,
     .browser-default.custom-select:disabled,
     .md-form input[type=text][class^="MuiInputBase-input"]:disabled {
         border: 1px solid $ups-gray-inactive-background;
         color: $ups-brown-mid;
     }


    .md-form input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]):disabled,
    .md-form input[type=tel]:not(.browser-default):disabled,
    .browser-default.custom-select.pbt:disabled,
    .md-form input[type=text][class^="MuiInputBase-input"]:disabled {
        border: 1px solid $ups-gray-inactive-background;
        color: black !important;
        background-color: white !important;
    }

    .md-form label.disabled,
    .md-form label.browser-default-label.disabled,
    fieldset:disabled legend,
    .form-check-input:disabled~.form-check-label,
    .form-check-input[disabled]~.form-check-label,
    .disable-block .md-form label,
    .disable-block .md-form label.browser-default-label,
    .disable-block legend {
        color: $ups-brown-mid;
    }


.ups-style .progress-display {

    background-color: $ups-brown-light-10;
    .row.progress-display-bar {
        margin-top: 1.5rem!important;
    }
    .row.progress-display-title {
        margin-top: 0.5rem!important;
        margin-bottom: 1.5rem!important;
    }

    .row.progress-display-bar {
        margin-left: 10px;
        margin-right: 10px;
    }
    .row.progress-display-title {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.ups-style .progress-display-bar {
    background: transparent url('../images/svg/base_bar.svg');
    height: 5px;
    border-radius: 2px;
}

.ups-style .progress-display-bar-past {
    background: transparent url('../images/svg/past_bar.svg');
    height: 5px;
    border-radius: 2px;
}

.ups-style .progress-display-bar-current {
    background: transparent url('../images/svg/current_bar.svg');
    height: 5px;
    border-radius: 2px;
}

.ups-style .progress-display-title {
    color: $ups-gray-inactive-text;
    text-align: center;
}

.ups-style .progress-display-title-past {
    color: $ups-stroke;
}

.ups-style .progress-display-title-current {
    color: $ups-sienna-75;
}

.ups-style .ups-note-1 {
    font-size: 12px!important;
    margin-top: 25px;
    margin-bottom: 25px;
}

.ups-style .ups-control-id-note-1 {
    font-size: 12px!important;
    margin-top: -20px;
    margin-bottom: 25px;
}

.ups-style .ups-payment-account-history-multipay {
    font-size: 14px!important;
    margin-top: -20px;
}

.ups-style .ups-payment-account-history-multipay-mobile {
    font-size: 10px!important;
}

// Will edit everything selected including everything between a range of dates
.ups-style .CalendarDay__selected_span {
    background: $ups-teal-light;
    color: #242424!important;
    border: 1px solid $ups-teal-light;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: $ups-teal-light;
    color: #242424!important;
    border: 1px solid $ups-teal-light;
}
.CalendarDay__hovered_span:active {
    background: red!important;
    border: 1px double #80e8e0;
    color: #007a87
}

// Will edit selected date or the endpoints of a range of dates
.ups-style .CalendarDay__selected {
    background: $ups-teal;
    color: white!important;
}

// Will edit when hovered over. _span style also has this property
.ups-style .CalendarDay__selected:hover {
    background: $ups-teal-dark;
    opacity: 1!important;
    color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.ups-style .CalendarDay__hovered_span:hover,
.ups-style .CalendarDay__hovered_span {
    background: $ups-teal-light!important;
}

.CalendarDay__default:hover {
    background: $ups-teal-dark;
    color: white!important;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff !important;
    border: 1px solid #e4e7e7 !important;
    color: #cacccd !important;
}

@media screen and (max-width: 960px) {
    .ups-header_title {
        font-size: 25px;
    }

    .ups-style .ups-enroll-section {
        .card .card-header {
            padding: 10px 4px 10px 4px!important;
        }

        .ups-btn-section {
            padding-left: 0px!important;
        }

        .ups-enroll-subsection {
            max-width: 100%;
        }
        .ups-subsection-left {
            width: 100%;
        }

        .non-modal {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .browser-default.custom-select,
    input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"]),
    input[type=tel]:not(.browser-default) {
        font-size: 14px!important;
        margin-bottom: 20px;
    }
    input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"])::placeholder,
    input[type=tel]:not(.browser-default)::placeholder {
        font-size: 14px!important;
    }

    body.ups-style {
        font-size: 14px!important;
    }

    .progress-display-title {
        .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    .ups-style .ups-enroll-subsection {
        .card .card-header {
            padding: 10px 0px!important;
            font-size: 22px;
        }
        .card .card-body {
            padding: 0px!important;
        }
    }

    .ups-style .ups-enroll-section .section-preview {
        padding: 10px 5px 2px 1px!important;
        margin-top: 0px!important;
        margin-bottom: 30px!important;
        .card-header {
            padding: 5px 5px 3px 0px!important;
            h5 {
                font-size: 14px!important;
            }
            .ups-box-title, .ups-box-title1 {
                margin-top: 0rem!important;
                font-size: 14px !important;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
        .ups-field-label, .ups-field-value, .view-more {
            margin-top: 1px!important;
            font-size: 12px !important;
        }
        .ups-plus-sign, .ups-minus-sign {
            top: 5px;
            right: 8px;
        }
    }

    .ups-style .progress-display {
        .row.progress-display-bar {
            margin-left: 5px;
            margin-right: 5px;
        }
        .row.progress-display-title {
            margin-left: 1px;
            margin-right: 1px;
        }
    }

}
    
@media screen and (max-width: 640px) {
    .ups-style .ups-enroll-section {
        margin-left: 2px;
        margin-right: 0px;

        .popover-body {
            font-size: 12px;
        }

        .btn {
            padding: 10px 15px;
        }
    }
    .progress-display-title {
        font-size: 12px;
    }
}

@media screen and (max-width: 320px) {
    .ups-style .ups-enroll-section {        
        input[type=text]:not(.browser-default):not([class*="MuiInputBase-input"])::placeholder,
        input[type=tel]:not(.browser-default)::placeholder {
            font-size: 11px!important;
        }
    }
}

.account-summary-item {}

a.quick-link:link, a.quick-link:visited {
    color: $ups-teal;
    font-size: 1rem;
}

.dashboard-container {

    .card.summary-widget-card {
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)!important;
        margin-bottom: 2rem;
        padding: 1rem;

        .card-body {
            padding: 0;
        }
    }
    .card.card-widget {
        background-color: $ups-brown-light-10;

        .card-title {
            border: none;
            box-shadow: none;
            padding: 1rem;
            margin: 0;
            font-size: 1.125rem;
        }

        .card-body {
            padding: 0 1rem 1.5rem 1rem;
        }
    }
}

.demo-control {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: 960px!important;
}

.w-33 {
    width: 33.333333%;
}

.modal h5 {
    font-size: 24px;
}

.modal h6 {
    font-size: 20px;
}

// table column settings start
// we may not need this later when we receive the code snippets from UPS
.table-column-container {
    border: 1px solid #767577;
    width: 100%;
    height: 268px;
    overflow-y: scroll;
    padding: 8px 15px;
}

.table-column-container .form-check {
   padding: 0;
}

.column-item {
    position: relative;
    overflow: hidden;
    background-color: #d7d6d7;
    color: #272325;
    padding: 0px 12px 0px 12px;
    height: 32px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 8px;
}

.column-item-remove {
    float: left;
    margin-right: 8px;


}

.column-item-remove svg {
    width: 12px;
    height: 12px;
}

.column-item-label {
    float: left;
    line-height:30px;
}

.column-item-order {
    float: right;
    position: relative;
    overflow: hidden;
}

.column-item-down {
    float: left;
    margin-right: 7px;
}

.column-item-up {
    float: left;
    marign-left: 7px;
}


.column-item-down svg {
    width: 12px;
    height: 12px;
    fill: #446da9;
}


.column-item-up svg {
    width: 12px;
    height: 12px;
    fill: #446da9;
}

.column-item:first-child .column-item-up svg{
    fill: #c0bec0;
}

.column-item:last-child .column-item-down svg{
    fill: #c0bec0;
}
// table column settings end


.form-check-input[type="checkbox"]:disabled:checked+label:before, label.btn input[type="checkbox"]:disabled:checked+label:before {
    background-color: #eeedef;
}



.form-check-input[type="checkbox"]:disabled:checked+label:after, label.btn input[type="checkbox"]:disabled:checked+label:after {
    background: transparent;
    border-color: #767577!important;
}

.ups-style .table-column-container .form-check .form-check-input[type="checkbox"]:checked + label:after {
    width: 6px;
}

.table-card {
    td{
        display: block;
        border: none;
        text-align: left;
        width: 100%!important;
        background-color: #fff;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 106px;
        font-size:14px;
    }
    .datatable-selection-col{
        width: 100%!important;
        .form-check {
            height: 100%;
            display: block;

            .form-check-label {
                height: 100%;
                & > * {
                    position: relative;
                    line-height: normal;
                    font-size: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .form-check-input[type="radio"] + .form-check-label {
                &::before {
                    top: 50%;
                    transform: translateY(-65%);
                }
                transition: none;
                width: 100%;
            }

            .form-check-input[type="checkbox"] + .form-check-label {
                &::before {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .form-check-input[type="radio"] + .form-check-label::after {
                top: 50%;
                transition:none;
                transform: translateY(-50%) rotate(45deg) scale(0);
            }

            .form-check-input[type="radio"]:checked + .form-check-label::after {
                transform: translateY(-50%) rotate(45deg);
            }

            .form-check-input[type="checkbox"]:checked + .form-check-label::after {
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

}

.table-card thead {
    display: none;
}

.table-card tbody {
    background-color: $ups-neutral-gray-2;
    padding: 1% 0;
}

.table-card tbody tr {
    float: left;
    width: 18%;
    margin: 1%;
    //border: 1px solid $ups-neutral-gray-1;
    border-radius: .25rem;
    text-align: center;
    box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.1);
}

.table-card.table-card-plan tbody tr {
    float: left;
    width: 31.3333%;
    margin: 1%;
    //border: 1px solid $ups-neutral-gray-1;
    border-radius: .25rem;
    text-align: center;
}

table.dataTable.table-card {
    border: none!important;
}

.table-card td.datatable-selection-col .form-check {
    padding-left: 0;
}

.ups-style .table-card td .form-check-input[type="checkbox"] + label {
    padding: 0;
    top: auto;
    left: auto;
    display: block;
    width: 100%;
    padding-left: 32px;

    &.plan-label {
        display:inline-block;
        width: auto;
        padding-right: 18px;
        &.verbose {
            white-space: pre-wrap;
            line-height: normal!important;
            height: auto;
        }
    }
}

.ups-style .table-card td .form-check.plan-container {

    &.top-plan-level {
        display:inline-block;
        label.plan-label {

            font-weight: bold!important;
            font-size: 0.875rem;

            &.verbose {
                line-height: 30px!important;
            }

        }

        &:before {

        }
    }
}

.ups-style .table-card td .plan-container + .btn-popover-link {
     float: right;
     top: 3px;
}
.ups-style .table-card td .plan-container.verbose + .btn-popover-link {
    top: 6px;
}




.custom-datepicker.hide-months {
    .DayPicker {
        width: 268px!important;
    }

    .DayPicker_wrapper__horizontal {
        position: absolute;
        left: -9999px;
        pointer-events: none;
    }
}




/** react select customizations start */

.react-select-form-group {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.react-select-label {
    position: relative;
    z-index: 1;
    top: 5px;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    color: $primary-body-color;
}

.react-select-container {
    display: block;
    width: 100%;

    .react-select__control {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        font-size: 1rem;
        box-shadow: none;

        &:hover {
            border-color: #ced4da;
        }

        &.react-select__control--menu-is-open {

        }

        &.react-select__control--is-focused {
            border-color: $ups-sienna;
            &:focus {
                outline: none
            }
        }
    }

    .react-select__value-container {
        position: relative;
        top: 2px;
        padding-left: 0;
        font-size: 1rem;
        display: flex;
        flex: none;
        width: calc(100% - 45px);
        flex-wrap: nowrap;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.react-select__value-container--is-multi .react-select__placeholder{
            align-self: center;
        }
    }

    .selected-options--container {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        flex: 1 0 0;
        display:flex;
    }

    .react-select__placeholder {

    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__menu {
        z-index: 999;
    }

    .react-select__menu-list {
        padding-top: 0;

        &.react-select__menu-list--is-multi {
            .react-select__option {
                &.react-select__option--is-selected {
                    background-color: #fff!important;
                    color: $primary-body-color;

                    &:after {
                        box-shadow: none;
                        content: " ";
                        position: absolute;
                        left: 22px;
                        top: 13px;
                        width: 6px;
                        height: 12px;
                        border-right: 3px solid #00857D !important;
                        border-bottom: 3px solid #00857D !important;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        padding: 0;
                        transition: none;
                        border-radius: 0;
                    }
                }

                &:before {
                    position: relative;
                    top: 0px;
                    content: '';
                    background: #fff;
                    border: 1px solid #757575 !important;
                    display: inline-block;
                    vertical-align: middle;
                    width: 24px;
                    height: 24px;
                    padding: 2px;
                    text-align: center;
                    border-radius: 5px;
                    margin-right: 10px;
                }
            }

        }
    }

    .react-select__multi-value {
        background-color: #fff;
        margin: 0;
        display: flex;
        align-self: center;
        min-width:unset;

        .react-select__multi-value__label {
            padding-left: 0;
            font-size: 1rem;
            &:after {
                content: ",";
                display: inline-block;
                margin-right: 3px;
            }
        }
        .react-select__placeholder {
            display: flex;
        }

        &:last-of-type {
            .react-select__multi-value__label {
                &:after {
                    display: none;
                }
            }
        }
    }

    .react-select__clear-indicator {
        display: none;
    }

    .react-select__indicator {
        //display: none;
        svg {
            fill: #016862;
            height: 24px;
            width: 24px;
        }
    }



    .react-select__option {
        position: relative;
        font-size: 0.875rem;
        cursor: pointer;

        &.react-select__option--is-instruction {
            color: $primary-body-color;
            pointer-events: none;
            &:before, &:after {
                display: none!important
            }

            &.react-select__option--is-disabled {
                color: $primary-body-color;
            }
        }

        &.react-select__option--is-focused {
            background-color: $ups-neutral-gray-2;

            &.react-select__option--is-selected {
                background-color: #eee;
                border: none;
            }
        }

        &.react-select__option--is-selected {
            background-color:#fff;
            color: black;
        }
    }

    .react-select__option__label {
        vertical-align: middle;
    }

    .react-select__group-heading {
        text-transform: initial;
        color: $primary-body-color;
        font-size: 0.875rem;
        padding: 3px 12px;
    }
}

.invalid-status {
    display: block;
    color: $ups-red-error;
    width: 100%;
    font-size: 80%;
    margin-top: -20px;
    margin-bottom: 15px;
}

.field-info {
    display: block;
    font-weight: bold;
    width: 100%;
    font-size: 80%;
    margin-top: -20px;
    margin-bottom: 15px;
}

.custom-select+.invalid-status {
    margin-top: -4px;
}

.invalid-status.with-date-picker {
    margin-top: 3px;
}

.warning-status {
    color: $ups-plum;
}

.invalid-status.component-level,
.warning-status.component-level {
    font-size: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
}

.valid-status {
    display: none;
}

// This may need adjustment later to remove the margins
.invalid-status[aria-hidden="false"] {
    margin-bottom: 15px;
}

.invalid-status.with-date-picker {
    margin-top: 3px;
}

.display-none {
    display: none !important;
}

.ups-style {
    .popover {
        &.manage-user-plan-popover {
            width: 100%;
            max-width: 380px;
            .popover-body {
                font-size: 0.875rem;
            }
        }
    }
}



.manager-user-plan-account-list {
    margin: 0;
    list-style-type: none;
    padding-left: 0;

    li {
        display: inline-block;
        padding: 0.5rem;
        min-width: 80px;
        margin: 0.25rem;
        border: 1px solid #efefef;
        border-radius: .25rem;
        text-align: center;
    }
}

.plan-summary-item {
    margin-right: 5px;
    font-size: 1rem;
}

.plan-summary-item:before {
    content: ' ';
    border-left: 1px solid #ccc;
    width: 1px;
    height: 8px;

    margin-left: 3px;
    margin-right: 3px;
}

.ups-icon-x:before {
    content: 'X' !important;
    font-weight: bold;
}

.plan-summary-item:first-child:before {
    display: none!important;
}

.ups-style .form-check-input[type="checkbox"] + label.verbose:before {
    top: 2px;
}

.side-nav-icon-payment-history:before {
    content: '\e8b0';
}

.side-nav-icon-plan:before {
    content: '\e617';
}

#selectAll0-label {
    z-index: 1;
}

table.table-two-col {
    th {
        font-weight: 700;
        border-color: $ups-neutral-gray-1;
        padding: 0.5rem;
        width: 50%;
    }

    td {
        padding: 0.5rem;
        word-break: break-word;
        text-align: right;
    }

    tr.total-p-line {
        border-top: 3px solid $ups-neutral-gray-1;
        td {
            font-weight: 700;
        }
    }
}

#supportIframe{
    margin-bottom: -20px;
    margin-top: -12px;
}

.payment-detail-header {
    position: absolute;
    top: 13px;	
}

.recent-payments-container {
    flex-grow: 1;
}

.ups-style .download-documents-modal {
    th:last-child {
        text-align: center;
    }
}

.ups-style .invoice-tab-content {
    padding: 1rem;
    border: 1px #dee2e6 solid;
    border-top: 0;

    .row:first-child {
        [class^="col"] {
            .md-form:first-child {
                margin-top: 1.5rem!important;
            }
        }
        .select-wrapper {
            &.md-form {
                margin-top: 0!important;
            }
        }
    }

}

@media screen and (max-width: 800px) {
    .table-card tbody tr,
    .table-card.table-card-plan tbody tr {
        //width: 31.333333%;
        width: 46%;
        margin: 2%;
    }

    label[for="expirationDate"] {
        white-space: nowrap;
    }
}

@media screen and (max-width: 667px) {
    .table-card tbody tr,
    .table-card.table-card-plan tbody tr {
        //width: 48%;
        width: 94%;
        margin: 2% 3%;
        &:first-child {
            margin-top: 3%;
        }
    }
}

@media screen and (max-width: 599px) {
    .table-card.table-card-wallet tbody tr td {
        height: auto;
    }
}

@media screen and (max-width: 414px) {
    .table-card tbody tr,
    .table-card.table-card-plan tbody tr {
        width: 95%;
        margin: 3%;
    }
}

@media screen and (max-width: 320px) {
    .table-card tbody tr,
    .table-card.table-card-plan tbody tr {
        width: 92%;
        margin: 3% 4%;
    }
}

.ups-style .nav-tabs-container {
    //border-bottom: 1px solid #dee2e6;

    .nav-tabs {
        border: none;
    }

    .tabs-primary {
        background-color: #fff !important;
    }
    .nav-item {
        //color: #fff;
        >.active {
            color: $ups-teal;
        }
        .nav-link {
            font-size: 1.25rem;
            padding: .5rem 2.5rem;
        }
    }
}

@media screen and (max-width: 991px) {

    .ups-style .nav-tabs {
        white-space: nowrap;
        border-bottom: none;
        margin-bottom: -1px;

        .tabs-primary {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            overflow-x: auto;
            //overflow-y: hidden;

        }

        .nav-item {
            display: inline-block;
            //white-space: nowrap;
            > .active {
                color: $ups-teal;
            }

            .nav-link {
                font-size: 1rem;
                padding: .5rem 1.875rem
            }
        }
    }
}

@media screen and (max-width: 575px) {

    .ups-style .nav-tabs {
        margin-bottom: 0;

        .nav-item {
            margin-bottom: 0;
            > .active {
                color: $ups-teal;
            }

            .nav-link {
                font-size: 0.813rem;
                padding: 0.5rem 0.35rem;
                border-bottom: 1px #dee2e6 solid;
                &.active {
                    border-bottom-color: #fff;
                }
            }
        }
    }

    .ups-style .invoice-tab-content.border-top {
        border-top: none!important;
    }

    .ups-style .invoice-tab-content {
        padding: 0.8rem;
    }

    .ups-style .download-documents-modal {
        padding-left: 10px;
        padding-right: 10px;

        table.dataTable thead .sorting:after, 
        table.dataTable thead .sorting_asc:after, 
        table.dataTable thead .sorting_asc_disabled:after, 
        table.dataTable thead .sorting_desc:after, 
        table.dataTable thead .sorting_desc_disabled:after {
            display: none;
        }

        h5 {
            font-size: 18px;
            text-align: left;
            padding-left: 15px;
        }

        th {
            font-size: 12px !important;
        }

        td, .btn-text-link {
            font-size: 13px !important;
            padding-left: 1px !important;
            padding-right: 2px !important;
        }

        th, td {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
    }
}

@media screen and (max-width: 320px) {

    .ups-style .nav-tabs {

        .nav-item {
           
            .nav-link {
                font-size: 0.75rem;
                padding: 0.5rem 0.3rem;
            }
        }
    }

    .ups-style .invoice-tab-content.border-top {
        border-top: none!important;
    }

    .ups-style .download-documents-modal {
        th {
            font-size: 11px !important;
        }
        td, .btn-text-link {
            font-size: 12px !important;
        }

        table.dataTable thead .sorting, table.dataTable thead .sorting_asc, 
        table.dataTable thead .sorting_desc {
            padding-right: 15px !important;
        }

    }
}

// .ups-style #invoice-summary-table-datatable .custom-row {
//     padding: .75rem !important;
//     background-color: #fafafa !important;
// }

.ups-style table.table-invoice-summary tbody tr td, tr.total-p-line td {
    word-break: inherit;
}

.ups-style table.table-invoice-summary thead th {
    background-color: #e8e8e8;
}

.ups-style table.table-invoice-summary [scope="colgroup"] {
    background-color: #f7f6f5;
}


.position-top-right {
    position: absolute !important;
    top: 9px;
    right: 16px;
}

.invoice-detail-dispute-btn{
    position: absolute !important;
    top: 14px;
    left: 275px;
}

td.custom-row {
    .row {
        margin-left: 0px;
        margin-right: 0px;
        .card {
            white-space: normal;
        }
        .card:not(:only-child) {
            display: flex;
            width: 100%
        }
        .card-12 {
            display: flex;
            width: 100%;
        }
    }
}


@media screen and (min-width: 1440px) {
    td.custom-row {
        .row {
            .card-6:not(:only-child) {
                display: flex;
                width: calc(50% - .75rem);
            }
            .card-6:not(:last-child) {
                margin-right: 1.5rem;
            }
        }
    }
}

.custom-table-heading {
    display: none;
}

@media screen and (max-width: 599px) {
    td.custom-row {
        .card-title {
            font-size: 1.313rem;
        }

        .btn {
            &.position-top-right {
                position:relative!important;
                top: auto!important;
                right: auto!important;
            }
        }

        .custom-table-cell {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        table {
            margin: 1rem auto 0 auto!important;
            border: none;
            width: 100%;


            thead {
                display: none;
            }

            tr {
                border: 1px solid #e0e0e0;
                width: 100%;
                position: relative;
                display: inline-block;
                letter-spacing: normal;
                margin-bottom: 1.25rem;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border-radius: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            td {
                display: block;
                font-size: 0.750rem;
                padding: 0 !important;
                height: auto !important;
                text-align: left !important;
                white-space: normal !important;
                width: auto!important;
                border-left: none!important;
                border-right: none!important;
                &:first-child {
                    border-top: none!important;
                }

            }

            .custom-table-heading {
                font-size: 0.750rem;
                padding: 1em 0.5em !important;
                width: 50%;
                background: #f8f8f8;
                letter-spacing: normal;
                word-spacing: normal;
                font-weight: 600;
                display: inline-block;
                margin-right: 8px;
                border-right: 1px solid #e0e0e0!important;
                vertical-align: middle;
                line-height: normal;
            }

            .custom-table-value {
                width: 50%;
                line-height: normal;
            }

        }
    }

}

@media screen and (max-width: 575px) {
    td.custom-row {
        .card-title {
            + .btn {
                width: calc(100% - 30px);
                margin: 0 auto!important;
            }
        }

        table {
            margin: 1rem auto 0 auto !important;
            border: none;
            width: calc(100% - 30px);
        }

    }
}





#paymentReceiptPrintBounds{
    .print-only-content{
        display:none;
    }
    @media print {
        padding:30px;
        .print-only-content{
            display:block;
        }
    }
}

#paymentReceiptPrintBounds .processing-msg {
    color: red!important;
}

.dispute-msg-error {
    color: $ups-red-error;
}

.pending-dispute-status-icon {
    height: 21px;
    width: 21px;
    fill: #f78b23;
    vertical-align: top;
}

.alert-dispute-submitted, .alert-dispute-error {
    color: $ups-plum;
    font-weight: 700;
    font-size: 14px;
}

.datatablev1-hidden-column{
    display:none;
}

.text-teal {
    color: $ups-teal;
}

.text-blue {
    color: $ups-blue;
}

.overflow-visible {
    overflow: visible !important;
}

.header-dropdown-navbar  {

    .dropdown-menu {
        min-width: 280px;
        padding: 15px 15px;
    }
    .nav-link {
        padding: 0!important;

        text-align: center;
        line-height: 28px;
        height: 28px;
        background: rgba(0,0,0,0.05);
        color: $primary-body-color;
        font-size: 14px;
        border-radius: 4px;

        .fa, .material-icons, .fad, .fas {
            width: 28px;
            line-height: 28px;
            height: 28px;
            border-radius: 4px;
            font-size: 14px;
        }
    }

    .btn {
        &.btn-primary, &.btn-secondary {
            width: 100%;
            margin: 0;
        }
    }

    .dropdown-divider {
        margin: 15px 0;
    }
    &:empty {
        display: none;
    }
}

.not-payable-msg{
    font-size: 11px;
    font-size: .688rem;
    font-weight: 700;
}

.cancel-account-modal-scrollable{
    max-height: 300px;
    overflow-y: auto;
    #account-table-cancel-list-datatable{
        margin-bottom:0px!important;
    }
}

.md-form textarea.md-textarea.form-control.cancelReasonComments{
    height:60px;
}


.cancel-account-modal-scrollable{
    max-height: 300px;
    overflow-y: auto;
    #account-table-cancel-list-datatable{
        margin-bottom:0px!important;
    }
}

.md-form textarea.md-textarea.form-control.cancelReasonComments{
    height:60px;
}

.nav-div{
    width: 100%;
    display: flex;
}

.user-account-container {
    margin-top: 18px;
    
    .dropdown.user-account-dropdown {
        &.show {
            .fa.fa-angle-down {
                transform: rotate(-180deg);
            }
        }
    }

    a.nav-link {
        &.user-account-profile {
            span.user-account-name {
                float: left;
                min-width: 96px;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                max-width: 268px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .fa, .fad, .fas {
                text-align: center;
                background-color: rgba(0, 0, 0, 0.1);
                color: $primary-body-color;
            }
        }
    }

    .account-user-name {
        font-weight: 700;
    }

    .account-user-role {
        color: #383838;
        font-size: 14px;
    }

    .account-user-company {
        color: #383838;
        font-size: 14px;
    }

    .impersonation-user-name {
        color: #383838;
        font-size: 14px;
    }

    .impersonation-user-role {
        color: #383838;
        font-size: 14px;
    }

    .account-user-last-login {
        font-size: 0.75rem;
    }
}

#disputes-modal {
    .col-md-6, .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 768px) {
    .ups-style .preferences {
        .col-md-6:nth-child(odd) {
            padding-right: 0px;
            margin-right: 0px;
        }

        .col-md-6:nth-child(even) {
            padding-right: 0px;
            margin-left: 0px;
        }

        .mb-4 {
            padding-right: 0px;
        }
    }
}

@media screen and (max-width: 992px) {
    .ups-style .preferences{
        .col-md-6 {
            margin-bottom: 20px;
        }

        .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .col-4.pref-phone-number {
            max-width: 50%;
        }
    }
    #disputes-modal {
        .col-md-6, .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    #pmDetailsSection .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .user-account-container a.nav-link.user-account-profile span.user-account-name {
        max-width: 198px;
    }

    .ups-style .preferences{
        .mt-2, .mb-4 {
            padding-right: 0px;
        }

        .col-md-6 {
            margin-bottom: 20px;
        }

        .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .col-4.pref-phone-number {
            max-width: 100%;
        }
    }

    .card .position-top-right {
        position: relative !important;
        top: 0px;
        right: 0px;
        width: calc(100% - 80px);
        margin: 0 auto !important;
    }
    #pmDetailsSection .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 599px) {
    .user-account-container a.nav-link.user-account-profile span.user-account-name {
        max-width: 138px;
    }
}

@media screen and (max-width: 320px) {
    .user-account-container a.nav-link.user-account-profile span.user-account-name {
        max-width: 108px;
    }
}

.icon-circle-wrapper {
    position: relative;
    overflow:hidden;
    background-color: #fff;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    text-align: center;

    &.grey {
        background-color: #efefef!important;
    }

    .fa, .fas, .far, .fad {
        vertical-align: middle;
        line-height: 48px;
    }
}

#btn-download-docs{
    min-width: 20%;
}

.alert-error {
    font-weight: 700;
    font-size: 14px;

    .svg-icon {
        fill: $ups-plum;
        min-width: 32px;
    }
}

.datatable-card-description {
    white-space:pre-wrap;
    overflow-wrap:break-word;
}

.font-size-body {
    font-size: 1rem!important;
}

.fa-minus.prefix{
    font-size:7px;
    line-height:30px;
    color:black!important;
}

#paymentAmount.negative{
    padding-left:10px;
    margin-left:0px;
    &+ label{
        margin-left:0px;
    }
}

#sepaMandatePrint{
    @media print {
        padding:30px;
    }
}

div.dataTables_wrapper table.table-invoicesDownload tbody td{
    white-space: break-spaces;
}

#clearFilterLink{
    font-weight:bold;
    color:#00857D;
}

.datatable-v3 {
  .dt-buttons {
    .dt-button {
      margin: 0 0.156rem!important;
    }
  }
  .dtr-rowExpander{
    cursor:pointer;
  }
  .dtr-chevron{
    color:#016862;
    transition: transform 250ms;
    &.down{
      transform: rotate(90deg) !important
    }
  }
  &.search-right .dt-search-col{
    flex: 0 0 100%;
    max-width: 100%;
    .dataTables_filter{
        float:right;
    }
  }

  .datatable-selection-col {
    padding:0.6rem;
  }

  &.datatable-v3-filterWrapper{
      .table-card-mobile thead{
          width:100%;
      }
      .datatable-card{
          display:none;
      }
  }
  &.card-wrapper{
    #datatable-filter-status{
        display: none!important;
    }
    .manage-custom-buttons{
        margin-right:0.25rem;
        .btn-icon {
            margin-right: 0.25rem;
        }
        .dropdown.datatable-custom-dropdown{
            display:inline;
        }
        .datatable-custom-dropdown .Ripple-parent{
            overflow:visible;
        }
    }

    @media screen and (max-width: 767px) {
        .manage-custom-buttons {
            margin-right: 0px;
            margin-bottom: 0.5rem;
        }
    }
  }
  .card-row .dataTables_empty{
      word-wrap:break-word;
      white-space:normal;
      vertical-align:middle;
  }

  @media screen and (max-width: 767px) {
      &.datatable-v3-filterWrapper{
          .responsive-table.table-card-mobile{
              .dtr-rowExpander{
                  display:none!important;
              }
          }
          .datatable-card{
              display:table!important;
              padding-top: 10px;
              line-height: normal;
              white-space: pre-wrap;
              font-size: 0.875rem;
              display: table !important;
              width: 100% !important;
              border: none !important;
          }
          .table-card-mobile thead{
              th{
                  display:none;
              }
              .datatable-selection-col{
                  display:inherit;
              }
          }
          .table-card-mobile.selectAll thead{
              display:inherit!important;
          }
      }
  }
}

.printView{
    display:none;
}

.printDisplay .printView{
    display:block;
    margin-top:5mm;
    h2{
        margin-bottom:5mm;
    }
    .datatable-v3{
        th{
            color:black!important;
            background-color:white!important;
        }
    }
    .payment-status-activity-link{
        display:none;
    }
}

#invoice-details-pay-button{
    min-width:150px;
}

.dummy-css {
    display:none;
}

.modal-button-col{
    position:static;
    button{
        position:static;
    }
}

.multi-select-dropdown-filter{
    transition:none;
    ul{
        transition:none;
        transform:none;
    }
}

table.dataTable.table-bordered-rows{
    border-collapse: collapse;
}

.screenreader-text{
    color:transparent;
    font-size:0px;
}

.collapsible-header + .collapsible-children{
    transition: max-height 0.35s;
    overflow:hidden;
}

.required-field::after {
    content: "*";
}

.table-custom-responsive th, 
.hyphenate {
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@media (forced-colors: active) { // High Contrast Mode    
    .ups-style {
        .form-check-input[type="radio"]:checked + label:after {
            background: transparent url('../images/svg/white_filled_circle.svg');
        }
    
        .progress-display-bar-current {
            background: transparent url('../images/svg/current_bar_hc.svg');
        }

        .progress-display-title-current {
            border: 1px solid transparent;
        }
    
        .modal-dialog .modal-content {
            border: 1px solid #FFFFFF;
        }

        .form-check-input.form-radio-group-check:checked + label {
            font-weight: bold!important;
            border-bottom: 4px solid;
        }

        .md-form.md-browser-default-form:before,
        .browser-default-select:before {
            background: transparent url('../images/icon/ups/chevrondown-hc.svg');
            background-repeat: no-repeat;
            background-size: 15px 15px;
        }
    }

    .form-check-input[type="radio"]:disabled + label,
    .CalendarDay__blocked_out_of_range, 
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
        opacity: 0.4;
    }

    .highcharts-container rect.highcharts-point {
        fill: black;
    }
}

.small-spinner{
    width:20px;
    height:20px;
}

.cardTypeSelect {
    input {
        margin-bottom:0px;
    }
    .cardTypeSelectIcon{
        display:inline-block;
        position:relative;
        margin-right:8px;
        background-size: 40px auto!important;
        width:40px;
        height:27px;
        bottom:unset;
        top:unset;
        right:unset;
        vertical-align:middle;
    }
    [class$="-indicatorContainer"] {
        padding:0px;
        svg {
            width:25px;
            height:25px;
        }
    }
}

.card-number-card-type-select-showing{
    margin-bottom:8px!important;
}

.cardTypeSelectWrapper .invalid-status{
    margin-top:6px;
}

td.noPadding{
    padding-left:0px!important;
}

.ups-style div.datatable-v3 .table thead th.rightPadding{
    padding-right:8px!important;
}

.pointer{
    cursor:pointer;
}

.ups-style .md-form input[type=tel]:not(.browser-default).masked-number-input{
    box-sizing:content-box!important;
}
