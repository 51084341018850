@import "variables";
/* Can add this to ups.scss or keep as a separate file */

#advancedSearch .DayPicker {
  margin-top: 0;
  z-index: 1041 !important;
  right: auto;
  // top: 50%; /* position the top  edge of the element at the middle of the parent */
  // left: 50%; /* position the left edge of the element at the middle of the parent */
  // transform: translate(-70%);
}

.reportTextLink {
  color: #00857D !important;
}

.invoiceTextLink {
  color: #00857D !important;
}

.w-17p{
  width: 17%;
}

#advancedSearch .dateFilterPosition-1 .DayPicker {
  transform: translate(-30%);
}

#advancedSearch .dateFilterPosition-2 .DayPicker {
  transform: translate(-60%);
}

#advancedSearch .dateFilterPosition-3 .DayPicker {
  transform: translate(-90%);
}

#advancedSearch .dateFilterPosition-4 .DayPicker {
  transform: translate(-100%);
}

#advancedSearch input.select-dropdown {
  z-index: 1 !important;
}

#advancedSearch label.multi-select-label{
  height: 22px;
}

#advancedSearch .drop-down {
  margin-top: 23px;
}

#advancedSearch .multi-select {
  margin-top: 3px;
}

.ups-style-as legend {
  color: $primary-body-color;
}

@media screen and (max-width: 414px) {
  #advancedSearch .DayPicker {
    transform: translate(-100%) !important;
  }
}

//.adv-search-style-1 {
.form-check.search-card {
  //min-width: 33.333%;
  width: calc(20% - 20px);
  border: 1px solid #e3e3e3;
  padding: 15px 15px;
  justify-content: left;
  border-radius: 30px;
  margin-right: 20px;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
  }
}

.search-components-container {
  border: 1px solid #e3e3e3;
  padding: 20px 15px 15px 15px;
  border-radius: 30px;
  //margin-right: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1680px) {
  .form-check.search-card {
    width: calc(33.33334% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .form-check.search-card {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 767px) {
  .form-check.search-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem!important;
  }
}

/* these are the other variations, keeping it here but you can remove for the real app */
/* start */
.adv-search-style-2 {
  .form-check.search-card {
    width: auto;
    padding: 8px 8px 8px 0px;
    justify-content: left;
    border-radius: 30px;
    margin-right: 20px;
    margin-bottom: 8px;
    margin-left: 0;

    label {
      font-size: 0.875rem;
    }
  }

  .search-components-container {
    padding: 20px 15px 15px 0px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1680px) {
    .form-check.search-card {
    }
  }
}

.adv-search-style-3 {
  .search-components-container {
    margin-bottom: 20px;
  }
}

.ups-style .search-toggle label {
  font-weight: normal !important;
  font-size: 1rem;
  font-size: 0.875rem;
}
