/** This file is for browser compatibility adjustments **/
@import 'variables';


.ie11-btn-wrapper {
    position: relative;
    margin-right: 15px;
}

.btn.btn-ie11 {
    background-color: #fff!important;
    box-shadow: none!important;
    padding: 3px 3px!important;

    color: #f78b23!important;
    border:1px solid #f78b23!important;
    border-radius: 64px;
    width: 28px;
    height: 28px;
    margin: 0;
}

.ie11-icon {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 1;
    color: #f78b23!important;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 64px;
    width: 18px;
    height: 18px;
    touch-action: none;
    pointer-events: none;
    font-size: 1rem;
}

ol.ie11-list li {
    font-size: 14px;
}

@media screen and (-ms-high-contrast: none) {
    // fix IE11 font icon rendering issues
    table.dtr-column.collapsed .selectable-row td:first-child:before,
    table.dtr-column.collapsed td:not(.datatable-card):first-child:before,
    table.dtr-column.collapsed td.datatable-card + td:before {
        font-feature-settings: 'liga';
    }

    .dt-button.btn-icon .datatable-ups-svg-icon {
        vertical-align: middle!important;
    }

    .side-nav-icon-admin:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/admin_panel_settings.png)!important;
    }

    .side-nav-icon-admin.active:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/admin_panel_settings-active.png)!important;
    }

    .side-nav-icon-dispute:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/comment-dollar.png)!important;
    }

    .side-nav-icon-dispute.active:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/comment-dollar-active.png)!important;
    }

    .ups-style .dataTables_filter:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/105-search.png)!important;
    }

    .dataTables_length:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/039-chevrondown.png)!important;
    }

    .ups-style .md-form.md-browser-default-form:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/039-chevrondown.png)!important;
    }

    .ups-style .select-wrapper:before {
        background-color: #fff!important;
        -webkit-mask-image: none!important;
        mask-image: none!important;
        background: url(../images/icon/ups/fallback/039-chevrondown.png)!important;
    }

    .ups-style .btn-icon,
    .ups-style .btn-icon:hover, .ups-style :not([disabled]):not(.disabled).btn-icon:active,
    .ups-style .btn-icon:focus{
        line-height: 24px!important;
    }

    .datatable-ups-svg-icon {
        vertical-align: middle;
    }

    .ups-style .dataTables_length select::-ms-expand {
        display: none;
    }
}
