@import 'variables';

@media screen and (max-width: 767px) {
    .dt-payment-history-row {
        .dt-search-col {
            text-align: center;
            margin-bottom: 1rem;
        }

        .dt-buttons-col {
            text-align: center;
        }
    }
}

@media print {
    // payment history - view & cancel payment modal - hide the traditional modal header
    // there is an <h1> that is hidden for screen but shown on desktop
    // reason for this is when multipay, the print uses the datatable print functionality which is different than your traditional window.print
    // since we're using the datatable print by default, it only prints the contents of the datatable but it has a customized print function
    // in the datatable options that clones div.payment-receipt-summary-table-container and preprends it

    // when not multipay, it uses window.print as there is no datatable generated so the datatable print is not available

    .ups-style #view-payment-modal .modal-dialog .modal-content .modal-header,
    .ups-style #cancel-payment-modal .modal-dialog .modal-content .modal-header{
        display: none!important;
    }

    .ups-style #view-payment-modal .modal-dialog .modal-content,
    .ups-style #cancel-payment-modal .modal-dialog .modal-content{
        padding: 0!important;
    }

}