@import 'variables';

.pm-accepted-cards.pm-icon.pm-icon-smaller {
    width:40px;
    height:25px;
    background-size: 40px auto !important;
    top: 0.425rem;
}

input[name$="cardNumber"][value=""] ~ .pm-accepted-cards.pm-icon {
    opacity: 1!important;
    right: 0;
}

$sel: '';
input[name$="cardNumber"][value=""] {
    @for $i from 0 through 8 {
        $sel: if($i == 0, "~ .pm-accepted-cards.pm-icon", selector-nest($sel, "+ .pm-accepted-cards.pm-icon")) !global;

        #{$sel} {
            right: ($i * 3.5rem);
        }
    }
}

input[name$="cardNumber"][value=""] {
    @for $i from 0 through 8 {
        $sel: if($i == 0, "~ .pm-accepted-cards.pm-icon.pm-icon-smaller", selector-nest($sel, "+ .pm-accepted-cards.pm-icon.pm-icon-smaller")) !global;

        #{$sel} {
            right: ($i * 2.7rem);
        }
    }
}


.ups-style .md-form input[type=text]:not(.browser-default).masked-number-input{
    box-sizing:content-box!important;
}
.ups-style {
    .display-label {
        color: $primary-body-color;
        font-weight: 700;
    }
}
#pmDetailsSection .md-form{
    margin-top: 1rem;
}
.cc-dc-title {
    color: #C67D30 !important;
    font-weight: 700 !important;
}
.echeck-decoration-line1 {
    top: 96px;
    width: calc(100% - 30px);
}
.echeck-decoration-box1 {
    display: none;
}
.pm-cvv-sample {
    top: auto;
    bottom: 32px;
    right: 16px;
}
.wallet-section {
    min-height: 490px;
}
.wallet-section {
    .card-container {
        margin-bottom: 0.5rem;
        .pm-info {
            position: absolute;
            color: $primary-body-color;
            &.echeck-type {
                top: 38px;
            }
        }
        &.card-paypal {
            .pm-info {
                position: relative;
                color: $primary-body-color;
            }
        }
    }

    .card-exp-container {
        position: absolute;
        top: 60%;
        left: auto;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.571rem;
        line-height: 8px;
        margin-top: 2px;
        display: flex;
        align-items: flex-start;

        .pm-info {
            position: relative;
            left: auto;
            margin-right: 1rem;
            top: auto;

            &.card-exp-label {
                margin-top: 0;
            }
        }
    }
    .card-actions {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        .btn {
            white-space: nowrap;
            &.btn-text-link {
                font-size: 14px!important;
                font-size: 0.875rem!important;
                padding: 5px 5px!important;
            }
            &:last-child {
                margin-left: auto!important;
                white-space: normal!important;
                text-align:right;
                padding-left: 5px!important;
                flex: 1;
            }
        }
        .is-default-pm-card {
            display: inline-block;
            line-height: 15.4px;
            font-size: 14px;
            font-size: 0.875rem;
            padding: 5px 15px 5px 5px;
            color: $primary-body-color;
            font-weight: bold;
            margin-left: auto;
            text-align: right;
            white-space: normal;
            flex: 1;
        }
    }
}
.echeck-decoration-icon-bank {
    top: 112px;
}
.pm-cvv-sample {
    &.amex-cvv {
        background: transparent url(../images/pm/cvv_amex.png) no-repeat 0 0;
    }
}
// REMOVE LATER IN CASE MAKE DEFAULT IN TABLE VARIANT IS NOT GOOD
#my-wallet-payment-method-table-datatable td {
    line-height: normal!important;
    white-space: pre-wrap;
}
.ups-style div.dataTables_wrapper table.allow-default-wrap td,
.ups-style div.dataTables_wrapper table.allow-default-wrap th{
    white-space: normal!important;

    &.text-nowrap {
        white-space: nowrap!important;
    }
}
.card-container.card-paypal:before {
    background: transparent url(../images/pm/pp-logo-100px.png) no-repeat 95% 90%;
}
.card-container.card-paypal .pm-info.card-name,
.card-container.card-paypal-credit .pm-info.card-name{
    position: relative;
    left: auto;
    text-align: left;
    padding-left: 0;
    bottom: auto;
    padding-top: 5px;
}
.card-container.card-paypal .pm-info.time-stamp,
.card-container.card-paypal-credit .pm-info.time-stamp{
    left: auto;
    text-align: left;
    padding-left: 0;
}
.display-md-form {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.display-plaintext {
    padding-top: 0;
    min-height: 0;
}
.button-load-container {
    position: relative;
    width: 100%!important;
    max-width: 100%!important;
    display: block!important;
    margin-top: 12px;
    //min-width: 374px;
    //height: 100px;
}
#paypal-button {
    width: 100%!important;
    max-width: 100%!important;
    display: block!important;
}
#paypal-button * {
    overflow: visible !important;
}
.button-load-container.paypal-load-container, .button-load-container.paypal-credit-load-container {
    height: auto!important;
}
.paypal-load-container .button-load-spinner, .paypal-credit-load-container .button-load-spinner {
    height: auto!important;
}
.hide-thead {
    display: none;
}
.ups-style table.table-hover tbody tr.table-card-row:hover {
    background-color: #fff!important;
}
.table-wallet {
    border: none!important;
}
tr.table-card-row {
    float: left;
    width: 25%;
    text-align: center;
    box-shadow: none !important;
    border: none !important;
    margin: 1% 0% !important;
    display: block;
    //height: 238px;
    td {
        display: block;
        text-align: left;
        width: 100% !important;
        background-color: #fff;
        height: auto!important;
        border: none!important;
        padding: 8px 10px 8px 0!important;
    }
}
tr.table-card-row .card-container {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
}
.modal-stacked {
    #modal-root .modal-container:first-child {
        .fade.show {
            opacity: 0!important;
            pointer-events: none!important;
        }
    }
}

.remove-pm-alert-container {
    max-width: 350px;
    margin-top: 1.5rem;
}

@media screen and (max-width: 1024px) {
    .modal-content .card-container {
        margin: 0 auto;
    }
    .remove-pm-alert-container {
        margin: 1.5rem auto 0 auto;
    }
}

@media screen and (max-width: 1536px) {
    tr.table-card-row {
        width: 33.3333%;
    }
}

@media screen and (max-width: 768px) {
    .card-container.credit-card .pm-info.card-number {
        font-size: 1.5rem;
    }

    input[name$="cardNumber"][value=""] {
        @for $i from 0 through 8 {
            $sel: if($i == 0, "~ .pm-accepted-cards.pm-icon", selector-nest($sel, "+ .pm-accepted-cards.pm-icon")) !global;

            #{$sel} {
                right: ($i * 2.25rem);
            }
        }
    }

    input[name$="cardNumber"][value=""] {
        @for $i from 0 through 8 {
            $sel: if($i == 0, "~ .pm-accepted-cards.pm-icon.pm-icon-smaller", selector-nest($sel, "+ .pm-accepted-cards.pm-icon.pm-icon-smaller")) !global;

            #{$sel} {
                right: ($i * 2.25rem);
            }
        }
    }

    .pm-accepted-cards.pm-icon, .pm-highlight.pm-icon, .pm-highlight.pm-icon.pm-icon-smaller, .pm-accepted-cards.pm-icon.pm-icon-smaller {
        top: 0.563rem;
        width: 32px;
        height: 20px;
        background-size: 32px auto!important;
        &.methodVPay {
            background-size: 18px auto!important;
        }
    }
}

@media screen and (max-width: 767px) {
    .dt-button.btn-card-view {
        display: none!important;
    }
    .dt-button.btn-table-view {
        display: none!important;
    }
}

@media screen and (max-width: 580px) {
    .wallet-section .btn-secondary {
        width: 100%;
    }
    .wallet-section .btn-primary {
        width: 100%;
    }
}
@media screen and (max-width: 320px) {
    .card-container.credit-card .pm-info.card-number {
        font-size: 1.25rem;
    }
}
@media print {
    .modal-open {
        #root { display: none!important;}
        .modal {
            visibility: visible!important;
            /**Remove scrollbar for printing.**/
            overflow: visible !important;
        }
        .modal-dialog {
            visibility: visible !important;
            /**Remove scrollbar for printing.**/
            overflow: visible !important;
        }
    }
    .ups-style .modal-dialog .modal-content .modal-header {
        display: none!important;
    }
    .ups-style .modal-dialog .modal-content {
        padding: 0!important;
    }
    table.dtr-column.collapsed .selectable-row td:first-child:before,
    table.dtr-column.collapsed td:first-child:before {
        display: none!important;
    }
}

.pm-card-expiration-text, .pm-card-pending-token-text {
    color: red
}