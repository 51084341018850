$font-stack-1:  Tahoma,helvetica,arial,sans-serif!important;
// their style guide says they use this Georgia Regular for some headings, their website shows otherwise but leaving this here in case
$font-stack-2:  Georgia Regular;

$ups-brown: #350000!important;
$ups-black: #000!important;
$ups-teal: #007A72!important;
$ups-teal-dark: #016862!important;
$ups-teal-light: #E6F3F2!important;
$ups-green-dark: #173F35!important;
$ups-green-dark-75: #516F67!important;
$ups-green-dark-55: #7F9590!important;
$ups-green: #6E7E1B!important;
$ups-green-75: #939F54!important;
$ups-green-55: #B0B882!important;
$ups-brown-dark: #5E544B!important;
$ups-brown-mid: #90867F!important;
$ups-brown-light: #BBB0A2!important;
$ups-brown-light-50: #DDD7D0!important;
$ups-brown-light-10: #F7F6F5!important;
$ups-blue: #0662BB!important;
$ups-blue-dark: #105497!important;
$ups-neutral-gray-1: #D5D5D5!important;
$ups-neutral-gray-2: #F1F1F1!important;
$ups-sienna: #A46828!important;
$ups-sienna-75: #98632A!important;
$ups-sienna-55: #E0B78D!important;
$ups-stroke: #707070!important;
$ups-plum: #910048!important;
$ups-plum-light-75: #ad4176!important;
$ups-plum-light-55: #7f9590!important;
$ups-red-error: #C91A13!important;
$ups-gray-inactive-text: #707070!important;
$ups-gray-inactive-background: #EDEDED!important;
$ups-inactive-grey: #EBEBEB!important;

$ups-gold: #FFC400!important;
$ups-gold-dark: #e6b000!important;
$ups-gold-light: #ffd64c!important;

$btn-primary-color: #000!important;
$btn-primary-background: $ups-gold;
$btn-primary-border: none!important;
$btn-primary-color-hover: #121212!important;
$btn-primary-background-hover: $ups-gold-light;
$btn-primary-border-hover: none!important;
$btn-primary-color-focus: #000!important;
$btn-primary-background-focus: $ups-gold!important;
$btn-primary-border-focus: none!important;

$btn-secondary-color: $ups-blue;
$btn-secondary-background: #fff!important;
$btn-secondary-border: 1px solid $ups-blue;
$btn-secondary-color-hover: $ups-teal;
$btn-secondary-background-hover: #E6F3F2!important;
$btn-secondary-border-hover: 1px solid $ups-blue;
$btn-secondary-color-focus: $ups-blue;
$btn-secondary-background-focus: #E6F3F2!important;
$btn-secondary-border-focus: 1px solid $ups-blue;
$btn-icon-border: 1px solid $ups-teal;

$btn-disabled-color: #fff!important;
$btn-disabled-background: #AAAAAA!important;

$primary-body-color: #242424!important;
$table-th-background: #e8e8e8!important;

@mixin button($color, $background, $border) {
    border-radius: 0;
    background: none;
    border: none;
    padding: 0;
    line-height: 1;
    border-radius: 25px;
    display: block;
    background: $background;
    color: $color;
    font-size: 1rem!important;
    line-height: 1.1em;
    padding: 10px 30px 10px;
    text-align: center;
    font-weight: 400;
    font-family: $font-stack-1;
    display: inline-block;
    text-decoration: none;
    -moz-transition-property: all;
    -o-transition-property: all;
    -webkit-transition-property: all;
    transition-property: all;
    -moz-transition-duration: .2s;
    -o-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    text-transform: initial;
    border: $border;
    box-shadow: none!important;
    border-radius: 65px!important;
}

@mixin select() {
    //width: 100%;
    //display: block;
    color: $primary-body-color;
    //margin-bottom: 5px;
    //height: 35px;
    //padding: 6px 10px 7px 11px;
    //border-radius: 7px;
    //border: 1px solid $ups-stroke;
    //font-size: 14px;
    font-family: $font-stack-1;
    font-weight: normal;
    //-webkit-appearance: none;
    //-moz-appearance: none;
    //appearance: none;
    //background: transparent;
    //position: relative;
    //cursor: pointer;
    //pointer-events: auto;
    //padding-right: 30px;
    //left: 0;
    box-sizing: border-box;
}