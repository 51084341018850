@import 'variables';

.ups-style table.dataTable thead {
    th.payment-amount-col {
        &.sorting, &.sorting_asc, &.sorting_desc {
            padding-right: 32px!important;
        }
    }
}
th.payment-amount-col,
td.payment-amount-col {
    width: 198px!important;
    text-align: right!important;
    padding-right: 26px!important;

    .invalid-status {
        margin: 0;
    }

    .md-form .form-control {
        text-align: right;
        font-size: 0.813rem!important;
        margin: 0!important;
        padding: 0.438rem 0.5rem 0.5rem 0.5rem;
        color: #242424!important;
        font-weight: 400!important;
        width: 118px;
        display: inline-block;

        &:focus {
            padding: 0.438rem 0.5rem 0.5rem 0.5rem!important;
        }

        &[readonly] {
            background: transparent!important;
            border-color: transparent!important;
        }

        &.is-invalid {
            text-align: left;
        }
    }
}

//.pay-later-date-container {
//    width: 268px;
//    display: inline-block;
//    margin-left: 1rem;
//    vertical-align: top;
//    margin-top: -13px;
//
//
//    .DateInput_input {
//        font-size: 1rem;
//    }
//
//    .SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
//        top: 2px;
//    }
//}


tr.disable-payment-row td.datatable-selection-col {
    -webkit-opacity: 1;
    -moz-box-opacity: 1;
    -ms-box-opacity: 1;
    -o-box-opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

//.bank-direct-debit-only-warning {
//    max-width: 728px;
//        margin: 0 auto;
//}
//
//h6.view-component-title {
//    font-size: 18px;
//    //font-weight: 700!important;
//    padding-top: 5px;
//}
//
//.make-payment-add-payment-method {
//    margin-top: 15px;
//}


@media screen and (max-width: 1023px) {
    //.pay-later-date-container {
    //    width: 70%;
    //}

    th.payment-amount-col, td.payment-amount-col {
        width: 108px!important;
        padding-right: 10px!important;
    }
    th.payment-amount-col .md-form .form-control,
    td.payment-amount-col .md-form .form-control {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    // .pay-later-date-container {
    //     width: 198px;
    // }

    .ups-style .pay-my-bill-section  .progress-display {
        margin-left: -15px;
        margin-right: -15px;
    }
}

//@media screen and (max-width: 320px) {
//    .pay-later-date-container {
//        width: 148px;
//    }
//}

.btn.btn-short-pay {
    display: inline-block;
    padding: 0.5rem!important;
    vertical-align: top;

    svg {
        width: 16px;
        height: 16px;
    }
}

.datatable-cell-pay-amount {
    display: inline-block;
    padding: 0.5rem;
}

.sepa-mandate-container {
    h5, h6 {
        margin-bottom: 1.5rem;
    }

    .md-form {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    p {
        font-size: .875rem;
    }
}
