body:not(.landing-payanyone):not(.otp-payanyone) {
    background-color: #fff;
}

#main-nav {
    width: 268px;
    box-shadow: none!important;
    border-right: 1px solid #e3e3e3;
}

#main-nav ul.list-unstyled {
    padding-left: 15px;
    padding-right: 15px;
}

.main-nav-note {
    font-size: 12px;
    color: rgb(174 169 169);
    padding-left: 20px;
}

.nav-drawer-separator {
    width: 212px;
}

.navbar {
    position: relative;
    background-color: #fff;
    box-shadow: none!important;
    padding-left: 268px!important;
    height: 88px;
}

@media screen and (max-width: 1440px) {
    .navbar {
        padding-left: 1.75rem!important;
        height: auto;
    }

}

#main-container .container-fluid{
    padding-left: 20px;
    padding-right: 20px;
}

#main-container .container-fluid.ipn-restrict-width {
    margin-left: 0;
}

@media screen and (max-width: 1440px) {
    #main-container .container-fluid {
        padding: 0;
    }

    #main-container .container-fluid.ipn-restrict-width {
        max-width: 100%;
    }
}


.app-title {
    padding-left: 38px;
}

.app-title h1 {
    font-size: 2rem;
    color: #000;
}

.fixed-sn main {
    padding-left: 268px;
    padding-top: 1rem;
}

@media screen and (max-width: 1440px) {
    .fixed-sn main {
        padding-left: 0;
    }
}

.side-nav .collapsible-body a {
    background-color: #fff;
}

.preference-container {
    position: fixed;
    top: 16px;
    right: 8px;
}

.issuer-container {
    position: fixed;
    top: 16px;
    right: 64px;
}

/*.btn:not(.btn-floating):not(.btn-text-link):not(.btn-minimal):not(.custom-vertical-dropdown):not(.dt-button):not(.sr-only):not(.btn-toggle-demo-api-call):not(.btn-demo-api-popover) {*/
    /*border-radius: 65px!important;*/
    /*border: none!important;*/

    /*box-shadow: none!important;*/
    /*text-transform: initial!important;*/
    /*background-color: #15477d!important;*/

/*}*/

.btn-primary {
    border-radius: 65px!important;
    border: none!important;

    box-shadow: none!important;
    text-transform: initial!important;
    background-color: #15477d!important;

}

.btn:not(.btn-floating):not(.sr-only):not(.btn-document-link):not(.btn-input-group) {
    /*border-radius: 65px!important;*/
}

/*.btn:not(.btn-floating):not(.btn-text-link):not(.btn-minimal):not(.custom-vertical-dropdown):not(.dt-button):not(.sr-only):not(.btn-toggle-demo-api-call):not(.btn-demo-api-popover):hover {*/
    /*background-color: #006EE5 !important;*/
/*}*/


.btn-primary:hover {
    background-color: #006EE5!important;
}

.card {
    box-shadow: none!important;
    margin-top: 0;
}

.card-header {
    margin-top: 0;
    color: #333;
}

.side-nav .logo-wrapper {
    margin-top: 15px;
}

a[class*="side-nav-icon"]:before {
    left: 12px;
}

.side-nav.wide .collapsible a {
    line-height: normal;
    padding-top: 10px;
    margin-top: 1px;
    padding-bottom: 10px;
    padding-left: 44px;
}

.side-nav .collapsible a i.fa.fa-angle-down, .side-nav .collapsible a i.far.fa-angle-down, .side-nav .collapsible a i.fas.fa-angle-down {
    right: -3px;
}

table.dataTable, table.dataTable th, table.dataTable td {
    box-sizing: border-box!important;
}

hr.hr-thick {
    border-color: #dcdcdc;
}

.ipn-main .search-bar-container {
    margin: 0 auto;
    padding: 0 20px;

}

.ipn-main .search-bar-button.search-button {
    left: 20px;
}

.search-bar-button-container {
    right: 20px;
}

.form-intl {
    position: relative;
}

.md-form .prefix.input-clear-icon {
    left: auto;
    right: 0;
    top: 4px;
    padding: 0.5rem;
    font-size: 1.125rem;
    cursor: pointer;
    /*font-size: 0.75rem;*/
}
.md-form .prefix.input-eye-icon-right {
    right: 15px;
}

.md-form .prefix.input-clear-icon ~ label {
    margin-left: 0;
}

.md-form .prefix.input-clear-icon ~ input, .md-form .prefix.input-clear-icon ~ textarea {
    margin-left: 0;
    width: 100%;
}

.input-clear-button-container {
    position: absolute;
    display: flex;
    right: 0;
    top: 1px;
}

.input-clear-button-container button.btn.btn-primary.btn-minimal {
    /*height: 56px;*/
    /*width: 56px;*/
    /*margin: 0;  */
    /*border-radius: 0!important;*/
    /*margin-left: 1px;*/
    /*color: #ccc!important;*/
}