body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

body:not(.landing-payanyone):not(.otp-payanyone) {
    background-color: #f8f8f8;
}

.react-select-container .react-select__menu-list.react-select__menu-list--is-multi .react-select__option.react-select__option--is-focused.react-select__option--is-selected {
    background-color: #F1F1F1 !important;
}


:lang(debug) .dataTables_wrapper {
    overflow-x: auto;
}

:lang(debug) .side-nav a{
    white-space: nowrap;
}

:lang(debug) * {
    font-size: 11px!important;
}

.sr-only {
    position: absolute!important;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    border-radius: 0px!important;
    box-shadow: none!important;
    text-transform: initial!important;
    background-color: #15477d!important;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: absolute!important;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    z-index: 8888;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 0px!important;
    box-shadow: none!important;
    text-transform: initial!important;
    background-color: #006EE5!important;
    color: #ffffff;
}



.sr-skip {
  -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

h2 {
    font-size: 1.500rem;
    font-weight: 500;
    color: #333;
}


h3,
.card .card-body h3{
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}



.login-bg {
  /*background: #0580c4 url(../images/welcome_bg.png) repeat 0 0;*/ /* blue */
    background: #efefef url(../images/welcome_bg.png) repeat 0 0;
}

.global-ipn-bg {
    background: #fff url(../images/global_ipn_bg.png) no-repeat 0 0;
    background-size: 100% auto;
    background-attachment: fixed;
}

.page-login .biller-logo {

}

body {
    color: #111;
}

a:link, a:visited {
    color: #0066c0;
}

a:hover {
    text-decoration: underline;
}

.landing-payanyone .dropdown.preference-dropdown a:link, .landing-payanyone .dropdown.preference-dropdown a:visited {

    padding: 10px;
    font-size: .9375rem;
    font-weight: 300;
    color: #000;

}



.login-container,
.pay-now-container {
    width: 100%;
    /*padding: 1.25rem 1.25rem;*/
    margin: 0 auto;
    background: rgba(255,255,255,0.88);
    /*height: 100vh;*/
    max-width: 538px;
}

.login-container.agent-login-card {
    width: 528px!important;
    max-width: 100%;
}

.login-container h1 {
    font-weight: 600;
}

.login-container p,
.pay-now-container p {
    font-size: 0.875rem;

}

.login-container .card-header,
.pay-now-container .card-header{
    background-color: #fff;
    color: #000;
    border: none;
    height: auto;
    line-height: normal;
}

.login-container .card-header h2,
.pay-now-container .card-header h2 {
    font-size: 1.313rem;
    font-weight: 500;
    padding: 1rem 0;
    margin: 0;
}

.card.login-card,
.card.pay-now-card{
    background-color: transparent;
}



ul.simplify-your-life,
ul.simplify-your-life li {
    list-style: none;
    font-size: 0.875rem;
}

.md-form .form-control {
    background-color: #fff!important;
}

.register-container {
    width: 100%;
    max-width: 568px;
    padding: 1.25rem 1.25rem;
    margin: 0 auto;
}

/*
.md-form input[type=text].ipn-custom-input {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}*/

.md-form {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/*.btn:not(.btn-floating):not(.sr-only):not(.btn-input-group) {*/
  /*border-radius: 3px!important;*/
/*}*/

.btn-primary {
  background-color: #2374CC!important;
    color: #fff;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: #0b51c5!important;
}

.btn-outline-primary {
    color: #2374cc!important;
    border-color: #2374cc!important;
}

.btn-outline-primary:hover {
    color: #0b51c5!important;
    border-color: #0b51c5!important;
}

.btn[class*="btn-outline-"].btn-minimal {
    padding: 0.375rem 0.75rem!important;
    line-height: 24px;
}

.btn[class*="btn-outline-"].btn-minimal.has-icon {
    padding: 0.375rem!important;
}

.btn[class*="btn-outline-"].btn-minimal i.material-icons {
    padding-bottom: 0;
    vertical-align: top;
}

.btn-primary:not([disabled]):not(.disabled).btn-minimal:active,
.btn-primary:not([disabled]):not(.disabled).btn-minimal.active {
    background-color: #fff!important;
    text-decoration: underline;
}

.btn.btn-text-link {
    display: inline-block;
    text-decoration: none;
    color: #0066c0;
    padding: 0 0.5rem;
    background: none!important;
    box-shadow: none!important;
    text-transform: none;
    text-transform: initial;
    font-size: inherit!important;
    margin: 0;
    line-height: inherit;
    vertical-align: top;
    text-align: left;
}

.btn.btn-text-link.current-link {
    text-decoration: underline;
}

.btn.btn-text-link:focus,
.btn.btn-text-link:hover,
.btn-default.btn-text-link:not([disabled]):not(.disabled):active, .btn-default.btn-text-link:not([disabled]):not(.disabled).active {
    box-shadow: none!important;
    background-color: transparent !important;

}

@media screen and (max-width: 575px) {
    /*.btn-final-action,*/
    /*.btn-return {*/
        /*-webkit-box-ordinal-group: -1;*/
        /*-ms-flex-order: -1;*/
        /*order: -1;*/
    /*}*/

    /*.btn-print {*/
        /*-webkit-box-ordinal-group: 12;*/
        /*-ms-flex-order: 12;*/
        /*order: 12;*/
    /*}*/

    /*.btn-cancel, .btn-start-over {*/
        /*-webkit-box-ordinal-group: 13;*/
        /*-ms-flex-order: 13;*/
        /*order: 13;*/
    /*}*/

    .flex-footer button:first-child {
        margin: 0;
    }

    .flex-footer button {
        margin: 0;
    }

    .flex-footer button:last-child {
        margin: 0;
    }
}

.side-nav .logo-wrapper {
  display: flex!important;
  height: 60px!important;
  max-width: 100%;
  box-sizing: border-box;
  -webkit-align-items: center!important;
  align-items: center!important;
  border: none;
}

.side-nav .logo-wrapper a {
    height: auto;
    padding: 0;
    text-align: center;
    width:auto;
    margin: 0 auto;
}

.side-nav .logo-wrapper img {
  padding: 8px 8px;
  margin: 0 auto;
    max-height: 64px;

}

.side-nav {
  background-color: #fff;
    border-right: 1px solid rgb(224,224,224);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding-bottom: 0!important;
}

.side-nav a {
  font-size: 0.875rem;
  font-weight: 400;
}

.side-nav .poweredby a:link,
.side-nav .poweredby a:visited {
  height: auto;
  line-height: 26px;
  display: inline-block;
  padding-left: 0;
  font-size: 12px;
  color: #424242;
  vertical-align: top;
}

.side-nav .poweredby a.active-theme:link,
.side-nav .poweredby a.active-theme:visited {
  color: #1577dd;
}

.side-nav .poweredby a.footer-link:link,
.side-nav .poweredby a.footer-link:visited {
    color: #007bff;
    line-height: normal;
    display: block;
}

.side-nav .poweredby a.footer-link:hover {
    color: #0056b3;
}

.poweredby p.last-login {
    font-size: 0.75rem;
}

.theme-options {
  color: #ccc;
}

.navbar {
    height: 64px;
    background: #ffffff;
    z-index: 102;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.nav-drawer-separator {
    height: 1px;
    background-color: #dcdcdc;
    margin: 8px auto;
    width: 200px;
}

.poweredby {
    text-align: center;
    padding: 16px 16px;

}

.poweredby p {
  font-size: 0.875rem;
    color: #424242;
    line-height: normal;
}

.app-title {
    height: 64px;
    vertical-align: middle;
    display: table-cell;
    padding-left: 16px;
    color: #333;
}

.card-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
}

.side-nav {
  opacity: 1!important;
}

@media screen and (min-width: 1440px) {
  .side-nav {
    transition-duration: 0s !important;
  }
}

.side-nav .collapsible a,
.side-nav .collapsible a:link,
.side-nav .collapsible a:visited {
    font-size: 0.875rem;
    font-weight: 400;
    height: auto;
    line-height: normal;
    padding-top: 10px;
    margin-top: 1px;
    padding-bottom: 10px;
    color: #424242;
}


.side-nav .collapsible .collapsible-body a,
.side-nav .collapsible .collapsible-body a:link,
.side-nav .collapsible .collapsible-body a:visited {
    height: auto;
    font-size: 13px;
}

.side-nav .collapsible .collapsible-body .collapsible .collapsible-body a,
.side-nav .collapsible .collapsible-body .collapsible .collapsible-body a:link,
.side-nav .collapsible .collapsible-body .collapsible .collapsible-body a:visited {
   background-color: #e3e3e3;
}



.side-nav .collapsible a.active {
  /*color: #15477d;*/
    color: #006ee5;
    /*color: #0056b2; !* WCAG AAA *!*/
}

.side-nav .collapsible li a:hover {
    background-color: #efefef;
}

.side-nav .collapsible-body a {
  background-color: #efefef;
}


.side-nav .collapsible a i.fa.fa-angle-down,
.side-nav .collapsible a i.far.fa-angle-down,
.side-nav .collapsible a i.fas.fa-angle-down {
    font-size: 1.125rem;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: -8px;
}

/*
.side-nav .collapsible a i.fa.fa-angle-down,
.side-nav .collapsible a i.far.fa-angle-down,
.side-nav .collapsible a i.fas.fa-angle-down {
    min-width: 0;
    position: absolute;
    right: 0;
    top: 0;
}*/

.side-nav .collapsible a:hover {
  color: #15477d;
  text-decoration: underline;
}

.side-nav .collapsible a .material-icons {
  vertical-align: middle;
    padding-right: 16px;
}

.side-nav .collapsible a.collapsible-header:link,
.side-nav .collapsible a.collapsible-header:visited{
  color: #424242;
}

.side-nav .collapsible a.collapsible-header:hover {
  color: #15477d;
  text-decoration: underline;

}

.side-nav .fa-angle-down.rotate-icon {
  top: 1.313rem;
    transition: all 150ms ease-in 0s;
}

.collapsible .active .rotate-icon {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.side-nav .collapsible li ul.sub-menu .collapsible-header {
  background-color: #efefef;
  line-height: 36px;
  height: 36px;
  padding-left: 54px;
}

.side-nav .collapsible>li a.collapsible-header.active {
  /*color: #15477d;*/
    /*color: #0056b2;*/ /* WCAG AAA */
    color: #006EE5;
}

.side-nav .collapsible>li a.collapsible-header:hover {
    background-color: #efefef;
}

.double-nav a {
  color: #333;
}

.breadcrumb-container {

}

.app-title {
    height: 64px;
    vertical-align: middle;
    display: table-cell;
    padding-left: 16px;
    color: #000;
}

.app-title h1 {
   font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

.nav-flex-icons a .material-icons {
  vertical-align: top;
  margin-left: 16px;
}

.nav-flex-icons a.nav-link {
  padding-left: 16px;
  padding-right: 16px;
}

.nav-flex-icons a.nav-link.dropdown-toggle::after {
  display: none;
}

.nav-flex-icons .dropdown .dropdown-menu.dropdown-info .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #efefef!important;
  -webkit-box-shadow: none!important;
  box-shadow:none!important;
  -webkit-border-radius: 0!important;
  border-radius: 0!important;
  color: #333!important;
}


.nav-flex-icons .dropdown .dropdown-menu.dropdown-info .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item.dropdown-item:focus {
    outline: -webkit-focus-ring-color auto 1px!important;
}

button:focus {
    outline: -webkit-focus-ring-color auto 1px!important;
}

/*fixes an issue with outline rendering in some cases*/
.outline-fix:focus, .dropdown .dropdown-menu .dropdown-item.dropdown-item.outline-fix:focus{
    outline: -webkit-focus-ring-color solid 2px!important;
    outline-offset: -2px;
}

.fixed-sn main {
    margin: 0 0 15px 0;
}

.card-header {
  border-top: 1px solid #ececec;
      position: relative;
    font-size: 1.313rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0rem 1.25rem;
    height: 48px;
    line-height: 48px;
    background-color: #71767e;
    color: #fff;
    height: auto;
    line-height: normal;
    margin-top: 1.5rem;
    font-size: 1.500rem;
    background-color: #fff;
    color: #111;
    border: none;
}

.card-header.note-header {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: .7rem;
    background-color: #fff;
    line-height: normal;
    height: auto;
    color: #212529;
    border-radius: 0;
    border: none;
}

.card-footer {
    border: none;
    background-color: #fff;
}

h3.side-widget-heading {
    font-size: 1rem;
    font-weight: 500!important;
    margin: 0;
}

.table-action-menu {
  text-align: center;
}

.table-action-menu .btn-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
}

.table-action-menu .material-icons {
  margin-top: 4px;
}

table th {
  font-weight: 600;
}

td.account-sel-col {
    width: 68px;
    cursor: pointer;
}

tr.selectable-row td:not(.prevent-toggle) {
  cursor: pointer;
}

tr.disable-payment-row td {
    -webkit-opacity: 0.68;
    -moz-box-opacity: 0.68;
    -ms-box-opacity: 0.68;
    -o-box-opacity: 0.68;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=68)";
    filter: alpha(opacity=68);
    opacity: 0.68;

}

tr.disable-payment-row td.account-sel-col {
    -webkit-opacity: 1;
    -moz-box-opacity: 1;
    -ms-box-opacity: 1;
    -o-box-opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

/*tr.disable-PaymentFilter-row td input[type="text"] {
    pointer-events: none;
}*/


th.action-col, td.action-col {
    width: 58px;
}


h3.section-title {
    background-color: #71767e; /* 93989e */
    color: #fff;
    padding: .7rem;
    font-size: 1rem;
    font-weight: 500;
}

.card .card-body h3.category-section-title, h3.category-section-title {
    background-color: #bebebe;
    color: #212529;
    padding: .7rem;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.25rem;
}

.card .card-body h4.subsection-title, h4.subsection-title {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0;
    padding: .7rem;
    background-color: #e0e0e0;
}

.card .card-body h5.subsection-title, h5.subsection-title {
    position: relative;
    font-size: 0.813rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: .5rem .7rem;
    background-color: #e0e0e0;
}

.subsection-actions {
    position: absolute;
    top: 9px;
    right: 18px;
}

.subsection-actions .btn.btn-primary.btn-minimal {
    background-color: transparent!important;
    box-shadow: none!important;
    padding: 0!important;
    font-size: 0.813rem;
}

.subsection-actions .btn.btn-primary.btn-minimal:hover {

}


.card.card-minimal {
    box-shadow: none;
}

.card.card-minimal .card-header {
    /*
    color: #212529;
    background-color: #e0e0e0;
    */
}
.card.card-minimal .card-body {
    padding-left: 0;
    padding-right: 0;
}

.card-body.ipn-min-height {
    min-height: 628px;
}

section.wallet-section {
    padding-left: 20px;
    padding-right: 20px;
}
.wallet-section .card-container {
    display: inline-block;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 370px;
}

.card-container .pm-icon {
    border: none;
}

.card-container.card-paypal,
.card-container.card-paypal-credit{
    /*background: #009cde;*/
    /*background: -moz-linear-gradient(left, #009cde 1%, #003087 100%);*/
    /*background: -webkit-linear-gradient(left, #009cde 1%,#003087 100%);*/
    /*background: linear-gradient(to right, #009cde 1%,#003087 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009cde', endColorstr='#003087',GradientType=1 );*/
    /*border: none;*/
}

.card-container.card-paypal:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: transparent url(../images/pm/paypal_logo_white.png) no-repeat center center;*/
    background: transparent url(../images/pm/paypal_wallet_card_logo.png) no-repeat center center;
}

.card-container.card-paypal-credit:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url(../images/pm/paypal_credit_logo_white.png) no-repeat center center;

}

.card-container.card-paypal .pm-info.card-name,
.card-container.card-paypal-credit .pm-info.card-name{
    position: absolute;
    bottom: 32px; /* need to set to 32px when we show time stamp */
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    text-transform: initial;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 0.875rem;
}

.card-container.card-paypal .pm-info.time-stamp,
.card-container.card-paypal-credit .pm-info.time-stamp{
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    text-transform: initial;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    font-size: 12px;
}

.card-container.card-amazon {
    background: #fff;
}

.card-container.card-amazon:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url(../images/pm/amazon_pay_logo.svg) no-repeat center center;
}


.card-container.card-amazon .pm-info.card-name {
    position: absolute;
    bottom: 32px; /* need to set to 32px when we show time stamp */
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    text-transform: initial;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #232f3e;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 0.875rem;
}

.card-container.card-amazon .pm-info.time-stamp {
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    text-transform: initial;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #232f3e;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    font-size: 12px;
}

/*
.modal-footer {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: end;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.modal-footer button {
    margin-top: 0;
    margin-bottom: 0;
}

.modal-footer button:first-child {
    margin-right: auto;
    margin-left: 4px;
}*/

.table-bordered-simple {
    border: 1px solid #e0e0e0;
}

.table.table-bordered-simple td, .table.table-bordered-simple th {
  vertical-align: middle;
  font-weight: 400;
}

.table.table-bordered-simple tr.tr-spacer td, .table.table-bordered-simple tr.tr-spacer th {
    border-top: 1px solid #e0e0e0;
    border-left: none;
    border-right: none;
    height: 20px;
}

.table-sm td, .table-sm th {
  padding-left: 0.6rem!important;
  padding-right: 0.6rem!important;
}

/* selectize bootstrap 4 + MDB styling start */
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  -webkit-box-shadow: inset 0 0 12px 4px #ffffff;
  box-shadow: inset 0 0 12px 4px #ffffff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: '!';
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.selectize-dropdown-header {
  position: relative;
  padding: 3px 12px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.selectize-dropdown-header-close {
  position: absolute;
  right: 12px;
  top: 50%;
  color: #333333;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
}
.selectize-dropdown-header-close:hover {
  color: #000000;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}
.selectize-control.plugin-remove_button [data-value] {
  position: relative;
  padding-right: 24px !important;
}
.selectize-control.plugin-remove_button [data-value] .remove {
  z-index: 1;
  /* fixes ie bug (see #392) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 17px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 1px 0 0 0;
  border-left: 1px solid rgba(0, 0, 0, 0);
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.selectize-control.plugin-remove_button [data-value] .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selectize-control.plugin-remove_button [data-value].active .remove {
  border-left-color: rgba(0, 0, 0, 0);
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled [data-value] .remove {
  border-left-color: rgba(77, 77, 77, 0);
}
.selectize-control.plugin-remove_button .remove-single {
  position: absolute;
  right: 28px;
  top: 6px;
  font-size: 23px;
}
.selectize-control {
  position: relative;
}
.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #333333;
  font-family: inherit;
  font-size: 1rem;;
  line-height: 20px;
  -webkit-font-smoothing: inherit;
}
.selectize-input,
.selectize-control.single .selectize-input.input-active {
  background: #ffffff;
  cursor: text;
  display: inline-block;
  padding: 0px 0px;
}
.selectize-input {
    border-bottom: 1px solid #ced4da;
    padding: 8px 0px 9px 0;

    display: inline-block;
    width: 100%;
    max-height: 3.262rem;
    /*
    overflow: hidden;
    position: relative;
    */
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.selectize-control.multi .selectize-input.has-items {
  padding: 5px 12px 2px;
}
.selectize-input.full {
    padding: 8px 0px 9px 0px;
}

.selectize-control.single .selectize-input.not-full,
.selectize-control.single .selectize-input.not-full.input-active {
    padding: 6px 0px 11px 0px;
}

.selectize-input.disabled,
.selectize-input.disabled * {
  cursor: default !important;
}
.selectize-input.focus {
  border-bottom: 1px solid #2170f2;
    box-shadow: 0 1px 0 0 #2170f2;
}
.selectize-input.dropdown-active {

}
.selectize-input > * {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.selectize-control.multi .selectize-input > div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 1px 3px;
  background: #efefef;
  color: #333333;
  border: 0 solid rgba(0, 0, 0, 0);
}
.selectize-control.multi .selectize-input > div.active {
  background: #428bca;
  color: #ffffff;
  border: 0 solid rgba(0, 0, 0, 0);
}
.selectize-control.multi .selectize-input.disabled > div,
.selectize-control.multi .selectize-input.disabled > div.active {
  color: #808080;
  background: #ffffff;
  border: 0 solid rgba(77, 77, 77, 0);
}
.selectize-input > input {
    display: inline-block !important;
    padding: 0 !important;
    min-height: 0 !important;
    max-height: none !important;
    max-width: 100% !important;
    margin: 0 !important;
    text-indent: 0 !important;
    border: 0 none !important;
    background: none !important;
    line-height: inherit !important;
    -webkit-user-select: auto !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: none !important;
    transition: none !important;
}
.selectize-input > input::-ms-clear {
  display: none;
}
.selectize-input > input:focus {
  outline: none !important;
}
.selectize-input::after {
  content: ' ';
  display: block;
  clear: left;
}
.selectize-input.dropdown-active::before {
  content: ' ';
  display: block;
  position: absolute;
  background: #ffffff;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}
.selectize-dropdown {
  position: absolute;
  z-index: 10;
  border: 1px solid #d0d0d0;
  font-size: 0.9rem;
  background: #ffffff;
  margin: -1px 0 0 0;
  border-top: 0 none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.selectize-dropdown [data-selectable] .highlight {
  background: rgba(255, 237, 40, 0.4);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.selectize-dropdown [data-selectable],
.selectize-dropdown .optgroup-header {
  padding: 8px 8px;
}
.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.selectize-dropdown .optgroup-header {
  color: #777777;
  background: #ffffff;
  cursor: default;
}
.selectize-dropdown .active {
  background-color: #f5f5f5;
  color: #262626;
}
.selectize-dropdown .active.create {
  color: #262626;
}
.selectize-dropdown .create {
  color: rgba(51, 51, 51, 0.5);
}
.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  font-family: 'Open Sans', sans-serif;
}
.selectize-min-scroll .selectize-dropdown-content {
    max-height: 388px;
}
.selectize-control.single .selectize-input {
  cursor: pointer;
  height: auto;
  padding: 6px 0px 7px 0px;
}

.selectize-control.single .selectize-input input {
  position: relative!important;
  cursor: pointer;
  height: auto;
  padding: 6px 0px 7px 0px;
}


.selectize-control.single .selectize-input.full {
    padding: 6px 0px 7px 0px;
}

.selectize-control.single .selectize-input.input-active {
  cursor: text;
  padding: 6px 0px 7px 0px;
  top: -3px;
}

.selectize-control.single .selectize-input.input-active input {
  cursor: text;
  padding: .3rem 0 .55rem;

}

.selectize-control.single .selectize-input:after {
    content: '\25bc';
    position: absolute;
    top: 8px;
    right: 4px;
    font-size: 9px;
    color: #495057;
    /*
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 17px;
    margin-top: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #333333 transparent transparent transparent;*/
}
.selectize-control.single .selectize-input.dropdown-active:after {
    /*
  margin-top: -4px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #333333 transparent;
  */
}

.selectize-control .selectize-input.disabled {
  opacity: 0.5;
  background-color: #ffffff;
}
.selectize-dropdown,
.selectize-dropdown.form-control {
  height: auto;
  padding: 0;
  margin: 2px 0 0 0;
  z-index: 1000;
  background: #ffffff;

}
.selectize-dropdown .optgroup-header {
  font-size: 12px;
  line-height: 1.42857143;
}
.selectize-dropdown .optgroup:first-child:before {
  display: none;
}
.selectize-dropdown .optgroup:before {
  content: ' ';
  display: block;
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  margin-left: -12px;
  margin-right: -12px;
}
.selectize-dropdown-content {
  padding: 0;
  line-height: 1.3rem;
}
.selectize-dropdown-header {
  padding: 6px 12px;
}
.selectize-input {
  /*min-height: 34px;*/
}
.selectize-input.dropdown-active {

}
.selectize-input.dropdown-active::before {
  display: none;
}
.selectize-input.focus {
  border-color: #66afe9;
  outline: 0;
}
.has-error .selectize-input {
  border-color: red;
}
.has-error .selectize-input:focus {
  border-color: red;
}
.selectize-control.multi .selectize-input.has-items {
  padding-left: 9px;
  padding-right: 9px;
}
.selectize-control.multi .selectize-input > div {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.form-control.selectize-control {
  padding: 0;
  line-height: normal;
  height: auto;
  border: none;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.selectize-input .item {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.non-md-selectize .form-control.selectize-control {
    margin-bottom: 0;
}

.non-md-selectize .selectize-input .item + input {
    display: inline-block;
    vertical-align: top;
}

.non-md-selectize .selectize-control.single .selectize-input {
    font-size: 0.9rem;
    border: 1px solid #bdbdbd;
    padding: 0.5rem!important;
    border-radius: 0.25rem;
}

.has-error .non-md-selectize .selectize-control.single .selectize-input {
    border-color: red;
}

.non-md-selectize .selectize-control.single .selectize-input input {
    font-size: 0.9rem;
}

.non-md-selectize .selectize-control.single .selectize-input:after {
    top: 8px;
    right: 8px;
}

#main-container .container-fluid.ipn-restrict-width {
  max-width: 1140px;
  margin-left: 8px;
}

#main-container.login-main {
  padding: 0;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
    #main-container.login-main {
        padding: 0 1rem;
    }
}



.login-main h1 {
    font-weight: 500;
    color: #15477d;
}

.pay-anyone-partner-network {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 15px;
}

.pay-anyone-partner-item {
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.pay-anyone-partner-logo {

}

.pay-anyone-partner-logo img {
    max-width: 128px;
    max-height: 24px;
}

.pay-anyone-partner-name {
    font-size: 0.813rem;
}

#main-container.login-register-main {
    padding: 0;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .app-title h1 {
    font-size: 1rem;
  }
}

.page-actions {
   position: fixed;
    top: 0;
    left: 0;
    top: 64px;
    left: 240px;
    z-index: 2;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.page-actions + .container-fluid {
  padding-top: 54px;
}

/* swagger styles start */
.opblock {
  margin-bottom: 8px;
  padding: 6px;
  border-radius: 3px;
}

.opblock .opblock-summary-method {
    font-size: 12px;
    font-weight: 700;
    min-width: 80px;
    padding: 6px 6px;
    text-align: center;
    border-radius: 3px;
    background: #000;
    text-shadow: 0 1px 0 rgba(0,0,0,.1);
    font-family: sans-serif;
    color: #fff;
}

.opblock.opblock-post {
  border: 1px solid #49cc90;
    background: rgba(73,204,144,.1);
}

.opblock.opblock-post .opblock-summary {
    border-color: #49cc90;

}

.opblock.opblock-post .opblock-summary-method {
    background: #49cc90;
    vertical-align: middle;
}

.opblock-summary-path {
  line-height: 26px;
  font-weight: 600;
}

.opblock-summary-description {
  display: inline-block;
  font-size: 12px;
}
/* swagger styles end */

pre, code {
  font-size: 12px;
}

.section-response-container {
  position: absolute;
  right: -398px;
  top: 0;
  width: 368px;
  border:1px solid #ccc;
  padding: 15px 15px;
}

.accordion>.card .card-header {
  height: auto;
  line-height: normal;
  background-color: #fff;
  color: #333;
  margin: 0;
  padding: 0;
  border: none;
}

.accordion .btn.btn-link {
  padding: 0;
  text-align: left;
  text-transform: initial;

}

.code-response-block .accordion .btn.btn-link {
  padding-left: 1rem;
}

.accordion .card {
      -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ccc;
}

.section-response-title {
  font-weight: 600;
  font-size: 14px;
}

.section-response-container .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
    left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
  top: 10px;
}

.section-response-container .arrow::before, .section-response-container .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.timeline-main .stepper.stepper-vertical.timeline.timeline-simple li .step-content.hoverable {
  border: 1px solid #ccc;
}

.stepper-vertical li .step-content p {
  font-size: 12px;
}


a.modal-helper-code:link, a.modal-helper-code:visited {
  padding: 3px;
  font-size: 12px;
  border-radius: 3px 3px;
  position: absolute;
  top: 16px;
    right: 50px;
    color: #424242;
}

a.modal-helper-code:after {
  display: block;
  content: '';
  border-right: 1px solid #000;
  height: 19px;
  position: absolute;
  right: -9px;
  top: 3px;
}

a.modal-helper-code:hover {
  color: #15477d;
  text-decoration: underline;
}

.modal-helper-code .material-icons {
  vertical-align: middle;
  margin-top: -2px;
}

.toggle-code-container h4 + .opblock {
  margin-top: 1rem;
}

.toggle-code-container a.nav-item.nav-link:link,
.toggle-code-container a.nav-item.nav-link:visited {
  color: #2374CC;
  font-size: 12px;
  font-weight: 400;
  border-bottom: none!important;
}

.toggle-code-container p {
  font-size: 12px;
}

.toggle-code-container .tab-content {
  padding: 1rem 1rem;
}

.toggle-code-container .nav-tabs {
  border-bottom: none!important;
}

table.model td {
  font-weight: 400;
  color: #333;
  font-size: 12px;
  padding: 0!important;
}

.prop-type {
  color: #55a;
}

.toggle-code-container table.model p {
  margin: 0!important;
}

.toggle-code-container .tab-pane {
  max-height: 568px;
  overflow: auto;
}

#modal-create-identity {

}

body.modal-open {
  overflow-y: hidden!important;
}

.modal.modal-animated.show .modal-dialog {
   -webkit-transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
  -ms-transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
}

.modal.show .modal-dialog.code-expanded {
  max-width: 1024px;

}

legend {
   font-size: .8rem;
   font-weight: 400;
}

.code-response-block {
  /*
  padding: 1rem;
  border:1px solid #ccc;
  */

}

.form-check-label {
  font-weight: 400;
}

.code-response-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.code-response-block a.nav-item.nav-link:link,
.code-response-block a.nav-item.nav-link:visited {
    color: #2374CC;
    font-size: 12px;
    font-weight: 400;
}

.tab-content.tab-main-code-content {
  border: 1px solid #dee2e6;
  border-radius: 3px;
}

.tab-content.tab-secondary-code-content {
  border: 1px solid #dee2e6;
  border-radius: 3px;
}


.toggle-code-container .code-response-block .tab-pane {
  max-height: 100%;
  overflow-y: hidden;
}

.modal-xxl {
    max-width: 1440px;
}

.modal-full {
    max-width: calc(100% - 2.5rem);
}

.load-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    z-index: 0;
    display: none;
}

.load-overlay.active {
  display: block;
  opacity: 1;
    z-index: 9999;
}

.load-overlay-bg {
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  background-color: #fff;
  opacity: 0.5;
}

.spinner-container {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -23px;
    text-align: center;
    line-height: normal;
    z-index: 2;
}

.ipn-api-spinner {
    -webkit-animation: spinRotator 1.4s linear infinite;
    animation: spinRotator 1.4s linear infinite;
}

.ipn-api-spinner {
    width: 108px;
}


@-webkit-keyframes spinRotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@keyframes spinRotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}
.spinPath {
  stroke: #2374CC;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: spinDash 1.4s ease-in-out infinite;
          animation: spinDash 1.4s ease-in-out infinite;
}

@-webkit-keyframes spinDash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg);
  }
}

.card .md-form label, .md-form label {
  font-weight: 400;
}

.modal-xl {
    /*max-width: 1200px;*/
    max-width: 1026px;
}



table.dataTable.table-sm thead .sorting:after,
table.dataTable.table-sm thead .sorting:before,
table.dataTable.table-sm thead .sorting_asc:after,
table.dataTable.table-sm thead .sorting_asc:before,
table.dataTable.table-sm thead .sorting_asc_disabled:after,
table.dataTable.table-sm thead .sorting_asc_disabled:before,
table.dataTable.table-sm thead .sorting_desc:after,
table.dataTable.table-sm thead .sorting_desc:before,
table.dataTable.table-sm thead .sorting_desc_disabled:after,
table.dataTable.table-sm thead .sorting_desc_disabled:before {
    bottom: 50%;
    margin-bottom: -12px;
}

.btn.btn-export {
  box-shadow: none!important;
  border-radius: 3px!important;
  border:1px solid #dee2e6;
  color: #424242;
  padding: 6px 12px;
}

.btn.btn-export:hover {
  color: #15477d;
}

.btn.btn-export .fas {
  font-size: 1rem;
}

.btn.btn-export:first-child {
  margin-left: 0;
}

tr.shown td.details-control i.fas {
   -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

td.details-control {
    cursor: pointer;

}

td.details-control i.fas {
    -moz-transition: all 250ms linear;
    -webkit-transition: all 250ms linear;
    transition: all 250ms linear;

}


th.no-sort:before,
th.no-sort:after {
  display: none!important;
}


#paymentHistoryTable tr td {
   cursor: pointer;
}

#paymentHistoryTable tr td > * {
  cursor: default!important;
}

#paymentHistoryTable tr td .datatable-expanded table tr td {
 cursor: default!important;
}

tr.shown td {
  background-color: #c3e3ff;
}

td.account-sel-col .form-check-input[type=radio]+label:after,
td.account-sel-col .form-check-input[type=radio]+label:before,
td.account-sel-col label.btn input[type=radio]+label:after,
td.account-sel-col label.btn input[type=radio]+label:before {
  margin-left: 0;
  margin-top: 7px;
}

.table.table-sm td.account-sel-col .form-check-input[type=radio]+label:after,
.table.table-sm td.account-sel-col .form-check-input[type=radio]+label:before,
.table.table-sm td.account-sel-col label.btn input[type=radio]+label:after,
.table.table-sm td.account-sel-col label.btn input[type=radio]+label:before {
    margin-left: 0;
    margin-top: 5px;
}

.theme-container {
  position: fixed;
  right: 0;
  bottom: 50%;
  z-index: 99999999;
  width: 168px;

}

.theme-container.ready {
  display: block;
}

.theme-toggle-btn {
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.theme-content {
  padding: 1rem;
   border: 1px solid #ccc;
}

.md-form textarea.form-control {
  border-radius: 3px!important;
  padding: 0.5rem;
  margin-top: 1.313rem!important;
}

.md-form textarea.form-control.m-0 {
    margin: 0!important;
}

.md-form textarea + label.active {
    font-size: .8rem;
    -webkit-transform: translateY(-180%);
    -ms-transform: translateY(-180%);
    transform: translateY(-180%);
}

.md-form textarea:focus + label.active {
}

.md-form textarea.form-control.bordered-textarea {
    border: 1px solid #ced4da;
    resize: initial;
    box-sizing: border-box;
}

.md-form textarea.bordered-textarea + label {
    left: 0.5rem;
    color: #212529;
}

.md-form textarea.bordered-textarea + label.active {
    font-size: .8rem;
    -webkit-transform: translateY(-21px);
    transform: translateY(-21px);
    left: 0;
}

.md-form textarea.bordered-textarea:focus + label.active {
}


b, strong {
  font-weight: 600;
}

table .btn.btn-primary.custom-vertical-dropdown,
.dropdown-minimal .btn.btn-primary.custom-vertical-dropdown{
  background: none!important;
  color: #333!important;
  box-shadow: none!important;
  padding: 0.5rem 1rem;
  margin: 0 auto;
}

table .btn.btn-primary.custom-vertical-dropdown:focus,
table .btn.btn-primary.custom-vertical-dropdown:active,
.dropdown-minimal .btn.btn-primary.custom-vertical-dropdown:focus,
.dropdown-minimal .btn.btn-primary.custom-vertical-dropdown:active {
  background: none!important;
  color: #333!important;
  box-shadow: none!important;
}

.custom-vertical-dropdown:focus {
    outline: -webkit-focus-ring-color auto 1px!important;
    border-radius: 32px;
}

tbody + thead{
  display: none;
}

.hamburger-menu {
    position: absolute !important;
    left: -16px;
    top: 0px;
    line-height: 32px;
    vertical-align: middle;
    cursor: pointer !important;
}

.hamburger-menu .fa.fa-bars {
    font-size: 2rem;
}

.menu-icon {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
}

.notification-modal{
    position: fixed;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) !important;
    padding: -10px;
}

.notification-checkbox .form-check {
    padding-left: 0;
}

.separator {
    margin: -5px 0px 0px;
}

@media screen and (min-width: 1440px) {
    .hamburger-menu {
        display: none;
    }
}
@media screen and (max-width: 1439px) {
   
    .hide-hamburger-menu {
        display: none;
    }
}


/* declare icons for the side navigation start */

.side-nav.wide .collapsible a {
    padding-left: 50px;
}


a[class*="side-nav-icon"]:before {
    position: absolute;
    left: 18px;
    top: 6px;
    /*font-family: 'Material Icons';*/
    /*font-weight: normal;*/
    /*font-style: normal;*/
    /*font-size: 24px;*/
    /*line-height: 1;*/
    /*letter-spacing: normal;*/
    /*text-transform: none;*/
    /*display: inline-block;*/
    /*white-space: nowrap;*/
    /*word-wrap: normal;*/
    /*direction: ltr;*/
    /*-webkit-font-feature-settings: 'liga';*/
    /*-webkit-font-smoothing: antialiased;*/
}

.side-nav-icon-dashboard:before {
  /*background: transparent url(../images/icon/menu/baseline-dashboard-24px.svg) no-repeat 16px 50%;*/
    content: '\e871';
}

.side-nav-icon-merchant:before {
  /*background: transparent url(../images/icon/menu/baseline-assignment-24px.svg) no-repeat 16px 50%;*/
    content: '\e85d';
}

.side-nav-icon-identity:before {
  /*background: transparent url(../images/icon/menu/baseline-group-24px.svg) no-repeat 16px 50%;*/
    content: '\e7ef';
}

.side-nav-icon-payment:before {
  /*background: transparent url(../images/icon/menu/baseline-credit_card-24px.svg) no-repeat 16px 50%;*/
    content: '\e870';
}

.side-nav-icon-autopay:before {
  /*background: transparent url(../images/icon/menu/baseline-schedule-24px.svg) no-repeat 16px 50%;*/
    content: '\e8b5';
}

.side-nav-icon-ebill:before {
  /*background: transparent url(../images/icon/menu/baseline-email-24px.svg) no-repeat 16px 50%;*/
    content: '\e0be';
}

.side-nav-icon-wallet:before {
    /*background: transparent url(../images/icon/menu/baseline-account_balance_wallet-24px.svg) no-repeat 16px 50%;*/
    content: '\e850';
}

.side-nav-icon-not-supported:before {
    /*background: transparent url(../images/icon/menu/baseline-watch_later-24px.svg) no-repeat 16px 50%;*/
    content: '\e924';
}

.side-nav-icon-log-out:before {
    content: '\e879';
}

.side-nav-icon-tools:before {
  /*background: transparenturl(../images/icon/menu/baseline-build-24px.svg) no-repeat 16px 50%;*/
    content: '\e869';
}

/* declare icons for the side navigation end */

#loader-spinner-root {
    position: relative;
    z-index: 1068;
}

#modal-root {
    position: relative;
    z-index: 1050;
}

.modal.show .modal-dialog {
    transition: all 0.5s;
}

/* some opp styling overwrites start */
/*
.input-group.input-group-minimal input {
    border: 1px solid #bdbdbd;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    background-color: #fff;
    margin-top: 0;
    font-size: .9rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    z-index: 11;
    padding-top: 0;
    padding-bottom: 0;
    height: 36px;
}
*/

.md-form.datepicker-form input[type="text"] {
    /* no parameter to pass a css class in their react MDBDatepicker to the input */
    /* so manual css overwrite */
    text-align: right!important;
}

.btn.btn-primary.btn-minimal {
    background-color: transparent!important;
    color: #2374cc!important;
    box-shadow: none!important;
    padding: 0!important;
    font-size: 1rem;
}

.btn.btn-primary.btn-minimal:hover {
    text-decoration:  underline;
}

.btn-minimal i.material-icons {
    vertical-align: middle;
    padding-bottom: 3px;
}

.btn-primary.btn-minimal:not([disabled]):not(.disabled):active,
.btn-primary.minimal:not([disabled]):not(.disabled).active {
    background-color: transparent!important;
}

.btn.btn-primary.btn-flat {

}



.payment-amount-col, .payment-date-col {
    width: 168px;
}

.pm-icon {
    position: absolute;
    bottom: 0.688rem;
    top: -0.75rem;
    right: 0.5rem;
    display: block;
    padding: 0px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    display: inline-block;
    width: 56px;
    height: 35px;
    background-size: 56px auto!important;
    background: transparent;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    text-indent: -9999px;
}

.pm-highlight.pm-icon {
    width: 48px;
    height: 30px;
    background-size: 48px auto!important;
    top: 0.125rem;
    right: 0;
    bottom: auto;
    z-index: 2;
}

.pm-accepted-cards.pm-icon {
    width: 48px;
    height: 30px;
    background-size: 48px auto!important;
    top: 0.125rem;
    opacity: 0!important;
    right: 0;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.pm-icon.methodVisa {
    background: #fff url(../images/pm/visa_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/visa.svg) no-repeat 0 0;*/
    opacity: 1;
}

.pm-icon.methodVisaDebit {
    background: #fff url(../images/pm/visa_debit_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/visa_debit.svg) no-repeat 0 0;*/
    opacity: 1;
}

.pm-icon.methodMastercard {
    background: #fff url(../images/pm/mastercard_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/mastercard.svg) no-repeat 0 0;*/
    opacity: 1;
}

.pm-icon.methodMastercardDebit {
    background: #fff url(../images/pm/mastercard_debit_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/mastercard.svg) no-repeat 0 0;*/
    opacity: 1;
}

.pm-icon.methodAmex {
    background: #fff url(../images/pm/amex_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/amex.svg) no-repeat 0 0;*/
    opacity: 1;
    border-color: transparent;
}

.pm-icon.methodDiscover {
    background: #fff url(../images/pm/discover_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/discover.svg) no-repeat 0 0;*/
    opacity: 1;
}

.pm-icon.methodDiscoverDebit {
    background: #fff url(../images/pm/discover_debit_56.png) no-repeat 0 0;
    /*background: #fff url(../images/pm/svg/discover.svg) no-repeat 0 0;*/
    opacity: 1;
}

.pm-icon.methodVisaElectron {
    background: transparent url("../images/pm/visa_electron_56.png") no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodDinersCard {
    background: #fff url(../images/pm/diners_56.png) no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodJCB{
    background: #fff url(../images/pm/jcb_56.png) no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodSCB{
    background: #fff url(../images/pm/scb_56.png) no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodVPay {
    background: transparent url("../images/pm/vpay.png") no-repeat 0 0;
    opacity: 1;
    border: none;
    background-size: 28px auto!important;
    border-radius: 0;
    background-position: center center;
}

.pm-icon.methodBancontact {
    background: transparent url("../images/pm/bancontact_56.png") no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodCartesBancaires {
    background: transparent url("../images/pm/cartes_bancaires.png") no-repeat 0 0;
    opacity: 1;
    border: none;
    border-radius: 0;
}

.pm-icon.methodPostePay {
    background: transparent url("../images/pm/postepay.svg") no-repeat 0 0;
    background-size: 58px auto!important;
    background-color: #f7e122;
    background-position: center center;
    opacity: 1;
    border: none;
}

.pm-info.card-type .pm-icon.methodPostePay {
    width: 94px;
    height: 28px;
    background-size: 128px auto!important;
    background-color: transparent;
    border-radius: 0;
}

.pm-icon.methodMaestro {
    background: transparent url("../images/pm/maestro_56.png") no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodUnionPay {
    background: transparent url("../images/pm/unionpay.png") no-repeat 0 0;
    opacity: 1;
    border: none;
    border-radius: 0;
}

.pm-icon.methodCheck {
    background: #fff url(../images/pm/echeck_56.png) no-repeat 0 0;
    opacity: 1;
}

.pm-icon.methodInteracOnline {
    background: #fff url(../images/pm/interac.png) no-repeat 0 0;
    opacity: 1;
    border-color: transparent;
}

.pm-icon.methodPaypal {
    background: #fff url(../images/pm/paypal_56.png) no-repeat 0 0;
    opacity: 1;
}

input[name$="CardNumber"][value=""] ~ .pm-accepted-cards.pm-icon {
    opacity: 1!important;
    right: 0;
}

input[name$="CardNumber"][value=""]~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 3.5rem;
}

input[name$="CardNumber"][value=""] ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 7rem;
}

input[name$="CardNumber"][value=""] ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 10.5rem;
}

input[name$="CardNumber"][value=""] ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 14rem;
}

.pm-cvv-sample {
    position: absolute;
    top: 0.125rem;
    right: 0.5rem;
    width: 48px;
    height: 30px;
    background: transparent url(../images/pm/cvv_grey.png) no-repeat 0 0;
    pointer-events: none;
}

.has-custom-graphic ~ .pm-icon {
    top: 1.250rem;
    right: 1rem;
}

.has-custom-graphic ~ .pm-highlight.pm-icon {
}

.has-custom-graphic ~ .pm-accepted-cards.pm-icon {

}
.has-custom-graphic + .pm-cvv-sample {
    top: 1.250rem;
    right: 1rem;
}

td.has-custom-graphic {
    position: relative;
}

.custom-graphic-icon.text-icon,
td.has-custom-graphic .custom-graphic-icon.text-icon{
    font-size: 1rem;
    color: #495057!important;
}

td.has-custom-graphic input[type="text"],
td.has-custom-graphic input[type="tel"] {
    padding-left: 21px!important;;
    box-sizing: border-box!important;
}

td.has-custom-graphic .custom-graphic-icon {
    position: absolute;
    top: 16px;
    color: #79848f;
    pointer-events: none;
}

td.has-custom-graphic .custom-graphic-icon.icon-left {
    left: 16px;
}

div.has-custom-graphic + .custom-graphic-icon {
    position: absolute;
    top: 23px;
    color: #79848f;
    pointer-events: none;
}

td div.has-custom-graphic + .custom-graphic-icon {
    top: 16px;
}

div.has-custom-graphic + .custom-graphic-icon.icon-left {
    left: 16px;
}

div.has-custom-graphic input[type="text"],
div.has-custom-graphic input[type="tel"] {
    /*padding-left: 21px;*/
    box-sizing: border-box!important;
}

.has-custom-graphic .fa,
.has-custom-graphic .far,
.has-custom-graphic .fas {
    font-size: 16px;
}



input[name="paymentMethod.accountNumber"][value=""] ~ .pm-accepted-cards.pm-icon {
    opacity: 1!important;
    right: 0.5rem;
}


input[name="paymentMethod.accountNumber"][value=""] ~ .pm-accepted-cards.pm-icon {
    opacity: 1!important;
    right: 0.5rem;
}

input[name="paymentMethod.accountNumber"][value=""]~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 4rem;
}

input[name="paymentMethod.accountNumber"][value=""] ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 7.5rem;
}

input[name="paymentMethod.accountNumber"][value=""] ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 11rem;
}

input[name="paymentMethod.accountNumber"][value=""] ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 14.375rem;
}

input[name="paymentMethod.accountNumber"]:not(.is-invalid) ~ .pm-accepted-cards.pm-icon {
    opacity: 1!important;
    right: 0.5rem;
}

input[name="paymentMethod.accountNumber"]:not(.is-invalid) ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 4rem;
}

input[name="paymentMethod.accountNumber"]:not(.is-invalid) ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 7.5rem;
}

input[name="paymentMethod.accountNumber"]:not(.is-invalid) ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 11rem;
}

input[name="paymentMethod.accountNumber"]:not(.is-invalid) ~ .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon + .pm-accepted-cards.pm-icon {
    right: 14.375rem;
}

/*
.custom-date-picker.md-form input[type=text] {
    border: 1px solid #bdbdbd;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    background-color: #fff;
    margin-top: 0;
    font-size: .9rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    z-index: 11;
    padding-top: 0;
    padding-bottom: 0;
    height: 36px;
    padding: 0!important;
}*/

.review-box-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 1.25rem;
    margin-bottom: 1rem;
}

.review-box-container.no-fee .review-box.rpb-amount,
.review-box-container.no-fee .review-box.rpb-fee,
.review-box-container.no-fee .review-box-icon.rpb-plus,
.review-box-container.no-fee .review-box-icon.rpb-equal {
    display: none;
}

.review-box-container.no-fee .review-box.rpb-total-amount {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
}

.review-box-container.no-fee .review-box.rpb-total-amount .review-box-value {
    padding: 1rem;
}

.review-box-container.no-fee .review-box.rpb-total-amount .review-box-heading {
    display: flex;
    margin-right: auto;
    font-size: 1.5rem;
    font-weight: 400;
}

/* ie11 flexbox fix */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .review-box-container.no-fee .review-box.rpb-total-amount {
        flex-direction: row;
        justify-content:    flex-start;
        align-items: stretch;
    }

    .review-box-container.no-fee .review-box.rpb-total-amount .review-box-heading {
        line-height: 63px;
    }

    /* different approach to fix for flexbox grid in a popover for IE10/IE11,
    instead of setting .popover-opp to width 100% */
    .popover-opp .row {
        display: block;
    }

    .popover-opp .popover-body {
        position: relative;
        overflow: hidden;
    }

    .popover-opp .row [class*="col-"] {
        float: left;
    }
}

.review-box {
    border:3px solid #e0e0e0;
    width: 30%;
    border-radius: 0.3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}


.review-box-heading {
    font-size: 1rem;
    padding: 1rem;
    height: 65px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.review-box-value {
    font-size: 2.625rem;
    padding: 0 0 1rem 0;
}

.has-large-value .review-box-value {
    font-size: 1.75rem;
}

.has-text-value .review-box-value,
.has-text-value.has-large-value .review-box-value{
    font-size: 1rem;
}

.review-box-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    width: 5%;
}

.has-large-value .review-box-value {
    font-size: 1.875rem;
}

@media screen and (max-width: 575px) {

    .review-box-container {
        display: block;
        margin-top: 1.25rem;
    }

    .review-box {
        width: 100%;
    }

    .review-box-heading {
        font-size: 0.813rem;
        height: auto;
        padding-bottom: 0;
    }
    .review-box-value {
        font-size: 1.5rem;
    }

    .review-box-icon {
        width: 100%;
        text-align: center;
    }
}

.flex-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 1.250rem 1rem;
}

.flex-footer button {
    margin-top: 0;
    margin-bottom: 0;
}

.flex-footer button:first-child {
    margin-right: auto;
    margin-left: 4px;
}

.flex-footer button:last-child {
    margin-right: 4px;
}

@media screen and (max-width: 575px) {
    .card-actions-custom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important;
        margin-top: 3rem;
    }

    .card-actions-custom button {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .card-actions-custom button ~ button {
        margin-top: 1rem!important;
    }

    .flex-footer {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important;
    }

    .flex-footer button {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .flex-footer button ~ button {
        margin-top: 1rem;
    }
}

.table-receipt .table-component tbody tr {
  background-color: transparent!important;
}

.table-receipt .table-component tbody th,
.table-receipt .table-component tbody td {
  border: none!important;
}

/* some opp styling overwrites end */

/* fix MDBSelect, MDBDatepicker, and MDBInput styling and alignment inconsistency start */
.select-wrapper + label {
    font-weight: 400;
    color: #212529;
    /*
    font-size: 0.8rem;
    top: 7px;
    z-index: 0;*/
}

.select-wrapper + label.active {
    font-size: 0.8rem;
}

.md-form label {
    color: #212529;
}

.md-form .form-control {
    padding: 0.45rem 0 0.55rem 0;
}

/* during npm run build, this plugin generates random css classes and no longer uses MuiFormControl */
/*div[class^="MuiFormControl"],*/
.datepicker-form > div {
    width: 100%;
}

/*div[class^="MuiInputBase"]:before,*/
.datepicker-form > div > div:before {
    border-bottom: none!important;
}

/*input[class^="MuiInputBase-input"],*/
.datepicker-form input {
    padding: 0.65rem 0 0.65rem 0!important;
}

/*label + div[class^="MuiInputBase"],*/
.datepicker-form label + div {
    margin-top: 0;
}

/*label[class^="MuiFormLabel-"],*/
.datepicker-form label {
    color: #212529;
    top: -18px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
}

.form-check.account-select-radio {
    margin: 0!important;
    padding: 0!important;
}

.form-check-label.account-select-label {
    margin-right: 0!important;
}

.display-plain-form {

}

.display-plain-label {
    font-size: .8rem;
    color: #212529;
    cursor: text;
    margin-top: 0px;
}

.display-plain-value {
    display: block;
    padding: .3rem 0 .55rem;
    margin: 0 0 1rem 0;
    min-height: 38px;
}
/* fix MDBSelect, MDBDatepicker, and MDBInput styling and alignment inconsistency end */

.table .thead-light th {
    background-color: #efefef;
    font-weight: 600;
}

.edit-merchant-min-col {
    width: 98px;
}

.edit-merchant-max-col {
    width: 98px;
}

.edit-merchant-pm-fixed-col,
.view-merchant-pm-fixed-col{
    width: 98px;
}

.edit-merchant-pm-percent-col,
.view-merchant-pm-percent-col{
    width: 98px;
}

.edit-merchant-pm-percent-total-col,
.view-merchant-pm-percent-total-col{
    width: 198px;
}

.edit-merchant-name-col input[type="text"] {
    width: 98px;
}

.edit-merchant-desc-col input[type="text"] {
    width: 98px;
}

.edit-merchant-pm-percent-total-col input[type="text"] {
    max-width: 79px;
    display:inline-block;
}

.view-merchant-min-col,
.view-merchant-max-col {
    width: 98px;
}

tr.disable-row td {
    -webkit-opacity: 0.68;
    -moz-box-opacity: 0.68;
    -ms-box-opacity: 0.68;
    -o-box-opacity: 0.68;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=68)";
    filter: alpha(opacity=68);
    opacity: 0.68;
    pointer-events: none!important;
}

td.account-sel-col input[type="checkbox"] + label:before {
    left: 9px;
}

td.account-sel-col input[type="checkbox"]:checked + label:before {
    left: 4px;
}

td.account-sel-col input[type="radio"] + label:before,
td.account-sel-col input[type="radio"] + label:after {
    left: 10px;
}

td.account-sel-col input[type="radio"]:checked + label:before,
td.account-sel-col input[type="radio"]:checked + label:after{
    left: 10px;
}

tr.disable-row td.account-sel-col {
    -webkit-opacity: 1;
    -moz-box-opacity: 1;
    -ms-box-opacity: 1;
    -o-box-opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    pointer-events: auto!important;
}

tr.disable-row.is-payable td {
    -webkit-opacity: 1;
    -moz-box-opacity: 1;
    -ms-box-opacity: 1;
    -o-box-opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    pointer-events: auto!important;
}

tr.disable-row td.payment-type-col,
tr.disable-row td.account-num-col,
tr.disable-row td.amount-due-col,
tr.disable-row td.date-due-col {
    pointer-events: auto;
}

.select-wrapper input.select-dropdown {
    margin-bottom: 0;
}

.card-header.accordion-header {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    padding: .7rem;
    background-color: #e0e0e0;
    line-height: normal;
    height: auto;
}

.card-header.accordion-header {

}

.card-header.accordion-header h5 {
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
}

.card-header.accordion-header h5 i.fa,
.card-header.accordion-header h5 i.fas,
.card-header.accordion-header h5 i.far {
    position: absolute;
    right: 16px;
    top: 13px;
    transition: all 150ms ease-in 0s;
}

.card-header.accordion-header h5 i.fa.rotate-icon,
.card-header.accordion-header h5 i.fas.rotate-icon,
.card-header.accordion-header h5 i.far.rotate-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);

}

.btn.btn-primary.btn-toggle-row {
    background-color: transparent!important;
    color: #333!important;
    box-shadow: none!important;
    padding: 0;
    font-size: 1rem!important;

}

.btn.btn-primary.btn-toggle-row:active {
    background-color: transparent!important;
}

.dropdown-content.select-dropdown {
    overflow-x: hidden;
}

.modal-content .dropdown-content.select-dropdown {
    overflow-x: auto;
    max-height: 12.5rem;
}

#selectSearchInput {
    padding-left: 0.5rem;
}

label[for="selectSearchInput"] {
    margin-top: 0.313rem;

}

.disable-section * {
    -webkit-opacity: 0.85;
    -moz-box-opacity: 0.85;
    -ms-box-opacity: 0.85;
    -o-box-opacity: 0.85;
    opacity: 0.85;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
    filter: alpha(opacity=85);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: not-allowed;
    pointer-events: none;
}

.disable-element * {
    -webkit-opacity: 0.55;
    -moz-box-opacity: 0.55;
    -ms-box-opacity: 0.55;
    -o-box-opacity: 0.55;
    opacity: 0.55;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
    filter: alpha(opacity=85);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: not-allowed;
    pointer-events: none;
}

.text-normalize {
    text-transform: initial!important;
}

.google-map-container {
    border: 1px solid #e0e0e0;
}

.biller-logo-url {
    font-size:  0.8rem;
}

.view-merchant-id-col {
    /*width: 197px;*/
    width: auto;
}

.view-merchant-name-col {
    width: 197px;
}

.view-merchant-issuer-col {
    width: 108px;
}

.view-merchant-class-col {
    width: 168px;
}

.view-merchant-verified-col {
    width: 98px;
}

.view-merchant-active-col {
    width: 197px;
}

.view-merchant-rank-col {
    width: 197px;
}

.view-merchant-rt-col {
    width: 108px;
}

.view-merchant-reversal-col {
    width: 168px;
}

.view-merchant-dst-col {
    width: 98px;
}

.view-merchant-country-col {
    width: 197px;
}

.view-merchant-state-col {
    width: 197px;
}

.view-merchant-authtoken1-col {
    width: 276px;
}

.view-merchant-time-zone-col {
    width: 98px;
}

.view-merchant-code-col {
    width: 197px;
}

.view-merchant-desc-col {
    width: 197px;
}

table td {
    font-weight: 400;
    font-size: 0.813rem;
}

table th {
    font-size: 0.813rem;
}

th.view-merchant-label-col {
    background-color: #efefef;
}

.view-merchant-address-effective-date-col {
    width: 20%;
}

.view-merchant-address-type-col {
    width: 20%;
}

.view-merchant-address-desc-col {
    width: 40%;
}

.view-merchant-address-action-col {
    width: 100px;
}

.view-merchant-prenotes-dmp-col,
.view-merchant-prenotes-cdp-col,
.view-merchant-prenotes-cdv-col,
.view-merchant-prenotes-cdd-col,
.view-merchant-prenotes-cdf-col,
.view-merchant-prenotes-cdn-col,
.view-merchant-prenotes-fbd-col,
.view-merchant-prenotes-fbc-col {
    width: 12.5%
}

.view-merchant-returns-cdr-col,
.view-merchant-returns-cdt-col,
.view-merchant-returns-cda-col,
.view-merchant-returns-cdv-col,
.view-merchant-returns-cdc-col {
    width: 12.5%
}

.view-merchant-returns-cdm-col {
    width: 37.5%;
}

/*
.mask-descriptor-container {
    max-height: 268px;
    overflow-y: auto;
}


.table-fixed-thead thead {
    overflow: auto;
    height: 100px;
}

.table-fixed-thead thead th {
    position: sticky;
    top: 0;
}*/

.table.table-fixed-thead {
    table-layout:fixed;
    width: 708px;

}

.table-fixed-thead thead tr {
    display: block;
    position: relative;
}

.table-fixed-thead tbody {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 149px;
}

.table-fixed-thead tbody tr:first-child td {
    border-top: none;
}

.mask-descriptor-container {
    border-right: 1px solid #e0e0e0;
}

.mask-descriptor-container .table th {
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
    min-width: 354px;
}

.mask-descriptor-container .table td {
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
    min-width: 354px;
}

.mask-descriptor-container .table th:last-child {
    min-width: 354px;
}

.mask-descriptor-container .table td:last-child {
    min-width: 354px;
}

.selectable-row {
    cursor: pointer;
}

.selectable-col {
    width: 38px;
}

.view-merchant-contact-box-item {
    font-size: 0.813rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem 0;
}

.phone-label {
    min-width: 32px;
    display: inline-block;
}

.btn-group {
    position: absolute;
    right: 0;
    top: 0;
}

.btn-transparent {
    background: transparent;
    box-shadow: none;
    padding: 0.625rem;
    color: #fff;
}

.btn-transparent:hover {
    background: transparent!important;
    box-shadow: none!important;
}

hr.hr-thick {
    border-top: 3px solid rgba(0,0,0,.38);
}

.header-action {
    position: absolute;
    top: 0;
    right: 0;
    padding: .7rem;
}

.header-action a {
    font-size: 0.875rem;
}

table.table a {
    color: #007bff;
}

table.table a:hover {
    color: #15477d;
    text-decoration: underline;
}

.add-account-payment-type-col {
    width: 284px;
}

.add-account-account-number-col {
    width: 208px;
}

.add-account-auth-token1-col,
.add-account-auth-token2-col,
.add-account-auth-token3-col {
    width: 108px;
}

.add-account-container {
    border: 1px solid #e0e0e0;
    padding: 1rem;
    margin-bottom: 1.25rem;
}

.account-actions-container {
    border: 1px solid #e0e0e0;
    padding: 0.5rem 1.25rem;
    margin-bottom: 1rem;
}

.table-responsive + .account-actions-container,
table + .account-actions-container,
.table-responsive + .actions-container,
table + .actions-container{
    border-top: none;
}

.actions-container {
    padding: 0.5rem 0;
}

.btn.btn-primary.btn-minimal.btn-table-action {
    font-size: 0.813rem;
}

.table-link-separator:before {
    content: '|';
    display: inline-block;
    margin-left:3px;
    margin-right:3px;
}

.api-info-widget {
    border: 1px solid #e0e0e0;
    background-color: #fff;
    position: fixed;
    top: 64px;
    right: 16px;
    width: 100%;
    max-width: 42px;
    height: 36px;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 16px 16px;
    z-index: 8888;
    -webkit-transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
    -ms-transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: 250ms cubic-bezier(0.42, 0, 0.58, 1);
}

.api-info-widget.active {
    max-width: 500px;
    height: 668px
}

.api-toggle-container {
    position: relative;

}

.api-info-content {
    margin-top: 24px;
    overflow-y: auto;
    height: calc(100% - 36px);
    display: none;
}

.api-info-widget.active .api-info-content {
    display: block;
}

.btn-api-toggle {
    color: #333;
    position: absolute;
    top: 4px;
    right: 2px;
    background-color: #fff;
    border: none;
}

.btn-api-toggle i.material-icons {
    vertical-align: middle;
}

.store-info-content .nav {
    font-size: 12px;
    font-weight: 400;
}

.store-active .store-info-content {
    display: block;
}

.store-info-content {
    display: none;
}

.store-info-content.always-show {
    display: block!important
}

.btn-store-toggle {
    color: #333;
    position: absolute;
    top: 4px;
    right: 38px;
    background-color: #fff;
    border: none;
}

.btn-store-toggle i.material-icons {
    vertical-align: middle;
}

.api-section {
    background-color: #9E9E9E;
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    margin-right: 0.5rem;
    text-align: center;
    line-height: 32px;
    font-weight: 600;
    font-size: 14px;
}

.api-POST {
    font-size: 14px;
    font-weight: 700;
    min-width: 80px;
    padding: 6px 15px;
    text-align: center;
    border-radius: 3px;
    text-shadow: 0 1px 0 rgba(0,0,0,.1);
    font-family: sans-serif;
    color: #fff;
    background: #49cc90;

}

.api-GET {
    font-size: 14px;
    font-weight: 700;
    min-width: 80px;
    padding: 6px 15px;
    text-align: center;
    border-radius: 3px;
    text-shadow: 0 1px 0 rgba(0,0,0,.1);
    font-family: sans-serif;
    color: #fff;
    background: #61affe;
}

.api-desc {
    display: block;
    padding-top: 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.api-success {
    display: block;
    padding-top: 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.api-url {
    font-weight: 400;
    margin-left: 0.5rem;
    vertical-align:middle
}

body.api-open .has-api-section:after {
    display: block;
    /*display: none!important;*/
}

.has-api-section {
    position: relative;
}

.has-api-section:after {
    position: absolute;
    top: -18px;
    right: -18px;
    display: block;
    content: '';
    background-color: #9E9E9E;
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 36px;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid #fff;
}

.has-api-section.api-middle:after {
    top: 50%;
    margin-top: -18px;
}

.has-api-section.api-bottom:after {
    top: auto;
    bottom: -18px;
    margin-top: 0px;
}


.api-section-1:after {
    content: '1';
}

.api-section-2:after {
    content: '2';
}

.api-section-3:after {
    content: '3';
}

.api-section-4:after {
    content: '4';
}

.api-section-5:after {
    content: '5';
}

.api-section-6:after {
    content: '6';
}

.api-section-7:after {
    content: '7';
}

.api-section-8:after {
    content: '8';
}



.cc-dc-title {
    display: block;
    cursor: text;
    height: auto;
    color: #333;
    font-size: .8rem;
    margin-top: -1px;
}

.debit-logos img {
    width: auto;
    height: 28px;
}

.form-check.m-0 {
    margin: 0!important;
}

.card-container {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px 16px;
    min-height: 180px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.32), 1px 2px 5px 0 rgba(0, 0, 0, 0.12);
    max-width: 350px;
}

.card-container.echeck {
    border-radius: 0;
}

.card-container.flat {
    box-shadow: none;
}

.pm-card-chip {
    position: absolute;
    background: url('../images/pm/cardchip.png') no-repeat 0 0;
    width: 36px;
    height: 31px;
    background-size: 36px 31px;
    opacity: 0.5;
    top: 50%;
    margin-top: -48px;
    left: 16px;
}

p.pm-info {
    position: relative;
    margin: 0;
    padding: 0;
    line-height: normal;
}

.pm-info .pm-icon {
    position: relative;
    display: inline-block;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    vertical-align: middle;
    margin: 0;
}

.pm-info .pm-icon.pm-icon-mini {
    width: 32px;
    height: 20px;
    background-size: 32px auto!important;
}

.pm-info.card-nickname, .pm-info.card-expiration, .pm-info.card-pending-token {
    /*font-size: 1rem;*/
    font-size: 0.875rem;
    font-weight: 500;
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
}

.pm-info.card-expiration, .pm-info.card-pending-token{
    right: 0;
    padding-right: 16px;
}

.pm-info.echeck-type {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 4px;
}

.pm-info.card-nickname.card-nickname-blank {
    color: #ccc;
}

.pm-info.card-number {
    position: absolute;

    top: 50%;
    left: 16px;
    margin-top: -16px;
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: 2px;
}

.pm-info.card-type {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.card-sep {
    color: #464a4c;
}


.pm-info.card-exp-label {
    position: absolute;
    top: 60%;
    /*left: 32.5%;*/
    left: auto;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.571rem;
    line-height: 8px;
    margin-top: 2px;
}

.pm-info.card-exp-date {
    position: absolute;
    top: 60%;
    /*left: 42.5%;*/
    left: 68px;
    margin-top: -5px;
    font-size: 1.313rem;
    font-weight: 500;
}

.pm-info.card-name {
    position: absolute;
    bottom: 11px;
    left: 16px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    max-width: 258px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.debit-logos img {
    width: auto;
    height: 28px;
}

.card .card-body .card-container p {
    margin-bottom: 0;
}

.wallet-item.echeck .card {
    background: #ffffff;
    background-image: none;
    border-radius: 0;
}

.pm-info.routing-number {
    position: absolute;
    bottom: 16px;
    left: 16px;
    font-weight: 500;
    font-size: 1rem;
}

.pm-info.bank-account-number {
    position: absolute;
    bottom: 16px;
    left: 128px;
    font-weight: 500;
    font-size: 1rem;
}

.pm-info.pm-account-number {
    position: absolute;
    bottom: 16px;
    left: 128px;
    font-weight: 500;
    font-size: 1rem;
}

.pm-info.bank-name {
    position: absolute;
    bottom: 50px;
    left: 40px;
    font-weight: 500;
    font-size: 0.875rem;
}

.pm-info.pm-type {
    font-weight: 500;
    white-space: nowrap;
    font-size: 0.875rem;
}

.pm-info.account-holder-name {
    font-weight: 500;
    text-transform: uppercase;
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
}

.echeck .pm-info.account-holder-name {
    position: absolute;
    top: 59px;
    top: 74px;
}

.echeck-decoration-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.echeck-decoration-line1 {
    position: absolute;
    width: calc(100% - 96px);
    height: 1px;
    background: #d3d3d3;
    left: 15px;
    top: 78px;
}

.echeck-decoration-line2 {
    position: absolute;
    width: calc(100% - 64px);
    height: 1px;
    background: #d3d3d3;
    left: 15px;
    top: 96px;
}

.echeck-decoration-line3 {
    position: absolute;
    width: calc(42%);
    height: 1px;
    background: #d3d3d3;
    left: 15px;
    top: 134px;
}

.echeck-decoration-line4 {
    position: absolute;
    width: calc(42%);
    height: 1px;
    background: #d3d3d3;
    right: 16px;
    top: 134px;
}

.echeck-decoration-box1 {
    position: absolute;
    border: 1px solid #d3d3d3;
    height: 16px;
    width: 56px;
    top: 63px;
    right: 16px;
}

.echeck-decoration-icon-lock {
    position: absolute;
    left: calc(100% - 44px);
    top: 84px;
}

.echeck-decoration-icon-lock .fa {
    font-size: 0.938rem;
    color: #d3d3d3;
}

.echeck-decoration-icon-bank {
    position: absolute;
    left: 15px;
    top: 106px;
}

.echeck-decoration-icon-bank .fa {
    font-size: 0.938rem;
    color: #d3d3d3;
}
/* last used end */

tr.total-line th,
tr.total-line td {
    border-top: 5px solid #dee2e6;
}

.table .table.table-component {
    background-color: transparent;
}

table.table-component th, table.table-component td,
table.table-component th:hover, table.table-component td:hover {
    border: none;
    padding: 0;
    background-color: transparent;
}

table.table-component tbody tr:hover {
    background-color: transparent;
}

.search-customer-options-row .form-check {
    min-width: 160px;
}

.search-merchants-options-row .form-check {
    min-width: 160px;
}

th.no-sort {
    background-image: none!important;
    cursor: default!important;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #e0e0e0!important;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background-image: none!important;
    padding-right: 30px!important;
}

table.dataTable thead .sorting {
    background-image: none!important;
    padding-right: 30px!important;
}

td.active .rotate-down {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: all 150ms ease-in 0s;
}

/* schedule calendar forecast styles start */
.calendar-forecast-container {
    border: 1px solid #e0e0e0;
    padding: 0.5rem;
    margin-top: 1.5rem;
    min-height: 235px;
}

.calendar-forecast-container.disable-section * {
    -webkit-opacity: 0.50;
    -moz-box-opacity: 0.50;
    -ms-box-opacity: 0.50;
    -o-box-opacity: 0.50;
    opacity: 0.50;
}

.calendar-forecast-container.disable-section:before {
    content: 'Select a start date';
    position: absolute;
    top: 50%;
    left: 0;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    width: 100%;
    padding: 0 2.25rem;
}

.calendar-forecast-container.disable-section.forecast-not-available:before {
    content: 'Forecast not available for this frequency';
}


.calendar-forecast-title {
    font-weight: 600;
    font-size: 0.875rem;
}

.calendar-forecast-item-row {
    display: flex;
    flex-wrap: nowrap;
}

.calendar-forecast-item{
    position: relative;
    flex: 1 1 auto;
    white-space: nowrap;
    text-align: center;
    font-size: 0.813rem;
    min-height: 28px;
    line-height: 28px;
    z-index: 1;
    width: 0;

}

.calendar-forecast-item:last-child {

}

.calendar-forecast-item.calendar-next-payment-date {
    color: #fff;
}

.calendar-next-payment-date:before {
    display: block;
    content: '';
    width: 28px;
    height: 28px;
    left: 50%;
    margin-left: -14px;
    line-height: 28px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    background-color: #3f51b5;
    z-index: -1;
}

.calendar-next-payment-date:before {

}

.calendar-next-payment-date:before {

}
/* schedule calendar forecast styles end */

.modal-backdrop ~ .modal-backdrop {
     z-index: 1051;
 }

.modal ~ .modal {
    z-index: 1052;
}

th a.helper-modal-link:link, th a.helper-modal-link:visited {
    position: absolute;
    margin-top: -2px;
}

.card-actions-custom {
    display: -webkit-flex;
    display: flex;
}

.stupid-mdb {
    position: absolute;
    top: 150px;
    left: 150px;
    z-index: 9999;
}

@media screen and (max-width: 1440px) {
    .app-title {
        padding-left: 74px;
    }

    #main-container .container-fluid.ipn-restrict-width {
        max-width: calc(100% - 17px);
    }
}


/* dashboard + pay anyone merchant/account listing  start */

.card.card-account {
    border: 1px solid #e0e0e0;
    width: 100%;
    margin-bottom: 1rem;
}

.card-account .card-body {
    font-size: 0.813rem;
}


.card-subsection-title {
    position: relative;
    font-weight: 500;
    padding: 0.5rem 0;
}

.card-subsection-title ~ .card-subsection-title {
    margin-top: 1rem;
    border-top: 3px solid #e0e0e0;
}

.dropdown.minimal {
    position: absolute;
    top: 3px;
    right: 0;
}

.dropdown.minimal .btn.btn-primary.custom-vertical-dropdown {

    background: none!important;
    color: #333!important;
    box-shadow: none!important;
    padding: 0.5rem 1rem;
    margin: 0 auto;
}

.calendar-legend-item {
    margin-right: 0.5rem;
}

.calendar-legend-item:last-child {
    margin-right: 0;
}

.calendar-legend-item-color-block {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: top;
}

.calendar-legend-item-label {
    display: inline-block;
    line-height: 16px;
    font-size: 0.813rem;
    vertical-align: top;
}
/* dashboard + pay anyone merchant/account listing  end */


/* manage accounts start */
.manage-account-payment-type-col {
    width: 238px;
}

.manage-account-account-number-col {
    width: 238px;
}

.manage-account-auth-token1-col {
    min-width: 140px;
}

.manage-account-auth-token2-col {
    min-width: 140px;
}

.manage-account-auth-token3-col {
    min-width: 140px;
}
/* manage accounts end */


.btn.btn-helper-modal-link {
    box-shadow: none!important;
    background-color: transparent!important;
    padding: 0;
    margin: 0;
    line-height: normal;
}

.btn.btn-helper-modal-link .material-icons {
    transition: all .2s ease-in-out;
    vertical-align: middle;
    color: #007bff!important;
}

.btn.btn-helper-modal-link:hover .material-icons {
    color: #15477d!important;
}


label.custom-select-label {
    -webkit-transform: translateX(-14px) translateY(8px) scale(0.8);
    transform: translateX(-14px) translateY(8px) scale(0.8);
    color: #212529;
    font-weight: 400;
}

label.custom-input-label {
    -webkit-transform: translateY(-14px) scale(0.8);
    transform: translateY(-14px) scale(0.8);
    color: #212529;
    font-weight: 400;
}

input.custom-input {
    border-radius: 0;
    margin: 0 0 .5rem;
    padding: .3rem 0 .55rem;
    background-image: none;
    background-color: transparent;
    transition: all .3s;
    outline: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    box-sizing: content-box;
    background-color: transparent;
    font-size: 1rem;
    line-height: 1.5;
    height: 23.5px!important;
    font-family: 'Open Sans', sans-serif;
    display: block;
    width: 100%;
}

.login-footer-container {
    margin: 0 auto;
    overflow: hidden
}

.login-footer-content {
    background-color: #fff;
}

.login-footer-container p {
    font-size: 0.813rem;
}

.footer-inner-divider {
    position: relative;
    height: 2.625rem;
    /*
    background: gradient(linear,left top,left bottom,color-stop(0,rgba(0,0,0,.14)),color-stop(.3rem,rgba(0,0,0,.03)),color-stop(100%,red));
    background: -webkit-linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) .3rem,red);
    background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) .3rem,#fff);*/
    background: gradient(linear,left top,left bottom,color-stop(0,rgba(0,0,0,.14)),color-stop(.3rem,rgba(0,0,0,.03)),color-stop(100%,transparent));
    background: -webkit-linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) .3rem,transparent);
    background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) .3rem,transparent);
    z-index: 0;
    zoom: 1;
    margin-bottom: 0;
}

.footer-inner-divider:after {
    display: block;
    width: 100%;
    height: 2.625rem;
    z-index: 1;
    content: '';
    background-color: transparent;
    background: gradient(linear, left top, right top, color-stop(0, #fff), color-stop(50%, rgba(255, 255, 255, 0)), color-stop(100%, #fff));
    background: -webkit-linear-gradient(to right, #fff, rgba(255, 255, 255, 0), #fff);
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0), #fff);
}

/*
p > a.footer-link:first-of-type {
    margin-right: 1em;
}

p a.footer-link:last-of-type {
    margin-left: 1rem;
}*/

.paymentus-footer-logo {
    max-width: 120px;
}

@media screen and (max-width: 575px) {
    .address-item {
        margin-top: 1rem;
    }

    .address-item:first-child {
        margin-top: 0;
    }
}


/* table-responsive start */
/* overwrite bootstrap 4 table-responsive to not do horizontal scrolling as it
    causes rendering/position issues with dropdown menus */
/* on tablet will use horizontal scroll */
/* on phone device, will switch to 2 column style */

.table-responsive {
    overflow: visible!important;
}

@media screen and (max-width: 575px) {

    .table-responsive .table-bordered-simple {
        border-bottom: none;
    }

    .table-responsive table thead {
        display: none;
    }

    .table-responsive table tr {
        border: 1px solid #e0e0e0;
        width: 100%;
        position: relative;
        display: inline-block;
        letter-spacing: normal;
        margin-top: 1.25rem;
        margin-bottom: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }
    .table-responsive table td {
        display: block;
        font-size: 0.750rem;
        padding: 0 !important;
        height: auto !important;
        text-align: left !important;
        white-space: normal !important;
        width: auto!important;
        border-left: none!important;
        border-right: none!important;
    }

    .table-responsive table td:before {
        font-size: 0.750rem;
        padding: 1em 0.5em !important;
        width: 36%;
        background: #f8f8f8;
        letter-spacing: normal;
        word-spacing: normal;
        font-weight: 600;
        content: attr(data-th) "\00a0";
        display: inline-block;
        margin-right: 8px;
        border-right: 1px solid #e0e0e0!important;
        vertical-align: middle;
        background-color: #efefef;
    }

    .table-responsive table td span {
        display: inline-block!important;
        vertical-align: middle!important;
    }

    .table-responsive td .dropdown {
        display: inline-block;
    }

    .table-responsive tr.table-add-row td:before {
        display: none;
    }

    .table-responsive tr td.account-sel-col {
        padding: 0.5rem 0!important;
    }

    .table-responsive tr td.account-sel-col .form-check {
        padding-left: 1rem!important
    }

    .table-responsive tr td.account-sel-col:before {
        display: none;
    }



    .table-responsive tr.table-add-row {
        margin-top: 0;
        margin-bottom: 0;
    }

    .table-responsive tr.table-add-row td {
    }

    .table-responsive tr.table-add-row td button,
    .table-responsive tr.table-add-row td button.btn.btn-primary.btn-minimal {
        display: block;
        width: 100%;
        padding: 0.375rem!important;
    }

    .table-responsive + h4 {
        margin-top: 1.25rem;
    }

    .table-responsive td .md-form {
        display: inline-block;
        min-width: 156px;
    }

    .table-responsive td .md-form input {
        font-size: 0.750rem;
    }


    td.has-custom-graphic .custom-graphic-icon.icon-left {
        left: 36%;
        margin-left: 8px;
        width: 24px;
        text-align: center;
    }

    .table-responsive td.has-custom-graphic .custom-graphic-icon.dollar-icon {
        top: 10px;
        font-size: 0.75rem;
    }
}

/* table-responsive end */

/* form modifications start */
@media screen and (max-width: 575px) {
    .form-check-input[type="radio"]:not(:checked) + label,
    .form-check-input[type="radio"]:checked + label,
    label.btn input[type="radio"]:not(:checked) + label,
    label.btn input[type="radio"]:checked + label {
        font-size: 0.813rem;
    }

    .form-inline .form-check {
        margin-top: 0!important;
        margin-bottom: 0!important;
    }

    .form-inline .form-check:last-child {
        margin-bottom: 1.25rem!important;
    }
}
/* form modifications end */


/* modal overwrites start  */
/*@media screen and (max-width: 1024px) {*/
    /*.modal-dialog {*/
        /*max-width: calc(100% - 48px)!important;*/
    /*}*/
/*}*/

@media screen and (max-width: 575px) {
    .modal-dialog {
        max-width: calc(100% - 1rem)!important;
        margin: 0.5rem auto!important;
    }
}

/* modal overwrites end */

/* tabs overwrites start */
.nav-tabs .nav-link,
 .nav-tabs .nav-item.show .nav-link,
 .nav-tabs .nav-link.active {
     font-weight: 400;
     color: #006ee5;
}

.nav-tabs .nav-link {
    color: #424242;
}

.nav-tabs .nav-link.active {
    font-weight: 400;
    color: #006ee5;
}

@media screen and (max-width: 575px) {
    .nav-tabs .nav-link,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        font-size: 0.813rem;
        padding: 0.5rem 0.5rem;
    }
}
/* tabs overwrites end */


/* general responsiveness start */
@media screen and (max-width: 575px) {
    .api-info-widget {
        display: none!important;
    }
}
/* general responsiveness end */

/* pickadate v5 alpha 2 css temporary support start  */
input.pickadate--element, button.pickadate--element {

}
/* pickadate v5 alpha 2 css temporary support end  */

.account-item-select-col {

}

.account-item-payment-type-col {
    width: 198px;
}

.account-item-account-number-col {
    width: 198px;
}

[class^="account-item-auth-token"] {
    width: 144px;
}

[class^="account-item-auth-token"] + [class^="account-item-auth-token"] {

}

.num-token-1 [class^="account-item-auth-token"] {
    width: 432px;
}

.num-token-2 .account-item-auth-token1-col {
    width: 144px;
}

.num-token-2 .account-item-auth-token2-col {
    width: 288px;
}

.num-token-3 [class^="account-item-auth-token"] {
    width: 144px;
}

.payment-item-payment-type-col {

}

.payment-item-account-number-col {

}

.payment-item-amount-due-col {

}

.payment-item-due-date-col {

}

.payment-item-payment-amount-col {
    width: 138px;
}

.payment-item-payment-date-col {
    width: 168px;
}

.simple-divider {
    display:inline-block;
    color: #333;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.input-authtoken {
    max-width: 128px;
}



/* Wallet start */
.wallet-item-container {
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    padding: 1.25rem;
}


.wallet-item .pm-icon {
    border: none;
}

.wallet-item .card-info {

}

.wallet-item .card-info .card-number {

}

.wallet-item .card-info .card-expiration {

}

/* Wallet end */

.note-box {
    border: 1px solid #e0e0e0;
    padding: 1.25rem 1.25rem;
    margin-bottom: 1rem;
}

.modal .note-box {
    font-size: 0.813rem;
    max-height: 118px;
    overflow-y: auto;
}

.note-box p:last-child {
    margin-bottom: 0;
}

.note-box ul:last-child,
.note-box ol:last-child{
    margin-bottom: 0;
}

.allow-scroll {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
}

td.view-details-value-col .allow-scroll {
    width: 340px;
}

.view-details-label-col{
    font-weight: 500!important;
}


/* start normalize edit tables that show current 'form' values and 'altered' form values */
/* edit schedule review start */
.table-comparison-normalize {

}

.table-comparison-normalize .review-frequency-col {
    width: 184px;
}

.table-comparison-normalize .review-schedule-day-col {
    width: 130px;
}

.table-comparison-normalize .review-effective-date-col {
    width: 110px;
}

.table-comparison-normalize .review-expiry-date-col {
    width: 110px;
}

.table-comparison-normalize .review-schedule-amount-col {
    width: 120px;
}

.table-comparison-normalize .review-payment-date-col {
    width: 150px;
}

.table-comparison-normalize .review-payment-method {
    width: 150px;
}

/* edit schedule review end */

/* end normalize  */


.form-check.my-3.mt-0 {
    margin-top: 0!important;
}

.form-check.my-3.mb-0 {
    margin-bottom: 0!important;
}

.form-check.my-3.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important;
}
.form-check-input[type="checkbox"] + label.mr-5.mr-0,
.form-check-input[type="radio"] + label.mr-5.mr-0{
    margin-right: 0!important;
}

.view-merchant-aka-effective-date-col {
    width: 136px;
}

.highlight-change {
    color: #33691E!important;
    font-weight: 500!important;
}


.form-check-input[type="checkbox"] + label {
    font-size: 0.813rem;
    line-height: normal;
}

.form-check-input[type="checkbox"] + label:before,
.form-check-input[type="checkbox"]:not(.filled-in) + label:after {
    margin-top: 1px;
}


.switch label {
    font-size: 0.813rem;
}

.switch input:focus + .lever:after {
    outline: -webkit-focus-ring-color auto 1px!important;
}

.btn.btn-primary.btn-minimal.btn-remove-payment-type {
    top: 23px;
    color: #212529!important;
}

.form-check.exceptionMask {
    top: 11px;
}

button.btn-popover-help {
    background-color: transparent!important;
    color: #212529!important;
    box-shadow: none!important;
    padding: 0!important;
    font-size: 1rem;
    border: none!important;
}

input.has-popover + label + button.btn-popover-help {
    position: absolute;
    top: 0;
    right: 0;
}

.md-form .form-control:disabled, .md-form .form-control[readonly] {
    border-color: #fff!important;
}


[class^="edit-table-row-"] td {
    border-top: none!important;
}

.ebill-iframe {
    min-height: 666px;
    height: 100%;
    width: 100%;
}

.standalone-language-container {
    position: absolute;
    top: 12px;
    right: 8px;
}

.standalone-language-container .nav-link.current-language {
    font-size: .938rem;
}

.current-issuer {
}

.dropdown-item.current-language {
    position: relative;
    top: 1px;
    font-size: .938rem;
    font-weight: 400;
}

.login-main .current-language {
    top: 0;
}

.language-dropdown a:link,
.language-dropdown a:visited {
    color: #333;
}

.language-dropdown .material-icons {
    vertical-align: top;
    margin-left: 8px;
}



.dropdown-item.current-language {
    color: #15477d;
    font-weight: 600;
}

/* allow up to 2 bootstrap modals to stack */
.modal-container:nth-of-type(even) .modal {
    z-index: 1052 !important;
}
.modal-container:nth-of-type(even) .modal-backdrop.show {
    z-index: 1051 !important;
}

@media screen and (max-width: 575px) {
    .user-name {
        display: none;
    }

    .newline br {
        display: none;
    }

    .table-responsive table td span.newline,
    .newline {
        display: block!important;
    }
}

/* let the print css fun begin */
@media print {
    .main-nav {
        display: none!important;
    }
    .fixed-sn main#main-container {
        padding: 1rem!important;
    }

    .api-info-widget {
        display: none!important;
    }

    body.modal-open .modal-backdrop {
        background-color: #fff;

    }

    body.modal-open .modal-backdrop.show {
        opacity: 1;
    }

    body.modal-open .modal-dialog .modal-content .modal-header {
        border: none!important;
    }

    body.modal-open .modal-dialog {
        max-width: 100%!important;
        margin: 0!important;
    }

    body.modal-open .modal-footer {
        display: none!important;
    }

}
/* defeated by print css end */

.or-line {
    display: table;
    white-space: nowrap;
    margin: 0px auto;
    font-size: 0.875rem;
    font-weight: 500!important;
    line-height: 21px;
}

.or-line:before{
    border-top: 1px solid #ccc;
    content: '';
    display: table-cell;
    position: relative;
    top: 11px;
    width:50%;
    right: 1.5%;
}

.or-line:after {
    border-top: 1px solid #ccc;
    content: '';
    display: table-cell;
    position: relative;
    top: 12px;
    width: 50%;
    left: 1.5%;
}

.other-signin {
    position: relative;
    overflow: hidden;
    padding-top: 8px;
}

.os-signin {
    display: block;
    width: 100%;
    background-color: #FFF;
    border: 1px solid #ccc;
    color: #222;
    border-radius: .3125rem;
    text-align: center;
    text-decoration: none;
    font-weight: 400;
    padding: 0.84rem 2.14rem;
    font-size: 0.81rem;
    line-height: 1.15;

}

.os-left {
    position:relative;
    overflow: hidden;
    float: left;
    width: 50%;
    padding-right: 8px;
}

.os-right {
    position:relative;
    overflow: hidden;
    float: left;
    width: 50%;
    padding-left: 8px;
}

.os-icon {
    vertical-align: middle;
    margin-top: 3px;
    position: relative;
    display: inline-block;
    margin-right: 3px;
}

/* accessibility options start  */
.dropdown-menu.accessibility-dropdown-menu {
    min-width: 368px;
}
.accessibility-option-container {
    display: block;
    min-width: 368px;
    width: 368px;
}

.accessibility-option {
    min-width: 68px;
    min-height: 68px;
    border: 1px solid #e2e2e2;
}

/* accessibility options end  */

/* fix mdb box shadow bleeding to the right when inputs are focused start */
.md-form input:not([type]):focus:not([readonly]),
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    box-shadow: inset 0px -1px 0px 0px #4285f4;
}
/* fix mdb box shadow bleeding to the right when inputs are focused end */

.form-group.browser-default-group {
    position: relative;
    margin-bottom: 2rem;
}

:not(.selectize) label.browser-default-label {
    position: absolute;
    top: 18px; /* previous value: 0.6rem*/
    left: 0px;
    -webkit-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    transition: .2s ease-out;
    cursor: text;
    color: #333;
    font-size: .8rem;
    -webkit-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    transform: translateY(-140%);
    height: auto;
}

select.browser-default {
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0 0 .5rem;
    /*padding: .3rem 0 .55rem;*/
    padding: 0.45rem 0 0.55rem 0;
    background-image: none;
    background-color: transparent;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: transparent;
    font-size: 1rem;
    line-height: 1.5;
    /*height: 23.5px!important;*/
    height: auto;
    font-family: 'Open Sans', sans-serif;
}

.md-form.md-browser-default-form:after {
    content: '▼';
    display: block;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 11px;
}

select.browser-default:focus,
select.browser-default:active{
    -webkit-box-shadow: 0 1px 0 0 #2170f2!important;
    box-shadow: 0 1px 0 0 #2170f2!important;
    border-bottom: 1px solid #2170f2!important;
}

select.browser-default option {
    font-family: 'Cabin', sans-serif;
}

select.browser-default.is-invalid {
    border-bottom: 1px solid red!important;
    box-shadow: 0 1px 0 0 red!important;
}

select.browser-default.is-invalid:focus,
select.browser-default.is-invalid:active{
    -webkit-box-shadow: 0 1px 0 0 #2170f2!important;
    box-shadow: 0 1px 0 0 #2170f2!important;
    border-bottom: 1px solid #2170f2!important;
}

select.browser-default.is-invalid + label {
    color:#dc3545;
}

select.browser-default:focus + label {
    color: #4285f4;
}

input.form-control[readonly] + label {
    -webkit-transform: translateY(-14px) scale(0.8);
    transform: translateY(-14px) scale(0.8);
}

.display-label {
    position: absolute;
    font-size: .8rem;
    color: #757575;
    cursor: text;
    top: .65rem;
    -webkit-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    transform: translateY(-140%);
}

.display-plaintext {
    display: block;
    padding: .3rem 0 .55rem;
    margin: 0 0 .5rem;
    min-height: 38px;
}


.btn-demo-popover {
    position: absolute!important;
    top: -24px;
    right: -18px;
}

.btn-demo-display-mode {
    position: absolute!important;
    top: -24px;
    right: -18px;
}

.btn-demo-popover ~ .btn-demo-display-mode {
    right: 36px;
}


.popover.demo-popover {
    max-width: 1920px;
    background-color: #ebf3fb;
    width: 100%;
}

.popover.demo-popover .popover-header {
    font-weight: 500;
    color: #3b4151;
    background: #ebf3fb;
    padding: 1rem .75rem;
}

.popover.demo-popover .popover-body {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 420px;
    color: #333;
}

.popover.demo-popover code {
    font-size: 12px;
    min-height: 60px;
    margin: 0;
    padding: 10px;
    resize: none;
    border-radius: 4px;
    background: #41444e;
    font-family: monospace;
    font-weight: 600;
    color: #fff;
    white-space: normal;
    width: 100%;
    display: block;
}

.popover.demo-popover pre {
    font-size: 12px;
    min-height: 60px;
    margin: 0;
    padding: 10px;
    resize: none;
    border-radius: 4px;
    background: #41444e;
    font-family: monospace;
    font-weight: 600;
    color: #fff;
    width: 100%;
    display: block;
}

.show.popover.demo-popover {
    z-index: 888;
}

.fake-code-block {
    font-size: 12px;
    min-height: 100px;
    margin: 0;
    padding: 10px;
    resize: none;
    border-radius: 4px;
    background: #41444e;
    font-family: monospace;
    font-weight: 600;
    color: #fff;
    white-space: normal;
    width: 100%;
    display: block;
}

.popover.demo-popover .popover-body label {
    color: #333;
}

.demo-api-highlight {
    color: #3eaeff;
    /* #ffbb8d */
}

.demo-api-highlight-value {
    color: #ff8e3e;
}

.demo-api-block {
    font-size: 14px;
    font-weight: 700;
    min-width: 80px;
    padding: 6px 15px;
    text-align: center;
    border-radius: 3px;
    background: #000;
    text-shadow: 0 1px 0 rgba(0,0,0,.1);
    font-family: sans-serif;
    color: #fff;
    text-transform: uppercase;
    margin-right: 8px;
}

.demo-api-block.get {
    background: #61affe;
}

.demo-api-block.post {
    background: #49cc90;
}

.demo-api-block.put {
    background: #fca130;
}

.demo-api-block.delete {
    background: #f93e3e;
}

.demo-api-desc {
    font-weight: 400;
    margin-left: 1rem;
    font-size: 12px;
}

.popover.demo-popover .popover-body .popover.demo-api-popover .popover-body {
    font-weight: 400;
    font-size: 14px;
}

.popover.demo-popover input[type=number]::-webkit-inner-spin-button,
.popover.demo-popover input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.btn-primary.btn-toggle-api {
    box-shadow: none!important;
    text-transform: capitalize;
    padding: 8px 16px!important;
    margin: 0!important;
}

.btn-default.btn-demo-api-popover {
    background-color: transparent!important;
    box-shadow: none!important;
    color: #333!important;
    display: inline-block!important;
    padding: 0!important;
    margin: 0!important;
    line-height: normal!important;
}

.btn-primary.btn-login-gsuite {
    /*background: #2374CC url('../images/logo/gsuite_logo_white.svg') repeat auto auto!important;*/
    padding: 12px;
}

.btn-primary.btn-login-gsuite img {
    width: auto;
    max-height: 12px;
}

ul.login-register-benefits {
}

ul.login-register-benefits li {
    font-size: 0.875rem;
    text-align: left;
    margin: 0;
}

/*

ul.login-register-benefits li:before {
    content: '\02022';
    display: inline-block;
    font-size: 1rem;
    margin: 0 0.5rem;
}

ul.login-register-benefits li:first-child:before {
    display: none;
}*/

.login-register {
    font-size: 0.875rem;
}

.form-overlay {
    position: absolute;
    top: 0;
    left: 15px;
    width: 100%;
    width: calc(100% - 30px);
    height: 100%;
    background-color: rgba(255,255,255,0.68);
    z-index: 100;
    /*max-width: 400px;*/
    height: calc(100% - 1.063rem);
}

.form-overlay-text {
    font-weight: 400;
    font-size: 0.938rem;
    position: relative;
    top: 50%;
    margin-top: -22px;
    line-height: normal;
}

.form-overlay-text p {
    background-color: #fff;
    display: inline-block;
    padding: 0.188rem 0.375rem;
    border: 1px solid #dedede;
    border-radius: 3px;

}

.sample-bill-container {
    position: relative;
}

.sample-bill {
    position: relative;
    overflow: hidden;
}

.sample-bill.sample-bill-full-size {
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 368px;
}

.sample-bill.sample-bill-full-size img {
    max-width: initial;
}

.sample-bill-instructions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.88);
    font-weight: 400;
    z-index: 1;
}

.sample-bill-mobile-container {
    border-bottom: 1px solid #949494;
    margin-bottom: 30px;
}

.sample-bill-mobile-container h2 {
    font-size: 1.125rem;
    font-weight: 300;
}

@media screen and (max-width: 960px) {
    .sample-bill {
        max-height: 268px;
    }
}

.demo-quick-nav-link:link, .demo-quick-nav-link:visited {
    padding: 0.5rem;
    margin-left: 0;
    font-size: 0.9rem;
    font-weight: 400;
    color: #212529;
    display: block;
    text-decoration: none;

}

.demo-quick-nav-link:hover {
    background-color: #efefef!important;
    box-shadow: none!important;
    border-radius: 0!important;
    color: #333!important;
    text-decoration: none;
}

.ipn-main .md-form .form-control.biller-search-input {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: 1.75rem 0.75rem 1.75rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing:border-box;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid #fff;

    padding: 1.75rem 1rem 1.75rem 3.188rem!important;
}

.ipn-main .biller-search-input-label {
    height: 0!important;
    width: 0!important;
    overflow: hidden;
    position: absolute;
}


.ipn-main .md-form .form-control.biller-search-input:focus {
    border: 1px inset #4285f4!important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)!important;
}

.search-bar-container {
    position: relative;
}

.search-bar-button-container {
    position: absolute;
    display: flex;
    right: 0;
    top: 1px;
}

.ipn-main .search-bar-button button.btn.btn-primary.btn-minimal {
    height: 56px;
    width: 56px;
    margin: 0;
    /*border-left: 1px solid #e5e5e5;*/
    border-radius: 0!important;
    margin-left: 1px;
    color: #ccc!important;
}
.ipn-main .search-bar-button button.btn.btn-primary.btn-minimal.has-search-value {
    color: #8e8e8e!important;
}


.ipn-main .search-bar-button button.btn.btn-primary.btn-minimal:focus {
    outline: -webkit-focus-ring-color auto 1px!important;
    color: #2374cc!important;
}

.ipn-main .search-bar-button.search-options-button button i.material-icons {
    font-size: 38px;
}


.ipn-main .search-bar-button.search-button {
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 1;
}

.ipn-main .search-bar-button.search-button button.btn.btn-primary.btn-minimal {
    color: #8e8e8e!important;
}

.ipn-main .search-bar-button.search-button button.btn.btn-primary.btn-minimal:focus {
    outline: none!important
}
#amazon-pay-button {
    display:inline-block;
    cursor: pointer;
}

#amazon-pay-button:focus,
.amazonpay-button-inner-image:focus {
    outline: -webkit-focus-ring-color auto 1px!important;
}

#amazonPayWalletWidgetDiv {
    height: 240px;
    width: 100%;
}

#amazonPayWalletReadOnlyWidgetDiv {
    height: 160px;
    width: 100%;
    margin-top: 24px;
    margin-top: 1.5rem;
}

#amazonPayConsentWidgetDiv {
    height: 120px;
    width: 100%;
    margin-top: 24px;
    margin-top: 1.5rem;
}



.wallet-add-pm-container {
    position: relative;
}

.wallet-add-pm-menu {
    padding-right: 40px;
}

.wallet-card-container {
    /*padding-left: 40px;*/
    padding-top: 30px;
}

.wallet-card-container .card-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 8;
}

.wallet-card-container .card-actions .btn.btn-text-link {
    /*font-size: 13px!important;*/
}

.wallet-card-container .card-paypal .card-actions .btn.btn-text-link,
.wallet-card-container .card-paypal-credit .card-actions .btn.btn-text-link {
    color: #fff;
}

.wallet-card-container .card-paypal-credit .card-actions .btn.btn-text-link:focus,
.wallet-card-container .card-paypal-credit .card-actions .btn.btn-text-link:hover {
    text-decoration: underline;
}

.wallet-card-container .card-actions .btn-text-link:not(:first-child)::before {
    position: absolute;
    left: -3px;
    text-align: left;
    content: '|';
    display: inline-block;
    font-size: 12px;
    vertical-align: top;
    color: #989898;
}

.btn.btn-add-pm {
    max-width: 200px;
    width: 100%;
    margin: 0;
    padding: 0.84rem 1rem;
}

/*
.wallet-section .btn.btn-add-pm,
.wallet-section #paypal-button,
.wallet-section #amazon-pay-button {
    display: inline-block;
    width: 200px;

}*/

.paypal-button {
    max-width: 200px;
}

.switch label .lever {
    background-color: #bdbdbd;
    margin: 0;
}

.switch label input[type="checkbox"]:checked + .lever {
    background-color: #b5d152;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    background-color: #6fb148;
    box-shadow: none;
}


.card-account-summary-container {
    border-right: 1px solid #dcdcdc;
    padding-right: 20px;
}

.card-account-summary-container .dropdown.minimal {
    right: 1.5rem;
    top: -3px;
}

.card-style-section {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}

.card-style-section .btn-demo-popover {
    right: -8px;
}

.card-style-section.v2 .card-account-summary-container {
    border: none;
    padding-right: 0;
}

.card-style-section.v2 .card-account-summary-container .dropdown.minimal {
    right: 0.5rem;
}

.card-account-sub-section table th, .card-account-sub-section table td {
    padding: 0.313rem 0;
    border-top: none;
    border-bottom: 1px solid #dee2e6;
}

.card-account-merchant-name h2{
    padding-right: 1.5rem;
    font-weight: 500!important;
}


.card-account-summary {
    font-size: 13px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
}

.demo-api-call-container {
    margin-bottom: 1rem;
}


.btn:not(.btn-floating):not(.sr-only).btn-toggle-demo-api-call {
    border-radius: 65px!important;
    border: none!important;
    box-shadow: none!important;
    text-transform: initial!important;
    background-color: #fff!important;
    padding: 0.5rem 1rem!important;

    color: #006ee5;


}

.btn:not(.btn-floating):not(.sr-only).btn-toggle-demo-api-call:hover {
    /*background-color: #007bff!important;*/
    /*background-color: #0056b2!important;*/ /* WCAG AAA */
    background-color: #dcdcdc!important;
    color: #006ee5;
}

.zero-height{
    height: 0px;
}

@keyframes buttonLoadSpin {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); }
}


/*.button-load-container {*/
    /*position: relative;*/
    /*height: 45px;*/
    /*max-width: 300px;*/
    /*width: auto;*/
    /*display: inline-block;*/
    /*overflow: hidden;*/
/*}*/

.wallet-add-pm-menu .button-load-container {
    display: block;
    margin-bottom: 1rem;
}

/*.button-load-container.paypal-load-container,*/
/*.button-load-container.paypal-credit-load-container{*/
    /*height: 35px;*/
/*}*/

.loaded .button-load-spinner {
    display: none;
}

.button-load-container * {
    /*pointer-events: none;*/
}

.button-load-container.loaded * {
    pointer-events: initial;
}

.button-load-spinner {
    color: #000;
    text-indent: -9999px;
    position: absolute;
    /*top: 0;*/
    top: 18px;
    /*width: 100%;*/
    width: 200px;
    background-color: #fff;
    height: 45px;
    z-index: 1111;
    pointer-events: none;
    opacity: 0.8;
}



.button-load-spinner::after {
    content: '';
    animation: buttonLoadSpin 1080ms linear infinite, buttonLoadScale 180ms;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #2380be;
    border-top-color: #cbcbca;
    border-right-color: #cbcbca;
    display: block;
    opacity: 1;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    z-index: 2;
}

.paypal-load-container .button-load-spinner,
.paypal-credit-load-container .button-load-spinner{
    height: 35px;
}


th.rpps-effective-date-col,
td.rpps-effective-date-col {
    width: 168px!important;
}

th.rpps-action-col,
td.rpps-action-col,
th.datatable-action-col,
td.datatable-action-col{
    min-width: 98px !important;
}

th.revision-num-col,
td.revision-num-col {
    width: 98px;
}

.w-15 {
    width: 15%!important;
}

.w-85 {
    width: 85%!important;
}

.seamless .card-header,
.seamless .card-body {
    padding: 0!important;
}

.flow-mode-container {
    position: fixed;
    top: 10px;
    left: 0;
    z-index: 9999;
    padding: 0.5rem;
}

th.control, td.control {
    padding-right: 0!important;
}

table.dtr-column.collapsed .selectable-row td:first-child:before {
    margin-top: -1px!important;
    display: inline-block;
    vertical-align: middle;
    font-family: 'Material Icons'!important;
    content: 'keyboard_arrow_down'!important;
    font-size: 1.5rem;
    width: auto!important;
    height: auto!important;
    border: none!important;
    box-shadow: none!important;
    background-color: transparent!important;
    color: #000!important;
    -webkit-transform: rotate(-90deg)!important;
    transform: rotate(-90deg)!important;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;

/*-webkit-transform: rotate(0deg)!important;*/
/*transform: rotate(0deg)!important;*/

}

table.dtr-column.collapsed .parent.selectable-row td:first-child:before {
    -webkit-transform: rotate(0deg)!important;
    transform: rotate(0deg)!important;
    color: #006ee5!important;

}

table.table-hover tbody tr:hover {
    background-color: #fff!important;
    -webkit-box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,0.50);
    -moz-box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,0.50);
    box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,0.50);
    transition: none!important;

}

table.dtr-column.collapsed tr.child td {
    border-top: none!important;
}

.hidden-export {
    display: none!important
}

.datatable-blend-container.blend-absolute, .datatable-blend-container.dispute-details {
    position: absolute;
    display: table;
    top: 4px;
    z-index: 1;
}

div#package-list-table-datatable_filter input {
    margin: 0;
}

.CalendarMonth_caption{
    font-weight: 600;
}

/* style fix for tealium cookie consent form */
.privacy_prompt input[type="checkbox"].toggle {
    position: relative !important;
}

/*style to remove box like structure appearing on clicking button links*/
.btn>div.Ripple.is-reppling{
    animation: none !important;
}