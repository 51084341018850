@import 'variables';

.ups-style table.dataTable {
    border-color: $ups-neutral-gray-1;
}

.ups-style div.dataTables_wrapper .table thead th {
    background-color: $ups-neutral-gray-2;
}

.ups-style div.datatable-v3 .table thead th {
    background-color: $ups-neutral-gray-2;
    padding: 0px !important;
}
.ups-style div.datatable-v3 .table thead th.padding-right {
    padding-right: 26px !important;
}
.ups-style .table .thead-light th {
    color: $primary-body-color;
}

.ups-style div.datatable-v3 .table thead th button {
    background-color: $ups-neutral-gray-2;
    padding: 10px 0px;
    color: $primary-body-color;
    border: none;
    font-weight: 600;
    padding-left: 9px;
    width: 100%;
    text-align: inherit;
    outline: none;
}

.datatable-v3 .table thead th button:focus {    
    border: 1px solid black !important;
}

.datatable-v3 .table thead th button:focus:not(:focus-visible) {
    border: none !important;
    outline: none !important;
}

th.text-right {
    padding-right: 0px !important;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
}


.ups-style table.table-hover tbody tr:hover {
    background-color: $ups-brown-light-10;
    box-shadow: none!important;
}

.ups-style table.dataTable thead .sorting,
.ups-style table.dataTable thead .sorting_asc,
.ups-style table.dataTable thead .sorting_desc {
    padding-right: 15px!important;
}


table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    display: none;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
    content: ' ';
    display: inline-block;
    width: 12px;
    height: 17px;
    right: auto;
    position: relative;
    vertical-align: top;
    bottom: auto;
    left: 0.5rem;
    opacity: 0.58;
    margin-top: 2px;
    background: transparent url('../images/table/sort.svg');
    background-size: 12px 17px;
}


table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after {
    background: transparent url('../images/table/sort-up.svg');
    background-size: 12px 17px;
    opacity: 1;
}

table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
    background: transparent url('../images/table/sort-down.svg');
    background-size: 12px 17px;
    opacity: 1;
}

.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.ups-style #modal-root .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.ups-style #modal-root .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
    background: none!important;
    background-color: $ups-brown-light-50;
    border: none!important;
    border-radius: 4px!important;
    box-shadow: none!important;
    //font-weight: 700;
}

.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button:not(.current):not(.next):not(.previous):hover {
    background-color: #fff!important;
    border: 1px solid #fff!important;
}



.ups-style div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
    height: 38px;
    margin-bottom: 0px;
    border: 1px solid #ced4da!important;
    border-radius:3.2px!important;
    padding: 0px 40px 0px 8px;
    max-width:190px;
    box-sizing:border-box;
}

.ups-style div.dataTables_wrapper.datatable-v3 div.dataTables_filter input {
    height: 42px;    
    padding: 8px 40px 0px 8px;
}

div.dataTables_wrapper div.dataTables_filter label, div.dataTables_wrapper div.dataTables_length label {
    color: #242424 !important;
}

.ups-style .paginate_button.previous {
    @include button($btn-primary-color, #fff, 1px solid #767676);
    border: 1px solid #767676!important;
    border-radius: 5px;
}


.ups-style .paginate_button.next {
    @include button($btn-primary-color, #fff, 1px solid #767676);
    border: 1px solid #767676!important;
    border-radius: 5px;
}

.ups-style .paginate_button.previous.disabled {
    visibility: hidden;
}


.ups-style .paginate_button.next.disabled {
    visibility: hidden;
}

.ups-style .paginate_button:first-child:last-child {
    display: none;
}

.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button:not(.next):not(.previous):not(.current){
    color: #426DA9!important;
    text-decoration: underline!important;
}

.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.previous:active,
.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.next:active,
.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
    border: 1px solid #767676!important;
    border-radius: 5px;
    background-color:#fff!important;
    color: $primary-body-color;
}

div.dataTables_wrapper table th,
div.dataTables_wrapper table td { white-space: nowrap; }

div.dataTables_wrapper .table thead th {
    background-color: #efefef;
    font-weight: 600;
    border-bottom: none;
}

div.dataTables_wrapper table td.child {
    white-space: normal;
}

#otherChargesTab-table-datatable {
    .dtr-control {
        white-space: normal;
        min-width: 200px;
    }

    .datatable-selection-col {
        text-align: center;
    }

}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #e0e0e0!important;
}

.dataTables_info {
    font-size: 0.875rem;
    font-weight: 400;
}

.dataTables_paginate {
    font-size: 0.875rem;
    font-weight: 400;
}

.dataTables_length {
    font-size: 0.875rem;
    font-weight: 400;
}

.dataTables_length:before {
    background-color: $ups-teal-dark;
    -webkit-mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-image: url(../images/icon/ups/039-chevrondown.svg);
    mask-size: 15px 15px;
    content: ' ';
    height: 15px;
    width: 15px;
    display: block;
    position: absolute;
    top: 13px;
    right: 6px;
    pointer-events: none;
}

.ups-style div.dataTables_wrapper div.dataTables_filter {
    display: inline-block;
    position: relative;
    float: none;
    text-align: left;
    top: 1px;
}

.ups-style .dataTables_filter:before {
    background-color: $ups-teal-dark;
    -webkit-mask-image: url(../images/icon/ups/105-search.svg);
    mask-image: url(../images/icon/ups/105-search.svg);
    mask-size: 18px 18px;
    fill: red;
    content: ' ';
    height: 18px;
    width: 18px;
    display: block;
    position: absolute;
    right: 13px;
    top: 11px;
    pointer-events: none;
}

.ups-style .dataTables_filter:after {
    content: ' ';
    border-left: 1px solid red;
    right: 30px;
    position: absolute;
}

.ups-style div.dataTables_filter input {
    font-size: 14px !important;
    font-family: Tahoma, helvetica, arial, sans-serif !important;
}

.datatable-ups-svg-icon {
    fill: $ups-teal-dark;
    width: 16px;
    height: 16px;
    vertical-align: top;
    &.large-1 {
        width: 21px;
        height: 21px;
    }
}

.btn-primary .datatable-ups-svg-icon,
.btn-input-group .datatable-ups-svg-icon,
.btn-icon.active .datatable-ups-svg-icon{
    fill: #fff!important;
}

#main-container .dataTables_wrapper .dataTables_paginate .paginate_button.current,
#main-container .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
#modal-root .dataTables_wrapper .dataTables_paginate .paginate_button.current,
#modal-root .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: none!important;
    background-color: #4285f4!important;
    color: #fff!important;
    border-radius: .125rem!important;
    border-color: #4285f4!important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: .375rem .75rem!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: none!important;
    background-color: #585858!important;
    color: #fff!important;
    border-color: #eee!important;
}

.DTFC_LeftWrapper .table.table-bordered-simple td:first-child,
.DTFC_LeftWrapper .table.table-bordered-simple th:first-child {
    border-right: none!important;
}

.DTFC_RightWrapper .table.table-bordered-simple td:first-child,
.DTFC_RightWrapper .table.table-bordered-simple th:first-child {
    border-left: none!important;
}

div.dataTables_wrapper div.dataTables_filter label {
    text-align: left;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0;
    margin: 0;
}

.ups-style .dataTables_filter {
    position: relative;
    display: flex;
    flex-direction: column;

    label {
        position: absolute;
        pointer-events: none;
        transform: translate(0, 23px) scale(1);
        transform-origin: top left;
        color: #5F5753 !important;
        font-size: 14px !important;
        line-height: 1;
        left: 8px;
        top: -8px;
        font-weight: normal !important;
    }

    .filled {
        transform: translate(0, 12px) scale(0.8);
        font-weight: 700 !important;
      }
}

.dataTables_filter:focus-within label {
    transform: translate(0, 12px) scale(0.8);    
    font-weight: 700 !important;
  }

div.dataTables_wrapper div.dataTables_length label {
    text-align: left;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0;
}


div.dataTables_length select {
    display: inline-block!important;
}

.dataTables_wrapper .dataTables_length {
    margin-top: 23px;
}

.dataTables_wrapper .dataTables_length label {
    padding: 0!important;
}

.dataTables_wrapper .dataTables_info {
    float: left;
    clear: none!important;
    padding: 0!important;
    margin-top: 21px;

}


.dataTables_wrapper .dataTables_info:before {
    content: '|';
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    font-size: 16px;
    color: #c1c1c1;
}

div.dataTables_wrapper div.dataTables_filter input {
    width: auto;
    margin-left: .5rem;
    display: inline-block;
}

.dataTables_filter input[type="search"] {
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dataTables_filter input[type="search"]:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}


table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    font-family: 'Material Icons'!important;
    content: 'keyboard_arrow_down'!important;
    font-size: 1.5rem;
    width: auto!important;
    height: auto!important;
    border: none!important;
    box-shadow: none!important;
    background-color: transparent!important;
    color: $ups-teal;
    transform: rotate(-90deg)!important;
    transition: transform 250ms;
    white-space: nowrap;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"].parent>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"].parent>th:first-child:before {
    transform: rotate(0deg)!important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
    /*padding-left: 30px!important; */
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    /*top: 50%!important;*/
    margin-top: -1px!important;
    display: inline-block;
    vertical-align: middle;
}

ul.dtr-details {
    list-style-type: none;
    margin:0 ;
    padding: 0;
    display: table;
    width: auto;
}

ul.dtr-details li {
    display: table-row;

}

.dtr-title {
    padding: 10px;
    background-color: #efefef;
    /*
    display: block;
    float: left;
    width: 50%;*/
    font-weight: 600;
    display: table-cell;
    text-align: left;
}

.dtr-data {
    padding: 10px;
    /*display: block;
    width: 50%;
    float: left;*/
    display: table-cell;
    text-align: right;

    .alert {
        display: inline-block;
        font-size: 0.813rem;
    }
}

.dt-header-row {
    margin-bottom: 1rem;
}

.dt-button {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.ups-style .dataTables_wrapper .dataTables_info:before {
    margin: 0px 8px;
}

.ups-style .dt-buttons-col {
    text-align: right;
}

.dataTables_length { position: relative;}

.ups-style .dataTables_length select {
    border-radius: 0;
    margin: 0 0 .5rem;
    padding: 0.45rem 1.55rem 0.55rem 0.55rem;
    background-color: #fff;
    transition: all .3s;
    outline: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    font-size: 1rem;
    line-height: 1.5;
    height: auto;
    color: #242424 !important;
    font-family: $font-stack-1;
    font-weight: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.ups-style .dataTables_info {
    line-height: 38px;
    display: inline-block;
}

.ups-style #modal-root .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.ups-style #modal-root .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: none!important;
    background-color: #ddd7d0!important;
    border: none!important;
    border-radius: 4px!important;
    box-shadow: none!important;
    font-weight: 700;
}

.ups-style .paginate_button.next,
.ups-style .paginate_button.previous {
    background: #fff;
    font-size: 1rem!important;
    line-height: 1.1em;
    padding: 10px 30px;
    text-align: center;
    font-weight: 400;
    font-family: $font-stack-1;
    display: inline-block;
    text-decoration: none;
    transition-property: all;
    transition-duration: .2s;
    text-transform: none;
    box-shadow: none!important;
    border: 1px solid #767676!important;
    border-radius: 5px!important;
}

th.datatable-selection-col, td.datatable-selection-col {
    width: 38px!important;

    &.datatable-card {
        width: 50px!important;

        .form-check-input[type="checkbox"] + label {
            top: auto;
        }
    }
}


.datatable-date-selection {
    font-size: 14px;
    vertical-align: top;
    margin-left: 3px;
    margin-right: 3px;
}

table.dtr-column.collapsed .selectable-row td:first-child:before,
table.dtr-column.collapsed td:not(.datatable-card):first-child:before {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Material Icons'!important;
    content: 'keyboard_arrow_down'!important;
    font-size: 1.5rem;
    width: auto!important;
    height: auto!important;
    border: none!important;
    box-shadow: none!important;
    background-color: transparent!important;

    -webkit-transform: rotate(-90deg)!important;
    transform: rotate(-90deg)!important;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    color: $ups-teal;
    margin-top: 0!important;
    top: auto;
    position: relative;
}

table.dtr-column.collapsed .selectable-row td.dataTables_empty:first-child:before,
table.dtr-column.collapsed td.dataTables_empty:not(.datatable-card):first-child:before {
    display: none;
}

table.dtr-column.collapsed td.datatable-card + td:before {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Material Icons'!important;
    content: 'keyboard_arrow_down'!important;
    font-size: 1.5rem;
    width: auto!important;
    height: auto!important;
    border: none!important;
    box-shadow: none!important;
    background-color: transparent!important;

    -webkit-transform: rotate(-90deg)!important;
    transform: rotate(-90deg)!important;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    color: $ups-teal;
    margin-top: 0!important;
    top: auto;
    position: relative;
}


table.dtr-column.collapsed tr.child td.child:before {
    display: none!important;
}

table.dtr-column.collapsed .parent.selectable-row td:first-child:before,
table.dtr-column.collapsed .parent td:not(.datatable-card):first-child:before,
table.dtr-column.collapsed .parent td.datatable-card + td:before {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
    color: $ups-teal;
}

.datatable-action-col .dropdown-item {
    text-align: right;
}

.ups-style .dataTables_wrapper .dataTables_info:before {
    margin: 0px 8px;
}

.dt-pagination-col {
    text-align: right;
}

.dataTables_wrapper .dataTables_length {
    margin-top: .625rem!important;
}

.dataTables_wrapper .dataTables_info {
    margin-top: .625rem!important;
}


.datatable-blend-container {
    display: table;
}

.datatable-blend-container-autopay {
    position: absolute;
    display: table;
    top: 4px;
    z-index: 2;
}

.datatable-blend-inner {
    display: table-cell;
    height: 48px;
    vertical-align: middle;
    h6 {
        margin: 0;
    }
    p {
        margin: 0;
    }
}

.datatable-selection-stats-container {
    position: absolute;
    display: block;
    width: calc(100% - 235px);
    font-size: 0.875rem;
    line-height: normal;
    .stats-container {
        line-height: normal;
        padding-left: 2rem;
        padding-top: 5px;
        position: relative;
        float: left;
        width: 75%;
    }
    .manage-custom-buttons {
        position: relative;
        float: right;
        width: 25%;
        text-align:right;
        padding-right: 15px;
        .btn-icon {
            margin: 0 1rem 0 0!important;
        }
    }
    .datatable-custom-dropdown {
        width: 32px;
        height: 32px;
        display: inline-block;
    }
    .stats-counter {
        font-size: 1.5rem;
        vertical-align: top;
    }
    .stats-desc {
        margin-left: 0.813rem;
        vertical-align: top;
        line-height: 33px;
    }
}


.datatable-custom-dropdown {
    .dropdown-header {
        border: none!important;
        font-size: 0.875rem;
        //color: $ups-sienna;
        color: $primary-body-color;
        font-weight: 700!important;
        padding: 8px!important;
        &:focus {
            outline: none;
        }
    }
    .dropdown-item {
        position: relative;
        .dropdrop-item-checkbox {
            line-height: 24px;
            &:before {
                content: '';
                background: #fff;
                border: 1px solid #757575 !important;
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                padding: 2px;
                text-align: center;
                position: relative;
                border-radius: 5px;
                margin-right: 10px;
                top: -1px;
                left: auto;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                transition: none;
            }
        }

        &.active {
            color: $primary-body-color;
            text-decoration: none;
            background-color: #fff;

            .dropdrop-item-checkbox {
                &:after {
                    box-shadow: none;
                    content: " ";
                    position: absolute;
                    left: 17px;
                    top: 13px;
                    width: 6px;
                    height: 12px;
                    border-right: 3px solid #00857D !important;
                    border-bottom: 3px solid #00857D !important;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    padding: 0;
                    transition: none;
                    border-radius: 0;
                }
            }
        }
        &.disable {
            color: #AAAAAA!important;
            .dropdrop-item-checkbox {
                &:before {
                    background-color: #EDEDED!important;
                }
                &:after {
                    border-color: #AAAAAA!important;
                }
            }
        }
    }
}

table.dataTable:not(.table-card) tbody td {
    height: 50.6px;
}


.form-check.is-delinquent:before {
    position: absolute;
    top: -8px;
    left: -10px;
    display: block;
    content: ' ';
    width: 5px;
    height: 51px;
    background-color: $ups-plum;
}

table.dtr-column.collapsed .selectable-row td:first-child,
table.dtr-column.collapsed td:first-child {

    .form-check.is-delinquent:before {
        left: -34px;
        top: -12px;
        height: 53px;
    }

    .form-check-input[type="checkbox"] + label {
        top: 1px;
        left: 8px;
        padding-left: 8px;
    }
}

.datatablev1-hidden-column{
    display:none;
}

.datatable-custom-filter {
    display: inline-block;
    position: relative;
    float: none;
    text-align: left;
    margin: 0 .5rem 0 0.5rem;
    top: 1px;
    font-size: 14px;
    font-weight: 700;
    color: #585858;

    &:empty {
        display: none!important;
    }
}

svg.filter-active, i.filter-active {
    width: 16px;
    height: 16px;
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: #fff!important;
    fill: #00857d!important;
    border-radius: 50%;
    border: 1px solid #fff;
}

.dt-buttons .btn-icon {
    position: relative;
}

.dt-buttons .btn-calendar {
    position: relative;
}

.dt-buttons .btn-secondary.btn-icon-mobile {
    color:$ups-teal;
}

.card-display-item.display-accepted-payment-method {
    white-space: normal;
}
.service-refund-container{
    text-align: left;
    margin-top: 22px;
    text-decoration: underline;
}
.service-refund-container.service-refund-no-data{
    position: relative !important;
    bottom: 0px !important;
}
.service-refund-container a{
    color: $ups-blue;
}
.service-refund-link{
    text-decoration: underline;
    font-weight: 600 !important;
}
@media screen and (min-width: 1800px) {
    .d-xl-inline {
        display: inline!important;
    }
}

@media screen and (max-width: 1800px) {
    .d-xl-inline {
        display: none!important;
    }

    .d-xl-none {
        display: inline-block!important;
    }

    .dt-buttons .btn-secondary.btn-icon-mobile {
        width: 38px !important;
        height: 38px !important;
        padding: 3px 3px !important;
        margin: 0 !important;
    }
    
}

@media screen and (max-width: 1500px){
    .dataTables_wrapper .dataTables_filter,
    .dataTables_wrapper .dataTables_length {
        float: none !important;
        display: inline-block;
    }

    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
        float: none!important;
        text-align: center!important;
    }
    .dt-length,
    .dt-pagination-col {
        text-align: center;
        flex: 0 0 100%;
        max-width: 100%;
    }
 .service-refund-container {
            position: relative !important;
            bottom: unset !important;
            left: unset !important;
        }
}

@media screen and (max-width: 1200px) {
    .datatable-date-selection {
        display: none;
    }

    .btn-secondary {
        &.btn-icon-mobile {
            background: #fff !important;
            font-weight: 400;
            font-family: Tahoma, helvetica, arial, sans-serif !important;
            display: inline-block;
            text-decoration: none;
            transition-property: none!important;
            transition-duration: .2s;
            text-transform: initial;
            border: 1px solid #00857D !important;
            padding: 3px 3px !important;
            margin: 0!important;
            width: 38px !important;
            height: 38px !important;
            text-align: center !important;
            color: #00857D !important;
            line-height: 0;
            box-shadow: none !important;
            border-radius: 65px !important;
        }
    }

    #otherChargesTab-table-datatable .dtr-control {
        min-width: 120px;
    }
}

@media (min-width: 992px) {
    div.dataTables_wrapper.table-newline-pagination{
        .dt-footer-row .col-lg-6.col-sm-12.dt-length {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .dt-footer-row .col-lg-6.col-sm-12.dt-pagination-col.text-right {
            flex: 0 0 100%;
            max-width: 100%;
        }

        div.dataTables_paginate {
            text-align: center;
            float: none;
        }
    }
    .service-refund-container{
        position: absolute;
        bottom: 44px;
        left: 38%;
    }
}

@media screen and (max-width: 992px) {
    /* since invoices table has more buttons than the other tables, we'll have it go into the mobile style at a higher breakpoint instead of the standard 767*/
    .ups-style .dt-header-row.dt-my-invoices-row {

        //.dt-search-col {
        //    text-align: center;
        //    margin-bottom: 1rem;
        //}
        //
        //.dt-buttons-col {
        //    text-align: center;
        //}
        //
        //.dt-buttons {
        //    text-align: center;
        //}

        // div.dataTables_filter {
        //    display: block!important;
        //    margin: 0;
        //
        //    label {
        //        display: block;
        //    }
        //
        //    input {
        //        max-width: 100%;
        //        width: 100%;
        //        display: block;
        //    }
        //}
    }
}

@media screen and (max-width: 991px) {
    .ups-style .dt-header-row.dt-my-wallet-row .dt-buttons-col {
        text-align: right;
    }

    .ups-style .dt-button {
        margin: 0!important;
    }

    .dt-buttons {
        width: 100%;
        display: block;
        text-align: right;
        padding-left: 100px;
    }

    .dt-header-row .dt-buttons .dt-button.btn-primary {
        display: block;
        width: 100%!important;
        margin-bottom: 0.5rem!important;
    }

    .ups-style .dt-button.btn-primary:not(.display-none) ~ .dt-button {
        margin-top: 0.5rem!important;
    }
}

@media screen and (max-width: 768px) {
    div.dataTables_wrapper div.dataTables_info {
        display: block!important;
        margin-top: 0!important;
    }

    .dataTables_wrapper .dataTables_info:before {
        display: none!important;
    }

    .dataTables_wrapper .dataTables_length {
        float: none!important;
        display: inline-block;
    }

    .ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
        display: block;
        max-width: 250px;
        margin: auto;
        margin-bottom: 20px;
    }

    .ups-style .dataTables_wrapper .dataTables_paginate .paginate_button.next {
        display: block;
        max-width: 250px;
        margin: auto;
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .ups-style div.dataTables_wrapper div.dataTables_filter {
        display: block!important;
        margin: 0;
    }

    .ups-style div.dataTables_wrapper .dt-manage-access-combined-row div.dataTables_filter {
        display: inline-block!important;
        max-width: 190px;
    }

    .ups-style div.dataTables_wrapper div.dataTables_filter label {
        display: block;
    }

    .ups-style div.dataTables_wrapper div.dataTables_filter input {
        max-width: 100%;
        width: 100%;
        display: block;
    }

    .dt-search-col {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .dt-buttons {
        text-align: center;
        padding-left: 0px;
    }

    .ups-style .dt-header-row.dt-my-wallet-row .dt-buttons-col {
        text-align: center;
        margin-bottom: 0;
    }


    .ups-style .dt-button {
        margin: 0 0 0.5rem 0!important;
    }

    .ups-style .dt-button.btn-primary:not(.display-none) ~ .dt-button {
        margin-top: 1rem!important;
    }

    .dataTables_wrapper .dataTables_filter {
        float: none!important;
        display: inline-block;
    }

    .dt-pagination-col,
    .ups-style div.dataTables_wrapper div.dataTables_paginate {
        text-align: center;
    }

    .datatable-selection-stats-container {
        position: relative;
        width: 100%;
        .stats-container {
            float: none;
            width: 100%;
            margin-bottom: 1rem;
        }
        .manage-custom-buttons {
            float: none!important;
            width: calc(100% - 225px)!important;
            position: absolute!important;
            padding-right: 0!important;
        }
    }

    .datatable-blend-container-autopay.account-selection {
        position: relative;
        top: auto;
        margin-top: 1rem;
    }
    .datatable-blend-container.payment-detail {
        position: relative;
        top: auto;
        margin-top: 1rem;

        .datatable-blend-inner.payment-detail-header {
            position:relative;
            display: block;

            h6 {
                font-size: 1.125rem;
            }
        }
    }

    .datatable-blend-container.account-selection {
        position: relative;
        top: auto;
        //margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 599px) {
    th.rpps-action-col, td.rpps-action-col, th.datatable-action-col, td.datatable-action-col {
        width: 68px!important;
    }

    .ups-style div.dataTables_wrapper div.dataTables_filter {
        display: block!important;
    }

    div.dataTables_wrapper div.dataTables_filter label {
        display: block!important;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        display: block!important;
        width: 100%!important;
    }

    .btn-download-batch,
    .btn-pay-invoice {
        display: block!important;
        width: 100%!important;

        &.display-none {
            display: none!important;
        }
    }

    //.btn-download-plan-invoices {
    //    display: block!important;
    //    width: 100%!important;
    //
    //    &.display-none {
    //        display: none!important;
    //    }
    //}

    .datatable-blend-container.account-selection {
        position: relative;
        top: auto;
        //margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 575px) {
    ul.dtr-details {
        width: 100%;
    }
}

@media screen and (max-width: 349px) {
    .datatable-selection-stats-container .manage-custom-buttons {
        padding-left: 15px;
        text-align: left;
        width: calc(100% - 175px) !important;
    }

    .datatable-selection-stats-container .manage-custom-buttons .dropdown.datatable-custom-dropdown {
        margin-right: 0.625rem!important;
    }

    .datatable-selection-stats-container .manage-custom-buttons .btn-icon {
        margin: 0!important;
    }
}

@media screen and (max-width: 320px) {
    .ups-style .dt-button.btn-icon,
    .btn-secondary.btn-icon-mobile {
        width: 32px !important;
        height: 32px !important;
    }

    .ups-style .dt-button.btn-calendar {
        padding: 6px!important;
    }
}

@media print {
    .datatable-v3 {
        .table thead th {
           background-color: #F1F1F1!important;
            &.sorting {
                padding-right: 15px!important;
                &.payment-amount-col {
                    padding-right: 32px !important;
                }
            }
        }

        .dataTables_length select {
            display: inline-block;
            border-radius: 0;
            margin: 0 0 .5rem;
            padding: 0.45rem 1.55rem 0.55rem 0.55rem;
            background-color: #fff;
            outline: 0;
            box-shadow: none;
            border: none;
            border-bottom: 1px solid #ced4da;
            font-size: 1rem;
            line-height: 1.5;
            height: auto;
            color: #242424 !important;
            font-family: Tahoma, helvetica, arial, sans-serif !important;
            font-weight: normal;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        .dataTables_info {
            line-height: 38px;
            display: inline-block;
            margin-top: 0.625rem;
        }
        .paginate_button {
            padding: 0.375rem 0.75rem !important;
            border: 1px solid transparent;
            border-radius: 2px;
            box-sizing: border-box;
            display: inline-block;
            margin-left: 2px;
            text-align: center;
            &.previous {
                background: #fff;
                font-size: 1rem !important;
                line-height: 1.1em;
                text-transform: none;
                box-shadow: none !important;
                border: 1px solid #767676 !important;
                border-radius: 5px !important;
            }
            &.next {
                background: #fff;
                font-size: 1rem !important;
                line-height: 1.1em;
                text-transform: none;
                box-shadow: none !important;
                border: 1px solid #767676 !important;
                border-radius: 5px !important;
            }
            &.current {
                background: none !important;
                background-color: #ddd7d0 !important;
                border: none !important;
                box-shadow: none !important;
                font-weight: 700;
                border-radius: 4px !important;
            }
            &.disabled {
                display: none!important;
            }
            &:first-child:last-child {
                display: none!important;
            }
        }
        // hide direct debit icon, react-to-print seems to remove it regardless of css trying to add it back
        .display-accepted-payment-method .fa.fa-university {
            display: none;
        }
    }
}

.paginate_button{
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

@media (forced-colors: active) { // High Contrast Mode

    .datatable-ups-svg-icon,
    .btn-primary .datatable-ups-svg-icon, 
    .btn-input-group .datatable-ups-svg-icon, 
    .btn-icon.active .datatable-ups-svg-icon {
        fill: #36b37e !important;
        width: 20px;
        height: 20px;
    }
    
    .dataTables_length:before {
        background: transparent url('../images/icon/ups/chevrondown-hc.svg');
        background-repeat: no-repeat;
        background-size: 15px 15px;
    }

    table.dataTable thead {
        .sorting:after {
            background: transparent url('../images/table/sort-hc.png');            
            opacity: 0.58;
        }

        .sorting_asc:after,
        .sorting_asc_disabled:after {
            background: transparent url('../images/table/sort-up-hc.png');
            opacity: 1;
        }

        .sorting_desc:after,
        .sorting_desc_disabled:after {
            background: transparent url('../images/table/sort-down-hc.png');
            opacity: 1;
        }

        .sorting:after,
        .sorting_asc:after,
        .sorting_asc_disabled:after,
        .sorting_desc:after,
        .sorting_desc_disabled:after {
            content: ' ';
            display: inline-block;
            width: 12px;
            height: 17px;
            right: auto;
            position: relative;
            vertical-align: top;
            bottom: auto;
            left: 0.5rem;
            margin-top: 2px;
            background-size: 12px 17px;
        }
    }
}