@import 'variables';

.btn-dashboard-view-payment-history {
    top: 4px;
    right: 0;
    padding: 10px !important;
}

.btn-dashboard-view-payment-history::after{
   font-family: 'Material Icons'!important;
    content: 'keyboard_arrow_right'!important;
    font-size: 20px;
    vertical-align: middle;
}

.btn-toogle:focus{
    outline: none!important;
}

.btn-toogle.btn-primary.dropdown-toggle{
    background-color:$btn-primary-background;
}

.tag-line-1 {
    font-size: 1.313rem;
    line-height: normal;
}

.tag-line-2 {
    font-size: .875rem;
}

.invoices-due-soon ul.custom-timeline span.timeline-date {
    width: 128px;
    text-align: left;
    font-size: 14px!important;
    margin-top:5px!important;
}

ul.custom-timeline span.timeline-date {
    color: rgb(209, 205, 199) !important;
}

ul.custom-timeline span.timeline-date {
    display: block;
    float: left;
    padding: 8px 0;
    font-weight: 700;
}

.invoices-due-soon ul.custom-timeline span.timeline-desc {
    width: calc(100% - 128px);
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
}

ul.custom-timeline span.timeline-desc {
    color: rgb(209, 205, 199) !important;
}

.dispute-status-container ul.custom-timeline span.timeline-date {
    width: 128px;
    text-align: left;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 19px;
}
.dispute-status-container ul.custom-timeline span.date-range-title {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 19px;
}

.dispute-status-container ul.custom-timeline span.timeline-desc {
    width: calc(100% - 128px);
    padding: 8px;
    font-size: 14px;
    text-align: end;
    font-weight: 400;
}
.dispute-status-container ul.custom-timeline li.dispute-date-range {
    border-bottom: 0 !important;
}

ul.custom-timeline span.timeline-desc {
    float: left;
    line-height: normal;
}

.fas .fa-file-alt{
    color:gray;
}

ul.custom-timeline span.timeline-date .material-icons {
    font-size: 48px;
    color: #686868;
    }

ul.custom-timeline {
    list-style-type: none;
    margin: 15px 0 0;
    padding: 0;
}

ul.custom-timeline span.timeline-date {
    display: block;
    float: left;   
    width: 68px;
    text-align: center;
    font-size: 34px !important;
    font-weight: 700;
    box-sizing: border-box;
}

.past-due-card ul.custom-timeline span.timeline-date {
    margin-top: -10px;
}

ul.custom-timeline li {
    position: relative;
    overflow: hidden;
    margin-bottom: 7px;
    padding-bottom: 7px;
    vertical-align: middle;
    border-bottom: 1px solid #989898;
}

ul.custom-timeline.past-due-timeline div.pay-align{
    position: absolute;
    right: 0;
    top: 12px;
    padding: 5px!important;
}

ul.custom-timeline.past-due-timeline{
    .pay-plans--container {
        position: relative;
        color: #686868 !important;
    }
    .fa-folder:before {
        content: "\f07b";
        color: #686868 !important;
        font-size: 2.3rem;
    }
    
    .fas.fa-folder:after {
        content: "\2605";
        position: absolute;
        right: 5px;
        font-size: 16px;
        top: 12px;
        z-index: 1;
        color: white;
    }

    .btn-primary {
        padding: 10px 5px!important;
        min-width: 165px;
        text-align: center;
        float: right;
    }
}

ul.custom-timeline.past-due-timeline .timeline-desc {
    font-size: 14px;

    a {
        font-weight: normal;
        text-decoration:underline;
        position: relative;
        color: $ups-blue;
    }

    a:visited{
        color: #426DA9;
    }

    a:hover {
        color: #426DA9;
    }
}

ul.custom-timeline span.timeline-desc {
    color: rgb(50, 54, 56) !important;
}

ul.custom-timeline span.timeline-desc {
    float: left;
    // width: calc(100% - 180px);
    color: #242424!important;
    // padding: 10px;
    // font-weight: 700;
    // font-size: 16px;
    line-height:normal;
    margin-top: 6px!important;
}

ul.custom-timeline.past-due-timeline hr {
    margin: 5px 0px 5px 0px;
    border: none;
}

.minimal-spacing {
    min-width:62px!important;
    display: inline;
}

ul.custom-timeline.past-due-timeline span.timeline-date .fa-file-alt {
    color: #686868 !important;
    font-size: 38px !important;
    margin-top: 5px !important;
}

ul.custom-timeline.past-due-timeline span.timeline-desc span.timeine-due-amount {
    font-size: 16px;
    color: #910048!important;;
    font-weight: 400;
}

ul.custom-timeline span.timeline-date {
    display: block;
    float: left;
    color: rgb(50, 54, 56) !important;
    width: 68px;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
}

.past-Due{
    display: inline-flex;
}

.due-now{
    color: #910048;
    margin-left:2px ;
}

.dashboard-act-btn{
    margin-left: 10px;
}

.ups-style .card.dashboard-widget-card {
    border-radius: 0;
    background: #F7F6F5;
    min-height: 380px;
    max-height: 422px;

    .view {

        img {
            border-radius: 0!important;
        }

        .mask, .Ripple {
            display: none;
        }

        .Ripple-parent {
            cursor: default;
            max-height: 210px !important;
        }

    }

    li {
        font-size: 14px;
    }

    .card-body {
        padding: 0 30px;
        overflow: hidden;
    }
    .card-body:hover{
        overflow-y: auto;
    }

    .card-title {
        border: none;
        padding: 30px 30px 0 30px;
        margin-bottom: 10px;
        //margin-top: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .card-footer {
        background: #F7F6F5;
        padding: 15px 30px 30px 30px;
    }

    &.invoice-due-soon-card {
        .card-footer {
        margin: 0 -16px!important;
        background-color: initial!important;
        }
    }
}

.ups-style {
    .card.admin-alert-widget .card-body::-webkit-scrollbar-thumb{
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2)
    }  
      
    .card.admin-alert-widget .card-body::-webkit-scrollbar{
        width: 6px;
        height: 6px;
    }
    .dashboard-admin-alert-widget-card:last-child{
        margin-bottom: 14px;
    }
    .marketing-widget-card{
        font-size: 13px !important;
        h5{
            font-size: 16px !important;
            font-weight: 500;
        }
        table{
            border-collapse: separate !important;
        }
        .view{
            overflow: unset;
        }
        .card-body {
            overflow: hidden !important;
        }
        .card-body:hover {
            overflow-y: auto !important;
            overflow-x: auto !important;
        }
        .card-footer {
            button {
                margin-top: 2px !important;
            }
        }
    }
    .marketing-widget-card .card-body::-webkit-scrollbar-thumb{
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2)
      }  
      
    .marketing-widget-card .card-body::-webkit-scrollbar{
        width: 6px;
        height: 6px;
    }
}

.ups-style .btn-text-link {
    &.btn-view-plan-invoice {
        padding: 10px 0px!important;
        margin: 0px 16px!important;
    }
    &.btn-view-account-invoice {
        padding: 10px 0px!important;
        margin:0px 16px!important;
        float: right;
    }
    &.btn-view-account-invoice::after, &.btn-view-plan-invoice::after, &.btn-view-disputes::after {
        font-family: 'Material Icons'!important;
        content: 'keyboard_arrow_right'!important;
        font-size: 18px;
        vertical-align: middle;
    }
}



@media screen and (min-width: 768px) and (max-width: 1540px) {
    .ups-style .card.dashboard-widget-card {
        .btn-text-link {
            padding: 10px 15px;
        }
    }

    .minimal-spacing {
        display: none;
    }

    .ups-style .btn-text-link {
        &.btn-view-account-invoice {
            float: none;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
    .ups-style .card.dashboard-widget-card {
        .card-footer {
            text-align: center;
        }

        .invoice-due-soon-footer {
            text-align: left;
        }
    }

    .minimal-spacing {
        display: none;
    }

    .ups-style .btn-text-link {
        &.btn-view-account-invoice {
            float: none;
        }
    }

    .ups-style .dashboard-btn-text-link {
        text-align: left;
    }
}

@media screen and (max-width: 1200px) {
    .ups-style .card.dashboard-widget-card.marketing-widget-card {
        .view {
            height: auto;
        }
    }

    .minimal-spacing {
        display: none;
    }

    .ups-style .btn-text-link {
        &.btn-view-account-invoice {
            float: none;
        }
    }
}

@media screen and (min-width: 1600px) {
    .ups-style .card.dashboard-widget-card {
        max-height: 400px !important;
    }
}

@media screen and (max-width: 1750px) {
    .minimal-spacing {
        display: none;
    }

    .ups-style .btn-text-link {
        &.btn-view-account-invoice {
            float: none;
        }
    }

    ul.custom-timeline.past-due-timeline{
        .btn-primary {
            float: none!important;
            margin-left: 60px;
            margin-top: -1rem;
        }
    }
    .past-due{
        display: inline-table;
    }

    .past-due-span {
        margin-bottom: 8px!important;
    }
}

@media screen and (max-width: 767px) {
    .summary-widget-card {
        &.dashboard-summary-widget-card {
            .btn {
                width: 100%;
            }
        }

        .summary-widget-cta {
            .btn {
                width: 100%;
            }
        }
    }

    .admin-alert-widget-card {
        &.dashboard-admin-alert-widget-card {
            .btn {
                width: 100%;
            }
        }
    }

    // .btn-dashboard-view-payment-history {
    //     position: relative!important;
    //     top: auto;
    //     right: auto
    // }


    .ups-style .card.dashboard-widget-card {
        .btn {
            width: 100%;
        }
    }
}

@media screen and (max-width: 599px) {
    .ups-style .card.dashboard-widget-card {
        .card-title {
            padding: 20px 20px 0 20px;
        }

        .card-body {
            padding: 0 20px;
        }

        .card-footer {
            padding: 20px;

            .btn {
                + .btn {
                    margin-top: 10px !important;
                }
            }
        }

    }

    .summary-widget-card {
        &.dashboard-summary-widget-card {
            .btn {
                margin-left: 0;
                margin-right: 0;
                margin-top: 10px!important;
            }
        }

        .summary-widget-cta {
            .btn {
                margin-left: 0;
                margin-right: 0;
                + .btn {
                    margin-top: 10px!important;
                }
            }

            .dropdown-menu {
                + .btn {
                    margin-top: 10px!important;
                }
            }
        }
    }

    .ups-style .card.dashboard-widget-card {
        &.invoice-due-soon-card {
            .card-footer {
                margin: 0!important;
            }
        }


    }
    .ups-style .btn-text-link {
        &.btn-view-plan-invoice {
            margin: 0!important;
        }
        &.btn-view-account-invoice {
            margin:0!important;
        }

        &.btn-view-disputes {
            padding: 10px 0!important;
        }
    }

}

@media screen and (max-width: 375px) {
    .ups-style .card.dashboard-widget-card.marketing-widget-card {
        .card-title {
            padding-top: 24px;
        }
    }
}
