@import 'variables';

/* Summary widgets are currently used in the following pages:
    1. My dashboard
    2. Make a Payment
*/


.summary-widget-items {
    display: -webkit-flex!important;
    display: flex!important;
    display: -webkit-flex!important;
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.summary-widget-item {
    -webkit-flex: 1 1 auto!important;
    flex: 1 1 auto!important;
    padding: 1rem 0 0 1rem;

    &.alert-past-due {
        .summary-widget-item-label {
            color: $ups-plum;
        }
        .summary-widget-item-value {
            color: $ups-black;
        }
    }
}

.summary-widget-item:first-child {
    padding-left: 0;
}

.summary-widget-item-label {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 700;
    display: inline-block;
    text-align: center;
}

.summary-widget-item-value {
    font-size: 1.313rem;
    font-weight: normal;
    margin: 0;
}

.card-header.summary-widget-title {
    padding-left: 0;
    font-size: 1.125rem;
}

.card-header.admin-alert-widget-title {
    padding: 0;
    font-size: 14px;
}

.admin-alert-widget-date {
    color: #757373;
    font-size: 14px;
    margin-top: 4px;
}

.admin-alert-widget-close {
    font-size: 16px;
    position: relative;
    left: 4px;
    top: -6px;
}

.admin-alert-widget-close:focus, .admin-alert-widget-close:focus-visible {
   outline: none !important;
}

.admin-alert-widget-name {
    font-weight: 700;
    text-transform: capitalize;
}

.payment-summary-item-value {
    font-weight: normal;
    font-size: 1.313rem;
}

.card.summary-widget-card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)!important;

    border-radius: 0;


    margin-bottom: 2rem;
    padding: 1rem;

    .card-body {
        padding: 0;
    }
}

.card.admin-alert-widget-card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)!important;

    border-radius: 0;


    margin-top: 1rem;
    padding: 0.7rem;

    .card-body {
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .summary-widget-items {
        display: block!important;
    }

    .summary-widget-item {
        padding: 1rem 0 0 0;
    }

    .summary-widget-item-label {
        text-align: left;
    }
}
