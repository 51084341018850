@import 'variables';
// These comments last updated notes on Sept 14, 2020 at 10:13 AM EST.
// This CSS contains css that tranforms the standard datatable look and feel into cards.
// Currently card view is offered in manage users, payment history, autopay, and wallet. May be more later on.

// Each of those sections uses a different card view because their use cases are different or the datatable implementation is different.

// 1. Payment History - the datatable goes into card view only on 767px width and below. It's card content is a separate datatable column
//  never shown on desktop using datatables special css class 'never'. 'no-export' is also used here to print, csv and excel don't include it.

// 2. AutoPay / Automatic Payments card view component is part of the checkbox column with it's card view table element set to display none on desktop.
// Did not want to have 2 checkboxes where one was for desktop selection and one for mobile selection so kept it as one checkbox used by both view
// to keep the checkbox selection in tact when resizing from desktop to mobile

// 3. Wallet - can toggle between card view and default datatable on desktop. When on mobile, only card view is offered so the buttons to toggle
// between the 2 display view styles is set to display none. The seamless integration of this is still a work in progress so right now in the static
// there is a hard re-render when switching between the 2 display view styles.

// THIS DOES NOT INCLUDE MANAGE USERS (left that in ups.scss for now to avoid introducing any css bugs as the html/css has been modified in the actual implementation)

// READ MORE HERE on reserved css classes used in datatables: https://datatables.net/extensions/responsive/classes

.ups-style table.table-card-wallet.table-hover tbody tr:hover {
    background-color: #fff !important;
}

.table-card {
    &.table-card-wallet {
        tbody {
            border-top: 1px solid #red;

            background-color: #fff!important;
            tr {
                float: left;
                width: 25%;
                text-align: center;
                box-shadow: none!important;
                border: none!important;
                margin: 1% 0%!important;

                &:hover {
                    background-color: #fff!important;
                }

            }

            td {
                &.datatable-card-col {
                    border: none;
                }
                display: block !important;
                height: auto;
                padding-left: 0!important;
                margin-right: 0;
            }
        }

        .card-container {
            max-width: 100%;
            margin-right: 0;
        }
    }
}

/* VERY EXPERIMENTAL CSS OF SWITCHING DATATABLE TO CARD VIEW purely by CSS*/
/* essentially a table is hidden in the checkbox column on desktop and only shown on mobile */
/* change the breakpoint to determine when it kicks to the card style */
.table-card-mobile-view {
    display: none;
}

.table-card-mobile-actions {
    position: absolute;
    top: 18px;
    right: 12px;
    display: none
}

.table-card-important-info {
    display: none;
}

.table-card-mobile-only-label {
    display: none;
}

.mobile-datatable-action-icon {
    display: none;
}

td.datatable-card {
    position: relative;
}

td.datatable-card .md-form {
    margin: 0;
}

td.datatable-card .md-form input[type="text"] {
    text-align: right !important;
    font-size: 0.75rem !important;
    margin: 0 !important;
    padding-top: 0px !important;
    padding-bottom: 3px !important;
    color: #242424 !important;
    font-weight: 400 !important;
    width: 138px;
    display: inline-block;

    &:focus {
        padding-right: 0!important;
    }
}

.table-card-mobile-container {
    &.has-selection-input {
        display: inline-block;
    }
}

@media screen and (min-width: 1681px) and (max-width: 1800px) {
    .pm-info.card-number {
        font-size: 1.625rem;
    }
}

@media screen and (min-width: 1536px) and (max-width: 1680px) {
    .pm-info.card-number {
        font-size: 1.313rem;
    }
}

@media screen and (max-width: 1800px) {
    // 3 cards shouwn per row
    .table-card.table-card-wallet tbody tr {
        width: 33.3333%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1180px) {
    .pm-info.card-number {
        font-size: 1.313rem;
    }
}

@media screen and (max-width: 1180px) {
    .table-card.table-card-wallet tbody tr {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {

    .table-card.table-card-wallet tbody tr {
        width: 100%;
    }

    .table-card.table-card-wallet tbody td {
        padding-right: 0!important;
    }

    .table-custom-hide-item {
        display: none;
    }
    .table-card-mobile-actions {
        display: block;
    }

    .table-card-mobile-hide {
        display: none;
    }

    .mobile-datatable-action-icon {
        display: inline-block;
    }

    .hide-datatable-button-text {
        display: none;
    }

    .btn.btn-add-payment-method {
        width: 100%;
        display: block;
    }

    // hide the cog button icon for datatables with a card view for mobile
    .has-datatable-card {
        .dt-button {
            &.btn-column-settings {
                display: none!important;
            }
        }
    }

    .table-card-mobile {

        .datatable-selection-col {
            position: relative;
        }

        .datatable-relative-col {
            position: relative;
        }

        thead {
            display: none!important;
        }

        tbody {
            background-color: #F1F1F1 !important;
            padding: 1% 0;

            th {
                display: none!important;
            }
        }

        tr {
            width: 94%;
            margin: 2% 3%;
            border-radius: .25rem;
            text-align: left;
            box-shadow: none!important;
            background-color: #F1F1F1 !important;
            border: none!important;

            &:hover {
                background-color: #F1F1F1 !important;
            }

            td {
                display: none!important;
                &:first-child {
                    padding-top: 10px;
                    line-height: normal;
                    white-space: pre-wrap;
                    font-size: 0.875rem;
                    display: table !important;
                    width: 100%!important;
                    border: none!important;

                    .form-check-inline {
                        display: block!important;
                        background-color: #fff!important;
                        padding-bottom: 1rem;
                        padding-top: 0.5rem;

                    }
                }
            }
        }

        .table-card-mobile-container {
            display: block;
            padding: 1rem 0.5rem 0.5rem 0.5rem;
            background: #fff;
            box-shadow: 1px 3px 4px 2px rgba(0, 0, 0, 0.1) !important;

            &.has-selection-input {
                padding-top: 0.813rem;

                .form-check {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                label {
                    padding-left: 28px!important;
                    width: calc(100% - 48px);
                }

                .table-card-mobile-only-label {
                    font-weight: bold;
                    padding-left: 0.5rem;
                    display: inline-block;
                    line-height: normal;
                }
            }
        }

        .table-card-important-info {
            font-weight: bold;
            padding-left: 0.6rem;
            display: block;
            font-size: 0.813rem;
        }

        .table-card-mobile-view {
            display: table;
            margin-top: 0.5rem;
            margin-bottom: 0;

            tr {
                width: 100%!important;
                margin: 0!important;
                //background-color: #F1F1F1 !important;
                background-color: #fff!important;
                &:hover {
                    background-color: #F1F1F1 !important;
                    box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,0.50);
                }
            }

            th {
                border: none!important;
                background-color: #fff!important;
                vertical-align: top;
                white-space: normal;
                font-size: 0.75rem;
            }

            td {
                border: none!important;
                display: table-cell!important;
                background-color: #fff!important;
                height: auto!important;
                text-align: right!important;
                vertical-align: top;
                font-size: 0.75rem;

                &:hover {
                    background-color: #fff!important;
                }
            }


        }
    }
}